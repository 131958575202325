import React, { useEffect, useState } from 'react'
import { useFeedsColumnsVendor } from '../../../utils/helpers/tableHelpers/useFeedsColumnsVendor';
import { getApi } from '../../../utils/services';
import { VENDOR } from '../../../utils/apiRoutes';
import SpinnerIcon from "../../../assets/image/SpinnerIcon";
import Vendor from '../../Table/Vendor';
import { Helmet } from "react-helmet";
import Loader from '../../common/Loader';

const VendorsList = () => {
    const [vendors, setVendor] = useState([]);
    const [loader, setLoader] = useState(false);

    const handleVendorList = async () => {
        try {
            setLoader(true)
            const result = await getApi(VENDOR);
            const vendors = result.res.vendor_list
            setLoader(false)
            setVendor(vendors)
        } catch (error) {
            console.log(error);
        }
    };

    const columns = useFeedsColumnsVendor(handleVendorList);

    useEffect(() => {
        handleVendorList();
    }, []);

    return (
        <>
            <Helmet>
                <title>Vendor Portal || Vendor List</title>
            </Helmet>
            <Loader isLoading={loader} />
            <div className="container-section">
                <div className="section-header">
                    <h3>Vendors</h3>
                </div>
                <div className="form-style">
                    <Vendor columns={columns} data={vendors} />
                </div>
            </div>

        </>
    )
}

export default VendorsList