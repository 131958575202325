import React from 'react'

const DownloadSvg = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
        <rect width="30.4351" height="30" rx="6" fill="#F7CB73" />
        <path d="M15 19L10 14L11.4 12.55L14 15.15V7H16V15.15L18.6 12.55L20 14L15 19ZM9 23C8.45 23 7.979 22.804 7.587 22.412C7.195 22.02 6.99934 21.5493 7 21V18H9V21H21V18H23V21C23 21.55 22.804 22.021 22.412 22.413C22.02 22.805 21.5493 23.0007 21 23H9Z" fill="white" />
      </svg>
    </>
  )
}

export default DownloadSvg