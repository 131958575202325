
import { useContext, useMemo } from 'react';
import { useDateFormat } from '../useDateFormate';
import { useStatusRenderer } from '../useStatus';
import eye from "../../../assets/image/eye.png"
import edit from "../../../assets/image/edit2.png"
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ApproveButton from '../../../assets/image/ApproveButton';
import { ADMIN_MANAGER_ID, FINANCE_ID, FINANCE_PENDING, SUPER_ADMIN_ID, VENDOR_MANAGER_PENDING, VENDOR_REJECT, VENDOR_VP_PENDING, VP_ID } from '../../constant';
import ApproveIndividualBill from '../../../components/modal/ApproveIndividualBill';
import { ModalContext } from '../../Context';
import RejectButton from '../../../assets/image/RejectButton';
import RejectIndividualBill from '../../../components/modal/RejectIndividualBill';
import FinanceApporveIcon from '../../../assets/image/FinanceApporveIcon';
import DepartmentPending from '../../../components/modal/DepartmentPending';
import PendingItem from '../../../components/modal/PendingItem';

// Custom hook for defining FeedsColumns
export const useFeedsColumnIndividualBills = (handlerIndividualBillList) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userData"));
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;

  const handlerView = (id) => {
    navigate(`/view-individual-bill/${id}`)
  }

  const handlerApprove = (data) => {
    if (data.DepartmentName) {
      let app = <ApproveIndividualBill data={data} handlerIndividualBillList={handlerIndividualBillList} />
      handleModalData(app, "md");
    }
    else {
      let ApproveModal = <DepartmentPending data={data} handlerIndividualBillList={handlerIndividualBillList} />
      handleModalData(ApproveModal, "md");
    }
  }

  const handleReject = (data) => {
    let rej = <RejectIndividualBill data={data} handlerIndividualBillList={handlerIndividualBillList} />
    handleModalData(rej, "md");
  }

  const handlerTds = (data) => {
    navigate(`/add-individual-bill-tds/${data.id}`)
  }

  const editIndividualBill = (data) => {
    if (data?.VendorStatus === VENDOR_MANAGER_PENDING && data?.IsSelfService === true) {
      let pendingModal = <PendingItem message="Details are pending for approval" />;
      handleModalData(pendingModal, "md");
    }
    else {
      navigate(`/edit-individual-bill/${data.id}`)
    }
  }

  const columns = useMemo(() => [
    {
      Header: "Bill Number",
      accessor: "DocumentNo",
    },

    {
      Header: "Created Date",
      accessor: "CreatedDate",
      Cell: ({ row }) => {
        return <> {useDateFormat(row.original.CreatedDate)}</>;
      },
    },
    {
      Header: "Vendor Name",
      accessor: "LegalName",
    },
    {
      Header: "Grand Total",
      headerStyle: { textAlign: 'right' },
      accessor: "Total",
      Cell: ({ row }) => {
        return <> <div className='text-end pe-4'>{row?.original?.CurrencySymbol} {row?.original?.Total?.toFixed(2)}</div> </>;
      },
    },
    {
      Header: "TDS Amount",
      headerStyle: { textAlign: 'right' },
      accessor: "TDSAmount",
      Cell: ({ row }) => {
        return <><div className='text-end pe-4'>{row?.original?.TDSAmount ? <>{row?.original?.CurrencySymbol} {row?.original?.TDSAmount?.toFixed(2)} </> : "N/A"} </div> </>;
      },
    },
    {
      Header: <div className='ps-5'>Status</div>,
      accessor: "Name",
      Cell: ({ row }) => useStatusRenderer(row),
      disableSortBy: true,
    },
    {
      Header: "Action",
      accessor: (data) => {
        return (
          <>
            <div className="d-flex align-item-center">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips1">
                    View
                  </Tooltip>
                }
              >
                <img src={eye} alt="" onClick={() => handlerView(data.id)} className='cursor-pointer' />
              </OverlayTrigger>

              {((data.Status === VENDOR_MANAGER_PENDING &&
                user.RoleId === ADMIN_MANAGER_ID) ||
                (data.Status === VENDOR_VP_PENDING && user.RoleId === VP_ID) ||
                (data.Status === FINANCE_PENDING &&
                  user.RoleId === FINANCE_ID)) && (
                  <span className="d-flex">
                    {data.Status === FINANCE_PENDING ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips2">
                            Add TDS
                          </Tooltip>
                        }
                      >
                        <span
                          onClick={() => handlerTds(data)}
                          className="cursor-pointer cstm-icon-gap"
                        >
                          <FinanceApporveIcon />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips3">
                            Approve
                          </Tooltip>
                        }
                      >
                        <span
                          onClick={() => handlerApprove(data)}
                          className="cursor-pointer cstm-icon-gap"
                        >
                          <ApproveButton />
                        </span>
                      </OverlayTrigger>
                    )}

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips4">
                          Reject
                        </Tooltip>
                      }
                    >
                      <span
                        onClick={() => handleReject(data)}
                        className="cursor-pointer cstm-icon-gap"
                      >
                        <RejectButton />
                      </span>
                    </OverlayTrigger>
                  </span>
                )}

              {(data.Status === VENDOR_REJECT ||
                (data.Status === VENDOR_MANAGER_PENDING &&
                  user.RoleId === ADMIN_MANAGER_ID)) && (
                  <>
                    {(user.RoleId !== VP_ID && user.RoleId !== FINANCE_ID && user.RoleId !== SUPER_ADMIN_ID) && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips5">
                            Edit
                          </Tooltip>
                        }
                      >
                        <img src={edit} alt="" onClick={() => editIndividualBill(data)} className="cstm-icon-gap cursor-pointer" />
                      </OverlayTrigger>
                    )}
                  </>
                )}
            </div>
          </>
        );
      },
    },
  ], []);

  return columns;
};