import { useState } from 'react';

const OtpFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (otp)=> {
    let _errors = {};
    let formIsValid = true;
    if (!otp) {
      formIsValid = false;
      _errors["otp"] = "Please Enter OTP!";
    }
    else if (otp.length <6 ) {
      formIsValid = false;
      _errors["otp"] = "Otp should have only 6 digits";
    }
    setErrors(_errors);
    return formIsValid;
  };

  return { errors,setErrors, validateForm };
};

export default OtpFormValidation;