import React, { useContext, useState } from "react";
import { ModalContext } from "../../utils/Context";
import toast from "react-hot-toast";
import { postApi } from "../../utils/services";
import { VENDOR_DETAILS_APPROVE } from "../../utils/apiRoutes";

const ApproveVendor = ({ handleVendorList, data }) => {
  const modalContext = useContext(ModalContext);
  const { closeModal } = modalContext;
  const [disable, setDisable] = useState(false)

  const handlerApproveApi = async () => {
    try {
      setDisable(true);
      const result = await postApi(VENDOR_DETAILS_APPROVE, {
        "vendorId": data.id,
        "vendorBankInfoIds": data.vendorBankInfoIds,
        "selfService": data.selfService ? data.selfService : null
      });
      if (result.res.success) {
        handleVendorList();
        toast.success(result.res.message, {
          className: 'custom-toast-class', // Add your custom class here
        });
        closeModal();
      } else {
        toast.error(result.res.message);
      }
    } catch (error) {
      toast.error(error.message);
      closeModal();
    }
  };

  return (
    <div className="custom-cnfm-mdl">
      <div class="modal-body">
        <h2>Are you sure?</h2>
        <h6 className="mb-4 delete-heading">
          Are you sure want to approve this Vendor?
        </h6>
      </div>
      <div class="modal-footer">
        <div className="d-flex align-items-center mb-20">
          <div className="cstm-dngr-btn">
            <button type="button" className="btn" onClick={closeModal}>
              Cancel
            </button>
          </div>
          <div className="cstm-success-btn ms-2">
            <button
              type="submit"
              className="btn"
              onClick={() => handlerApproveApi()}
              disabled={disable}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveVendor;
