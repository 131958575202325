import React, { useContext, useEffect, useState } from 'react'
import { ModalContext } from '../../utils/Context';
import { postApi } from '../../utils/services';
import { REJECTED_LIST } from '../../utils/apiRoutes';
import { useTransactionContext } from '../../utils/helpers/useTransaction';
import cross from "../../assets/image/cross.png";
import RejectToReqIndividualBill from './RejectToReqIndividualBill';
import RejectAtIndividualBill from './RejectAtIndividualBill';

const RejectIndividualBill = ({ data, handlerIndividualBillList }) => {
    const modalContext = useContext(ModalContext);
    const { handleModalData } = modalContext;
    const [levelValue, setLevelValue] = useState([]);
    const user = JSON.parse(localStorage.getItem("userData"));
    const transactionList = useTransactionContext();
    const { closeModal } = modalContext;

    const handleRejectRequester = () => {
        let rejectToRequestor = <RejectToReqIndividualBill data={data} handlerIndividualBillList={handlerIndividualBillList} />
        handleModalData(rejectToRequestor, "md")
    }

    const handlerRejectAt = () => {
        let rejectAt = <RejectAtIndividualBill levelValue={levelValue} data={data} handlerIndividualBillList={handlerIndividualBillList} />
        handleModalData(rejectAt, "md")
    }

    const handlerLevelApi = async () => {
        let res = await postApi(REJECTED_LIST, {
            "transactionId": transactionList[4].id,
            "roleId": user.RoleId
        })
        setLevelValue(res.res.rejectedAt_list)
    }

    useEffect(() => {
        if (user) {
            handlerLevelApi()
        }
    }, [])

    return (
        <div className='custom-cnfm-mdl'>
            <span className="cross" onClick={closeModal}> <img src={cross} alt="" /></span>
            <div class="modal-body">
                <h2>Are you sure?</h2>
                <h6>Are you sure want to reject this Standalone Bill? This action cannot be undone. </h6>
            </div>
            <div class="modal-footer">
                <div className=" d-flex align-items-center mb-20">
                    <div className="cstm-dngr-btn">
                        <button type="button" className="btn" onClick={handleRejectRequester}>Reject to Requester</button>
                    </div>
                    <div className="cstm-lvl-btn ms-2">
                        <button disabled={levelValue.length === 0} type="submit" className="btn" onClick={handlerRejectAt}>
                            Reject at
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RejectIndividualBill