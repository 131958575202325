import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BackButtonWhite from "../../../assets/image/BackButtonWhite";
import { getApi } from "../../../utils/services";
import { VIEW_PAYMENT } from "../../../utils/apiRoutes";
import moment from "moment";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { VENDORID } from "../../../utils/constant";

const ViewPayment = () => {

  const { id } = useParams();

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [viewPayment, setViewPayment] = useState({});
  const [loader, setLoader] = useState(false);

  const handleViewPayment = async () => {
    setLoader(true)
    const result = await getApi(`${VIEW_PAYMENT}/${id}`);
    setLoader(false)
    setViewPayment(result.res.payment_details);
  };

  useEffect(() => {
    if (id) {
      handleViewPayment();
    }
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Vendor Portal || View Payment</title>
      </Helmet>

      <Loader isLoading={loader} />

      <div className="container-section">

        <div className="section-inv-header ">
          <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
            <div class="svgsize">
              <BackButtonWhite />
            </div>
          </button>

          <h3>BILL PAYMENT</h3>
        </div>

        <div className="form-style">
          <div className="data-inv-info">
            <div className="data-inv-group-1">
              <div className="data-details-1">
                <div className="section-sub-heading">
                  <h4>Bill To:</h4>
                  <p>
                    JSTREAMS SOLUTION PVT LIMITED,
                    <br />
                    <span>Address: </span>Unitech Cyber Park, Unit No.- 1005 ,
                    10th Floor, Tower C, Sector 39, Gurugram, Haryana, Pincode:
                    122003
                  </p>
                </div>
              </div>
            </div>
            <div className="data-inv-group-2">
              <div className="view-footer-border">
                <div className="data-details-4 pt-2">
                  <div className="d-flex align-items-center ps-2 pb-1">
                    <span className="data-label">Billed Total</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">{viewPayment?.CurrencySymbol} {viewPayment?.InvoiceTotal?.toFixed(2)}</span>
                  </div>
                  <div className="d-flex align-items-center ps-2 pb-1">
                    <span className="data-label">GST Total</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">{viewPayment?.CurrencySymbol} {viewPayment?.TaxSubtotal?.toFixed(2)}</span>
                  </div>
                  <div className="d-flex align-items-center ps-2 pb-1">
                    <span className="data-label">Grand Total</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">{viewPayment?.CurrencySymbol} {viewPayment?.GrandTotal?.toFixed(2)}</span>
                  </div>
                  <div className="d-flex align-items-center ps-2 pb-1">
                    <span className="data-label">TDS Total</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">{viewPayment?.CurrencySymbol} {viewPayment?.TDSAmount?.toFixed(2)}</span>
                  </div>
                  <div className="view-total-amount d-flex align-items-center ps-2 pb-1 pt-1">
                    <span className="data-label text-white">Total</span>
                    <span className="data-semicolon text-white">:</span>
                    <span className="data-value text-white">{viewPayment?.CurrencySymbol} {viewPayment?.PayableAmount?.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-style">
          <hr />

          <div className="row pt-2">
            <div className="col-md-6 payment-details-div mb-2">
              <div className="d-flex">
                <span className="data-label">Payment ID</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.PaymentId}</span>
              </div>
            </div>
            {viewPayment?.DepartmentName && (
              <div className="col-md-6 payment-details-div mb-2">
                <div className="d-flex">
                  <span className="data-label">Department</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{viewPayment?.DepartmentName}</span>
                </div>
              </div>
            )}
            <div className="col-md-6 payment-details-div mb-2">
              <div className="d-flex">
                <span className="data-label">Legal Name</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.LegalName}</span>
              </div>
            </div>
            {/* {viewPayment?.DepartmentName && (
              <div className="d-flex">
                <span className="data-label">Department</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.DepartmentName}</span>
              </div>
            )} */}
            <div className="col-md-6 payment-details-div mb-2">
              <div className="d-flex">
                <span className="data-label">Amount Paid</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.CurrencySymbol} {viewPayment?.AmountPaid?.toFixed(2)}</span>
              </div>
            </div>
            <div className="col-md-6 payment-details-div mb-2">
              <div className="d-flex">
                <span className="data-label">Payment Date</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{moment(viewPayment?.CreatedAt).format('D/MM/YYYY')?.slice(0, 10)}</span>
              </div>
            </div>
            <div className="col-md-6 payment-details-div mb-2">
              <div className="d-flex">
                <span className="data-label">Bill#</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment.IndividualBill ?
                  <Link className="" to={`/view-individual-bill/${viewPayment?.InvoiceId}`} target="_blank">{viewPayment?.DocumentNo}</Link> :
                  <Link className="" to={`/view-bill/${viewPayment?.InvoiceId}`} target="_blank">{viewPayment?.DocumentNo}</Link>
                }
                </span>
              </div>
            </div>
            <div className="col-md-6 payment-details-div mb-2">
              <div className="d-flex">
                <span className="data-label">Reference No</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.ReferenceNo ? viewPayment?.ReferenceNo : "N/A"}</span>
              </div>
            </div>
            <div className="col-md-6 payment-details-div mb-2">
              <div className="d-flex">
                <span className="data-label">Due Date</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.DueDate ? moment(viewPayment?.DueDate).format('D/MM/YYYY')?.slice(0, 10) : "N/A"} </span>
              </div>
            </div>
            <div className="col-md-6 payment-details-div mb-2">
              <div className="d-flex">
                <span className="data-label">Status</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">{viewPayment?.StatusName}</span>
              </div>
            </div>
            {user.RoleId !== VENDORID && (
              <div className="col-md-6 payment-details-div mb-2">
                <div className="d-flex">
                  <span className="data-label">NetSuite Id</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{viewPayment?.ExternalId ? viewPayment?.ExternalId : " N/A"}</span>
                </div>
              </div>
            )}
          </div>

        </div>

      </div>
    </>
  );

};

export default ViewPayment;
