import { useState } from 'react';

const AddContractValidation = () => {
    const [errors, setErrors] = useState({});

    const isValidFileType = (file) => {
        const allowedTypes = ["application/pdf"];
        return allowedTypes.includes(file.type);
    };

    const MAX_FILE_SIZE = 2 * 1024 * 1024;

    const validateForm = (vendorId, startDate, endDate, itemDescription, contractTotal, contractDocument) => {

        let errors = {};
        errors.itemDescription = []
        let formIsValid = true;

        if (!vendorId) {
            formIsValid = false;
            errors.vendorId = "Select Vendor"
        }
        if (!startDate) {
            formIsValid = false;
            errors.startDate = "Select Start Date"
        }
        if (!endDate) {
            formIsValid = false;
            errors.endDate = "Select End Date"
        }
        if (contractTotal === "0.00") {
            formIsValid = false;
            errors.contractTotal = "Total Amount can't be zero !";
        }
        if (!contractDocument || contractDocument.length === 0) {
            formIsValid = false;
            errors.files = "Please upload a document !";
        }
        // else if (!formState.files || formState.files.length === 0) {
        //     formIsValid = false;
        //     errors.files = "Please attach a file !";
        // }
        else if (contractDocument) {
            for (const file of contractDocument) {
                if (!isValidFileType(file)) {
                    formIsValid = false;
                    errors.files = "Only PDF is allowed !";
                    break;
                }
                if (file.size > MAX_FILE_SIZE) {
                    formIsValid = false;
                    errors.files = "Maximum allowed file size is 2MB !";
                    break;
                }
                if (contractDocument.length > 5) {
                    formIsValid = false;
                    errors.files = "Only 5 files are allowed !";
                    break;
                }
            }
        }
        itemDescription.forEach((item, index) => {
            const lineItemErrors = {};

            if (!item.itemId) {
                formIsValid = false;
                lineItemErrors.itemId = "Select Item";
            }
            if (!item.rate) {
                formIsValid = false;
                lineItemErrors.rate = "Enter Rate";
            }
            if (item.rate && item.rate == 0) {
                formIsValid = false;
                lineItemErrors.rate = "Rate can't be zero";
            }
            if (!item.quantity) {
                formIsValid = false;
                lineItemErrors.quantity = "Enter Quantity";
            }
            if (item.quantity && item.quantity == 0) {
                formIsValid = false;
                lineItemErrors.quantity = "Quantity can't be zero";
            }
            if (Object.keys(lineItemErrors).length > 0) {
                errors.itemDescription[index] = lineItemErrors;
            }
            return lineItemErrors;
        });

        if (errors.itemDescription.length === 0) {
            delete errors.itemDescription;
        }

        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default AddContractValidation;
