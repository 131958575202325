import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../assets/image/BackButton";
import { getApi, postApi } from "../../../utils/services";
import { BILL_DETAILS, CREATE_BILL, DEPARTMENT_LIST, SITE_CONFIGERATION } from "../../../utils/apiRoutes";
import moment from "moment";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import Swal from "sweetalert2";
import AddBillValidation from "../../../middleware/addBillsValidation";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { ADMIN_MANAGER_ID, BILL_SITE_CONFIGERATION_ID } from "../../../utils/constant";

const BillAdd = () => {
  const navigate = useNavigate();
  const transactionList = useTransactionContext();
  const { id } = useParams();
  const [InvoiceItem, setInvoiceItems] = useState({});
  const [finalTotalAmount, setFinalTotalAmount] = useState("");
  const [loader, setLoader] = useState(false);
  const user = JSON.parse(localStorage.getItem("userData"));
  const [data, setData] = useState({
    cgst: "",
    sgst: "",
    utgst: "",
    igst: "",
    salesTax: "",
    taxSubTotal: "",
    vatTotal: "",
    total: "",
  });

  const [attachDocuments, setAttachDocuments] = useState(false);
  const [referenceNo, setReferenceNo] = useState("");
  // const [referenceError, setReferenceError] = useState(false);
  const [Files, setFiles] = useState([]);
  const [departmentList, setDepartmentList] = useState([])
  const [departmentId, setDepartmentId] = useState('')
  // const [fileError, setFileError] = useState(false);
  // const [selectedFileCount, setSelectedFileCount] = useState(0);
  const [billDate, setBillDate] = useState("");
  // const [subsidiary, setSubsidiary] = useState('');
  const [loaderTwo, setLoaderTwo] = useState(false);
  const [disable, setDisable] = useState(false);
  const [lineLevelGst, setLineLevelGst] = useState(false);
  //  advance payement work //
  const [isAdvancePayment, setIsAdvancePayment] = useState(false);
  const [advancePayment, setAdvancePayment] = useState("");
  // const [advancePaymentError, setAdvancePaymentError] = useState(false);
  const { errors, setErrors, validateForm } = AddBillValidation();
  const [billEndDate, setBillEndDate] = useState('');

  const fetchData = async () => {
    setLoader(true);
    const result = await getApi(`${BILL_DETAILS}/${id}`);
    setLoader(false);
    const minBillDate = moment(result.res.bill_details.startDate).format()?.slice(0, 10);
    const contractEndDate = moment(result.res.bill_details.endDate).format()?.slice(0, 10);

    const TermCount = result.res.bill_details.term.replace(/\D/g, "");
    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + parseInt(TermCount));

    const updateData =
      result.res.bill_details.AdvanceTotal !== 0
        ? result.res.bill_details.lines.map((item) => ({
          ...item,
          quantity: item?.remainingQuantity,
          totalAmount: Number(`${item?.rate * item?.remainingQuantity}`),
        }))
        : result.res.bill_details.lines;

    setInvoiceItems({
      ...result.res.bill_details,
      lines: updateData,
      termCount: parseInt(TermCount),
      minBillDate: minBillDate,
      contractEndDate: contractEndDate,
      dueDate: dueDate.toISOString().split("T")[0],
    });
  };

  const maxBillDate = moment(InvoiceItem.contractEndDate).add(billEndDate, 'days').format().slice(0, 10);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    const fetchDepartment = async () => {

      try {
        const departmentResult = await getApi(DEPARTMENT_LIST)
        setDepartmentList(departmentResult.res.department_list);

        let endDateResult = await getApi(SITE_CONFIGERATION);
        endDateResult = endDateResult.res.siteConfigeration_list.find(i => i.id === BILL_SITE_CONFIGERATION_ID);
        setBillEndDate(endDateResult.Days)

      } catch (error) {
        console.log(error);
      }
    };
    fetchDepartment();
  }, [])

  const handleDateChange = (e) => {
    const date = e.target.value;
    const termCount = InvoiceItem.termCount;

    if (date) {
      const newDueDate = new Date(date);
      newDueDate.setDate(newDueDate.getDate() + termCount);

      setBillDate(date);
      setInvoiceItems({
        ...InvoiceItem,
        dueDate: newDueDate.toISOString().split("T")[0],
      });
    } else {
      setBillDate("");
      setInvoiceItems({
        ...InvoiceItem,
        dueDate: "",
      });
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors['billDate'];
      return newErrors;
    });
  };

  const handleReferenceChange = (event) => {
    const { value } = event.target;

    setReferenceNo(value);
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors['refNo'];
      return newErrors;
    });
  };

  useEffect(() => {
    const calculateFinalTotalAmount = () => {
      if (InvoiceItem.lines && InvoiceItem.lines.length > 0) {
        const total = InvoiceItem.lines.reduce(
          (acc, item) => acc + parseFloat(item.totalAmount || 0),
          0
        );
        setFinalTotalAmount(total.toFixed(2));
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors['subtotal'];
          return newErrors;
        });
      } else {
        setFinalTotalAmount(0);
      }
    };

    calculateFinalTotalAmount();
  }, [InvoiceItem.lines]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const isValid = !isNaN(value) && value >= 0 && value <= 100;
    const hasValidDecimal = /^(\d{0,3})(\.\d{0,2})?$/.test(value);

    if (isValid && hasValidDecimal) {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors['taxError'];
      return newErrors;
    });
  };

  useEffect(() => {
    if (!lineLevelGst) {
      const cgst = !isNaN(parseFloat(data.cgst)) ? parseFloat(data.cgst) : 0;
      const sgst = !isNaN(parseFloat(data.sgst)) ? parseFloat(data.sgst) : 0;
      const utgst = !isNaN(parseFloat(data.utgst)) ? parseFloat(data.utgst) : 0;
      const igst = !isNaN(parseFloat(data.igst)) ? parseFloat(data.igst) : 0;
      const salesTax = !isNaN(parseFloat(data.salesTax)) ? parseFloat(data.salesTax) : 0;

      let taxSubTotal = 0, total = 0, vatTotal = 0;

      if (InvoiceItem.subsidiaryId === 2) {

        taxSubTotal = ((cgst + sgst + utgst + igst + salesTax) * parseFloat(finalTotalAmount)) / 100;
        total = taxSubTotal + parseFloat(finalTotalAmount);

        setData((prevData) => ({
          ...prevData,
          taxSubTotal: taxSubTotal.toFixed(2),
          total: total.toFixed(2),
        }));

      }

      else {

        vatTotal = ((cgst + sgst + utgst + igst + salesTax) * parseFloat(finalTotalAmount)) / 100;
        total = vatTotal + parseFloat(finalTotalAmount);

        setData((prevData) => ({
          ...prevData,
          vatTotal: vatTotal.toFixed(2),
          total: total.toFixed(2),
        }));

      }

    }

    else {
      const updatedTaxTotal = InvoiceItem.lines.reduce(
        (acc, item) => acc + parseFloat(item.totalGSTamount || 0),
        0
      );

      const updatedTotal = updatedTaxTotal + parseFloat(finalTotalAmount);

      setData((prevData) => ({
        ...prevData,
        taxSubTotal: updatedTaxTotal.toFixed(2),
        total: updatedTotal.toFixed(2),
      }));
    }
  }, [data.cgst, data.sgst, data.utgst, data.igst, data.salesTax, finalTotalAmount, lineLevelGst, InvoiceItem.subsidiaryId]);

  const handleCheck = (e) => {
    const check = e.target.checked;
    setLineLevelGst(check);

    if (check) {
      const updatedTaxTotal = InvoiceItem.lines.reduce(
        (acc, item) => acc + parseFloat(item.totalGSTamount || 0),
        0
      );

      const updatedTotal = updatedTaxTotal + parseFloat(finalTotalAmount);

      setData((prevData) => ({
        ...prevData,
        taxSubTotal: updatedTaxTotal.toFixed(2),
        total: updatedTotal.toFixed(2),
      }));
    }
  };

  // -----------new code ---------------------//
  const handleQuantityChange = (index, newQuantity, maxValue) => {
    const updatedLines = [...InvoiceItem.lines];
    const rate = updatedLines[index].rate;
    const quantity = isNaN(newQuantity) ? "" : newQuantity;

    if (maxValue >= quantity) {
      updatedLines[index].quantity = quantity;

      const totalAmount = rate * quantity;

      updatedLines[index].totalAmount = totalAmount.toFixed(2);

      const cgst = parseFloat(updatedLines[index].cgst || 0);
      const sgst = parseFloat(updatedLines[index].sgst || 0);
      const utgst = parseFloat(updatedLines[index].utgst || 0);
      const igst = parseFloat(updatedLines[index].igst || 0);

      const totalGSTamount = (totalAmount * (cgst + sgst + utgst + igst)) / 100;

      updatedLines[index].totalGSTamount = totalGSTamount.toFixed(2);

      const updatedTaxTotal = updatedLines.reduce(
        (acc, item) => acc + parseFloat(item.totalGSTamount || 0),
        0
      );

      const updatedTotal = updatedTaxTotal + parseFloat(finalTotalAmount);

      setData((prevData) => ({
        ...prevData,
        taxSubTotal: updatedTaxTotal.toFixed(2),
        total: updatedTotal.toFixed(2),
      }));

      setInvoiceItems((prevState) => ({
        ...prevState,
        lines: updatedLines,
      }));
    }
  };

  const handleLineLevelGstChange = (index, e) => {
    const { name, value } = e.target;

    const isValidval = value >= 0 && value <= 100;
    const hasValidDecimal = /^(\d{0,5})(\.\d{0,2})?$/.test(value);

    if (isValidval && hasValidDecimal) {
      const updatedLines = [...InvoiceItem.lines];
      updatedLines[index][name] = value || "";

      const totalAmount = updatedLines[index].totalAmount || 0;
      const cgst = parseFloat(updatedLines[index].cgst || 0);
      const sgst = parseFloat(updatedLines[index].sgst || 0);
      const utgst = parseFloat(updatedLines[index].utgst || 0);
      const igst = parseFloat(updatedLines[index].igst || 0);

      const totalGSTamount = (totalAmount * (cgst + sgst + utgst + igst)) / 100;
      updatedLines[index].totalGSTamount = totalGSTamount.toFixed(2);

      setInvoiceItems((prevState) => ({
        ...prevState,
        lines: updatedLines,
      }));

      const updatedTaxTotal = updatedLines.reduce(
        (acc, item) => acc + parseFloat(item.totalGSTamount || 0),
        0
      );
      const updatedTotal = updatedTaxTotal + parseFloat(finalTotalAmount);

      setData((prevData) => ({
        ...prevData,
        taxSubTotal: updatedTaxTotal.toFixed(2),
        total: updatedTotal.toFixed(2),
      }));
    }
  };

  const handleNegative = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/-/g, "");
    e.target.value = newValue;
  };

  const handleFileInputChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    const updatedErrors = { ...errors };
    delete updatedErrors.files;

    setErrors(updatedErrors);

    setFiles(selectedFiles);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const isFormValid = validateForm(
        billDate,
        // isAdvancePayment,
        finalTotalAmount,
        lineLevelGst,
        data,
        InvoiceItem.subsidiaryId,
        Files,
        InvoiceItem.lines,
        user.RoleId,
        departmentId
      );

      if (isFormValid) {

        setLoaderTwo(true);
        setDisable(true);

        let File = [];
        const words = InvoiceItem?.vendorName.split(" ");
        const vendorName = words[0].trim();
        const vendorNameLowerCase = vendorName.toLowerCase();
        const fileUploadPromises = Files.map(async (file) => {
          const result = await uploadFile(
            "bills",
            vendorNameLowerCase,
            InvoiceItem.vendorId,
            file
          );
          const singlefile = {
            FileName: result.fileName,
            FilePath: result.filePath,
          };
          File.push(singlefile);
        });
        await Promise.all(fileUploadPromises);

        let linesData;

        const RemoveZeroLine = InvoiceItem.lines.filter(
          (item) => item.remainingQunatity != 0 && item.quantity > 0
        );

        if (isAdvancePayment) {
          linesData = InvoiceItem?.lines.map((row) => ({
            itemId: Number(row.itemId),
            rate: Number(row.rate),
            quantity: 0,
            totalAmount: 0,
            contractLineId: Number(row.ContractLineId),
          }));
        }
        else {
          linesData = RemoveZeroLine.map((row) => {
            const newline = {
              itemId: Number(row.itemId),
              rate: Number(row.rate),
              quantity: Number(row.quantity),
              totalAmount: Number(row.totalAmount),
              contractLineId: Number(row.ContractLineId),
              itemDescription: row.itemDescription || null
            };

            if (lineLevelGst) {
              newline.cgst = row.cgst ? Number(row.cgst) : null;
              newline.sgst = row.sgst ? Number(row.sgst) : null;
              newline.utgst = row.utgst ? Number(row.utgst) : null;
              newline.igst = row.igst ? Number(row.igst) : null;
              newline.gstAmount = row.totalGSTamount ? Number(row.totalGSTamount) : null;
            }
            return newline;
          });
        }

        const result = await postApi(CREATE_BILL, {
          vendorId: Number(InvoiceItem.vendorId),
          contractId: Number(InvoiceItem.contractId),
          transactionId: transactionList[1].id,
          roleId: user.RoleId,
          subsidiaryId: InvoiceItem.subsidiaryId,
          departmentId: departmentId ? Number(departmentId) : null,
          billDate: billDate,
          referenceNo: referenceNo,
          dueDate: InvoiceItem.dueDate,
          isLineLevelGst: lineLevelGst,
          isAdvancePayment: isAdvancePayment,
          itemDescription: linesData,
          // invoiceTotalAmount: isAdvancePayment ? Number(advancePayment) : Number(finalTotalAmount),
          invoiceTotalAmount: Number(finalTotalAmount),
          cgst: data.cgst ? Number(data.cgst) : null,
          sgst: data.sgst ? Number(data.sgst) : null,
          utgst: data.utgst ? Number(data.utgst) : null,
          igst: data.igst ? Number(data.igst) : null,
          salesTax: data.salesTax || null,
          gstTotal: data.taxSubTotal ? Number(data.taxSubTotal) : null,
          vatTotal: Number(data.vatTotal) || null,
          grandTotal: Number(data.total),
          // grandTotal: isAdvancePayment ? Number(advancePayment) : Number(data.total),
          invoiceDocument: File,
        });

        if (result.res.success) {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: result.res.message,
            showConfirmButton: false,
            timer: 2000,
          });
          navigate("/invoice-listing");
        } else {
          setErrors({ refNo: result.res.message });
          setLoaderTwo(false);
          setDisable(false);
        }
      }
    } catch (error) {
      setLoaderTwo(false);
      setDisable(false);
    }
  };

  // advance payment work //

  const handleIsAdvancePayment = (e) => {
    setIsAdvancePayment(e.target.checked);
    setLineLevelGst(false);
  };

  const handleAdvancPaymentValue = (e) => {
    const { value } = e.target;
    if (
      Number(value) + Number(InvoiceItem?.totalInvoicedAmount) <
      InvoiceItem?.contractTotal
    ) {
      setAdvancePayment(value);
    }
  };

  const handleDepartmentChange = (e) => {
    setDepartmentId(e.target.value)

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }
      delete updatedErrors.departmentId
      return updatedErrors
    })
  }

  const handleDescriptionChange = (value, index) => {

    const updatedLines = [...InvoiceItem.lines];
    updatedLines[index].itemDescription = value

    setInvoiceItems((prevState) => ({
      ...prevState,
      lines: updatedLines,
    }));

  }

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Bill</title>
      </Helmet>

      <Loader isLoading={loader} />

      <div className="container-section">
        <div className="section-header">
          <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
            <div className="svgsize">
              <BackButton />
            </div>
          </button>
          <h3>Bill</h3>
        </div>

        <form>

          <div className="form-style">
            <div className="row ">

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="vendor" className="form-label">
                  Legal Name
                </label>

                <input
                  type="text"
                  name="vendor"
                  id="vendor"
                  value={InvoiceItem?.vendorName}
                  className="form-control"
                  disabled
                />
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="contractname" className="form-label">
                  Purchase Contract
                </label>

                <input
                  type="text"
                  id="contractname"
                  value={InvoiceItem?.documentNo}
                  className="form-control"
                  name="contractname"
                  disabled
                />
              </div>

              {user.RoleId === ADMIN_MANAGER_ID && (

                <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                  <label htmlFor="departmentId" className="form-label">
                    Department<span className="required">*</span>
                  </label>
                  <select name="departmentId" id="departmentId" className="form-select" value={departmentId} onChange={handleDepartmentChange}>
                    <option value="">Select</option>
                    {departmentList.map((item, index) => (
                      <option key={index} value={item.DepartmentId}>
                        {item.DepartmentName}
                      </option>
                    ))}
                  </select>
                  {errors.departmentId && <div className="validation-bill-err">{errors.departmentId}</div>}
                </div>

              )}

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="refNo" className="form-label">
                  Reference Number
                </label>

                <input
                  type="text"
                  id="refNo"
                  placeholder=" Enter reference number"
                  className="form-control"
                  name="refNo"
                  value={referenceNo}
                  maxLength={20}
                  onChange={handleReferenceChange}
                  autoComplete="off"
                />
                {errors.refNo && (
                  <div className="validation-bill-err">
                    {errors.refNo}
                  </div>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="pendingbill" className="form-label">
                  Total
                </label>

                <input
                  type="text"
                  name="pendingbill"
                  id="pendingbill"
                  value={`${InvoiceItem?.currencySymbol} ${InvoiceItem?.contractTotal?.toFixed(2)}`}
                  className="form-control"
                  disabled
                />
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="totalbilled" className="form-label">
                  Total Billed
                </label>

                <input
                  type="text"
                  name="totalbilled"
                  id="totalbilled"
                  value={`${InvoiceItem?.currencySymbol} ${InvoiceItem?.totalInvoicedAmount?.toFixed(2)}`}
                  className="form-control"
                  disabled
                />
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="billDate" className="form-label">
                  Bill Date<span className="required">*</span>
                </label>

                <input
                  type="date"
                  name="billDate"
                  id="billDate"
                  value={billDate}
                  className="form-control"
                  min={InvoiceItem?.minBillDate}
                  max={maxBillDate}
                  onChange={handleDateChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
                {errors.billDate && (
                  <div className="validation-bill-err">{errors.billDate}</div>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="dueDate" className="form-label">
                  Due Date
                </label>

                <input
                  type="date"
                  name="dueDate"
                  id="dueDate"
                  className="form-control"
                  value={InvoiceItem?.dueDate}
                  disabled
                />
              </div>

              {/* {InvoiceItem?.AdvanceTotal !== 0 && (
                  <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                    <label htmlFor="dueDate" className="form-label">
                      Advance Paid Amount
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      value={`\u20b9 ${InvoiceItem?.AdvanceTotal?.toFixed(2)}`}
                      disabled
                    />
                  </div>
                )} */}
              {/* {InvoiceItem?.AdvancePaymentDate && (
                  <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                    <label htmlFor="dueDate" className="form-label">
                      Advance Payment Date
                    </label>

                    <input
                      type="date" // Use type="text" instead of type="date"
                      name="dueDate"
                      id="dueDate"
                      className="form-control"
                      value={InvoiceItem?.AdvancePaymentDate?.slice(0, 10)}
                      disabled // Use readOnly instead of disabled
                    />
                  </div>
                )} */}
              {/* {
                  InvoiceItem?.AdvanceTotal == 0 && <div className="col-2">
                    <div className='d-flex algin-item-center'>
                      <input type="checkbox" name="" id="" checked={isAdvancePayment} onChange={(e) => handleIsAdvancePayment(e)} />
                      <p className='ms-1'>Advance Payment</p>
                    </div>

                  </div>
                } */}
              {/* 
                {!isAdvancePayment && (
                  <div className="col-xl-3 col-lg-4 col-md-3">
                    <div className="d-flex algin-item-center">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        checked={lineLevelGst}
                        onChange={(e) => handleCheck(e)}
                      />
                      <p className="ms-1">Line Level GST</p>
                    </div>
                  </div>
                )} */}
            </div>
          </div>

          <div className="form-style">
            {!isAdvancePayment && (InvoiceItem?.subsidiaryId === 2) && (
              <div className="col-xl-3 col-lg-4 col-md-3 mb-2">
                <div className="d-flex algin-item-center">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    checked={lineLevelGst}
                    className="form-check-input"
                    onChange={(e) => handleCheck(e)}
                  />
                  <label className="ms-2 form-check-label">Line Level GST</label>
                </div>
              </div>
            )}
            <>
              <div className="section-sub-heading">
                <h3>Item Description</h3>
              </div>
              <div className="inv-tbl">
                <div className="table-responsive tbl-body-shadow">
                  <table className="w-100 table-theme-1 ">
                    <thead className="table-heads">
                      <tr>
                        <th className="text-start">Item</th>
                        <th className="text-start">Description</th>
                        <th className="text-end">Rate</th>
                        <th className="text-end">Quantity</th>
                        {lineLevelGst && (
                          <>
                            <th className="text-end">CGST(%)</th>
                            <th className="text-end">SGST(%)</th>
                            <th className="text-end">UTGST(%)</th>
                            <th className="text-end">IGST(%)</th>
                            <th className="text-end">GST Amount</th>
                          </>
                        )}
                        <th className="text-end">Amount</th>
                      </tr>
                    </thead>

                    <tbody>
                      {InvoiceItem?.lines?.map((row, index) => (
                        <tr key={index}>
                          <td className="custom-width text-nowrap">{row.itemName}</td>
                          <td className="custom-width">
                            <textarea name="itemDescription" className="form-control description-width" rows="1" cols="50" value={row.itemDescription || ''}
                              onChange={(e) => handleDescriptionChange(e.target.value, index)}></textarea></td>
                          <td className="custom-width text-end text-nowrap">
                            {InvoiceItem?.currencySymbol} {row.rate?.toFixed(2)}
                          </td>
                          <td className="custom-width">
                            <input
                              type="number"
                              className="form-control text-end"
                              name={`quantity_${index}`}
                              value={row.quantity}
                              onChange={(e) =>
                                handleQuantityChange(
                                  index,
                                  e.target.value,
                                  row.remainingQuantity
                                )
                              }
                              onInput={handleNegative}
                              placeholder={`max ${row.remainingQuantity}`}
                              max={row.remainingQunatity}
                              min={0}
                              disabled={
                                row.remainingQuantity == 0 ||
                                isAdvancePayment ||
                                InvoiceItem?.AdvanceTotal !== 0
                              }
                            />
                          </td>
                          {lineLevelGst && (
                            <>
                              <td className="custom-width">
                                <input
                                  type="text"
                                  className="form-control text-end"
                                  name="cgst"
                                  placeholder="CGST(%)"
                                  value={row.cgst}
                                  onChange={(e) =>
                                    handleLineLevelGstChange(index, e)
                                  }
                                  onInput={handleNegative}
                                  disabled={
                                    row.utgst ||
                                    row.igst ||
                                    row.remainingQunatity == 0
                                  }
                                />
                              </td>
                              <td className="custom-width">
                                <input
                                  type="text"
                                  className="form-control text-end"
                                  name="sgst"
                                  placeholder="SGST(%)"
                                  value={row.sgst}
                                  onChange={(e) =>
                                    handleLineLevelGstChange(index, e)
                                  }
                                  onInput={handleNegative}
                                  disabled={
                                    row.utgst ||
                                    row.igst ||
                                    row.remainingQunatity == 0
                                  }
                                />
                              </td>
                              <td className="custom-width">
                                <input
                                  type="text"
                                  className="form-control text-end"
                                  name="utgst"
                                  placeholder="UTGST(%)"
                                  value={row.utgst}
                                  onChange={(e) =>
                                    handleLineLevelGstChange(index, e)
                                  }
                                  onInput={handleNegative}
                                  disabled={
                                    row.cgst ||
                                    row.sgst ||
                                    row.igst ||
                                    row.remainingQunatity == 0
                                  }
                                />
                              </td>
                              <td className="custom-width ">
                                <input
                                  type="text"
                                  className="form-control text-end"
                                  name="igst"
                                  placeholder="IGST(%)"
                                  value={row.igst}
                                  onChange={(e) =>
                                    handleLineLevelGstChange(index, e)
                                  }
                                  onInput={handleNegative}
                                  disabled={
                                    row.cgst ||
                                    row.sgst ||
                                    row.utgst ||
                                    row.remainingQunatity == 0
                                  }
                                />
                              </td>
                              <td
                                className="custom-width text-end text-nowrap"

                              >
                                {row.totalGSTamount && "\u20b9"}
                                {row.totalGSTamount}
                              </td>
                            </>
                          )}
                          <td className="custom-width text-end text-nowrap">
                            {row.totalAmount && InvoiceItem?.currencySymbol} {row.totalAmount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="mb-20 d-flex justify-content-end inv-gst-tbl-cont mb-3 mt-4">
                <div className="cstm-width">

                  {isAdvancePayment ? (
                    <>
                      <div className="d-flex align-items-center mt-4 mb-3">
                        <span className="gst-cont-label">Sub Total</span>
                        <span className="gst-cont-semicolumn">:</span>
                        <span className="gst-cont-input">

                          <input
                            className="form-control text-end"
                            value={advancePayment}
                            type="text"
                            id="finalTotalAmount"
                            placeholder="Sub Total"
                            onChange={(e) => handleAdvancPaymentValue(e)}
                          />
                        </span>
                      </div>

                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center mb-3">
                        <span className="gst-cont-label">Sub Total</span>
                        <span className="gst-cont-semicolumn">:</span>
                        <span className="gst-cont-input">

                          <input
                            className="form-control text-end"
                            type="text"
                            id="finalTotalAmount"
                            value={`${InvoiceItem?.currencySymbol} ${finalTotalAmount}`}
                            disabled
                          />
                          {errors.subtotal && (
                            <div className="validation-bill-err mb-2">{errors.subtotal}
                            </div>
                          )}
                        </span>
                      </div>


                      {!lineLevelGst && InvoiceItem?.subsidiaryId === 2 &&
                        <>
                          <div className="d-flex align-items-center mb-2">
                            <span className="gst-cont-label">CGST(%)</span>
                            <span className="gst-cont-semicolumn">:</span>
                            <span className="gst-cont-input">

                              <input
                                type="text"
                                className="form-control text-end"
                                id="cgst"
                                name="cgst"
                                placeholder="Enter CGST(%)"
                                value={data.cgst}
                                onChange={handleChange}
                                disabled={data.utgst || data.igst}
                                autoComplete="off"
                              />
                            </span>
                          </div>


                          <div className="d-flex align-items-center mb-2">
                            <span className="gst-cont-label">SGST(%)</span>
                            <span className="gst-cont-semicolumn">:</span>
                            <span className="gst-cont-input">

                              <input
                                type="text"
                                className="form-control text-end"
                                id="sgst"
                                name="sgst"
                                placeholder="Enter SGST(%)"
                                value={data.sgst}
                                onChange={handleChange}
                                disabled={data.utgst || data.igst}
                                autoComplete="off"
                              />
                            </span>
                          </div>


                          <div className="d-flex align-items-center  mb-2">
                            <span className="gst-cont-label">UTGST(%)</span>
                            <span className="gst-cont-semicolumn">:</span>
                            <span className="gst-cont-input">

                              <input
                                type="text"
                                className="form-control text-end"
                                id="utgst"
                                name="utgst"
                                placeholder="Enter UTGST(%)"
                                value={data.utgst}
                                onChange={handleChange}
                                disabled={
                                  data.cgst || data.sgst || data.igst
                                }
                                autoComplete="off"
                              />
                            </span>
                          </div>

                          <div className="d-flex align-items-center mb-3">
                            <span className="gst-cont-label">IGST(%)</span>
                            <span className="gst-cont-semicolumn">:</span>
                            <span className="gst-cont-input">

                              <input
                                type="text"
                                className="form-control text-end"
                                id="igst"
                                name="igst"
                                placeholder="Enter IGST(%)"
                                value={data.igst}
                                onChange={handleChange}
                                disabled={
                                  data.cgst || data.sgst || data.utgst
                                }
                                autoComplete="off"
                              />
                              {errors.taxError && (
                                <div className="validation-bill-err text-end mb-4">
                                  {errors.taxError}
                                </div>
                              )}
                            </span>
                          </div>

                        </>
                      }

                      {InvoiceItem?.subsidiaryId !== 2 &&
                        <>
                          <div className="d-flex align-items-center mb-2">
                            <span className="gst-cont-label">Sales Tax(%)</span>
                            <span className="gst-cont-semicolumn">:</span>
                            <span className="gst-cont-input">

                              <input
                                type="text"
                                className="form-control text-end"
                                id="salesTax"
                                name="salesTax"
                                placeholder="Enter Sales Tax(%)"
                                value={data.salesTax}
                                onChange={handleChange}
                                autoComplete="off"
                              />
                              {errors.taxError && (
                                <div className="validation-bill-err text-end mb-4">
                                  {errors.taxError}
                                </div>
                              )}
                            </span>
                          </div>

                        </>
                      }

                      {InvoiceItem?.subsidiaryId === 2 ?
                        <div className="d-flex align-items-center mb-2">
                          <span className="gst-cont-label">GST Total</span>
                          <span className="gst-cont-semicolumn">:</span>
                          <span className="gst-cont-input">
                            <input
                              type="text"
                              className="form-control text-end"
                              id="taxSubTotal"
                              name="taxSubTotal"
                              value={`${InvoiceItem?.currencySymbol} ${data.taxSubTotal}`}
                              disabled
                            />
                          </span>
                        </div>

                        :
                        <div className="d-flex align-items-center mb-2">
                          <span className="gst-cont-label">Vat Total</span>
                          <span className="gst-cont-semicolumn">:</span>
                          <span className="gst-cont-input">
                            <input
                              type="text"
                              className="form-control text-end"
                              id="taxSubTotal"
                              name="taxSubTotal"
                              value={`${InvoiceItem?.currencySymbol} ${data.vatTotal}`}
                              disabled
                            />
                          </span>
                        </div>

                      }

                      <div className="d-flex align-items-center mb-2">
                        <span className="gst-cont-label">Grand Total</span>
                        <span className="gst-cont-semicolumn">:</span>
                        <span className="gst-cont-input ">

                          <input
                            type="text"
                            className="form-control text-end"
                            id="total"
                            name="total"
                            value={`${InvoiceItem?.currencySymbol} ${data.total}`}
                            disabled
                          />

                        </span>
                      </div>

                    </>
                  )}
                </div>
              </div>
            </>

            <div className="row mb-2">
              <div className="col-md-12">
                <input
                  type="checkbox"
                  id="attachDocuments"
                  className="form-check-input"
                  name="attachDocuments"
                  checked={attachDocuments}
                  onChange={(e) => setAttachDocuments(e.target.checked)}
                />

                <label
                  htmlFor="attachDocuments"
                  className="form-check-label ms-2"
                >
                  Check for attach documents
                  <span className="required">*</span>
                </label>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-xl-4 col-lg-4 col-md-5">
                <div className="input-group mmm">
                  <input
                    type="file"
                    accept=".pdf"
                    className="form-control"
                    disabled={!attachDocuments}
                    onChange={handleFileInputChange}
                    multiple
                  />
                </div>

                {errors.files && (
                  <div className="validation-bill-err">{errors.files}</div>
                )}
              </div>
            </div>

            <div className=" d-flex align-items-center justify-content-end mb-20">
              <div className="cstm-success-btn ms-2">
                <button
                  type="submit"
                  className="btn"
                  onClick={(e) => handleSubmit(e)}
                  disabled={disable}
                >
                  {loaderTwo ? (
                    <>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>

        </form>
      </div>

    </>
  );
};

export default BillAdd;
