import React from "react";

const CreateBillIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="23"
                viewBox="0 0 22 22"
                fill="none"
            >
                <path
                    d="M20.1667 0H1.83333C1.3471 0 0.880787 0.193154 0.536971 0.536971C0.193154 0.880787 0 1.3471 0 1.83333V20.1667C0 20.6529 0.193154 21.1192 0.536971 21.463C0.880787 21.8068 1.3471 22 1.83333 22H20.1667C20.6529 22 21.1192 21.8068 21.463 21.463C21.8068 21.1192 22 20.6529 22 20.1667V1.83333C22 1.3471 21.8068 0.880787 21.463 0.536971C21.1192 0.193154 20.6529 0 20.1667 0ZM17.4167 11.9167H11.9167V17.4167C11.9167 17.6598 11.8201 17.8929 11.6482 18.0648C11.4763 18.2368 11.2431 18.3333 11 18.3333C10.7569 18.3333 10.5237 18.2368 10.3518 18.0648C10.1799 17.8929 10.0833 17.6598 10.0833 17.4167V11.9167H4.58333C4.34022 11.9167 4.10706 11.8201 3.93515 11.6482C3.76324 11.4763 3.66667 11.2431 3.66667 11C3.66667 10.7569 3.76324 10.5237 3.93515 10.3518C4.10706 10.1799 4.34022 10.0833 4.58333 10.0833H10.0833V4.58333C10.0833 4.34022 10.1799 4.10706 10.3518 3.93515C10.5237 3.76324 10.7569 3.66667 11 3.66667C11.2431 3.66667 11.4763 3.76324 11.6482 3.93515C11.8201 4.10706 11.9167 4.34022 11.9167 4.58333V10.0833H17.4167C17.6598 10.0833 17.8929 10.1799 18.0648 10.3518C18.2368 10.5237 18.3333 10.7569 18.3333 11C18.3333 11.2431 18.2368 11.4763 18.0648 11.6482C17.8929 11.8201 17.6598 11.9167 17.4167 11.9167Z"
                    fill="white"
                />
            </svg>
        </>
    );
}

export default CreateBillIcon;
