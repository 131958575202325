import { useMemo, useContext } from 'react';
import { useStatusRenderer } from '../useStatus';
import { useNavigate } from 'react-router-dom';
import eyeIcon from "../../../assets/image/eye.png"
import ApproveButton from '../../../assets/image/ApproveButton';
import ApproveVendor from '../../../components/modal/ApproveVendor';
import RejectButton from '../../../assets/image/RejectButton';
import { ModalContext } from '../../Context';
import edit from "../../../assets/image/edit2.png"
import { ADMIN_EXECUTIVE_ID, ADMIN_MANAGER_ID, VENDOR_MANAGER_PENDING, VENDOR_REJECT, VENDOR_VP_PENDING, VP_ID } from '../../constant';
import RejectVendor from '../../../components/modal/RejectVendor';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Custom hook for defining FeedsColumns
export const useFeedsColumnsVendor = (handleVendorList) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;

  const handleApprove = (data) => {
    let app = <ApproveVendor data={data} handleVendorList={handleVendorList} />
    handleModalData(app, "md");
  }

  const handleReject = (data) => {
    let rej = <RejectVendor data={data} handleVendorList={handleVendorList} />
    handleModalData(rej, "md");
  }

  const handlerEditVendor = (data) => {
    navigate(`/edit-vendor/${data.id}`)
  }

  const handleViewVendor = (id) => {
    navigate(`/view-vendor/${id}`)
  }

  const columns = useMemo(() => [
    {
      Header: "Vendor ID",
      accessor: "VendorId",
    },
    {
      Header: "Vendor Name",
      accessor: "LegalName",
    },

    {
      Header: "Primary Email ID",
      accessor: "EmailId",
    },
    {
      Header: "State",
      accessor: "State",

    },
    {
      Header: "Country",
      accessor: "Country",

    },

    {
      Header: <div className="ps-5">Status</div>,
      accessor: "StatusName",
      Cell: ({ row }) => {
        return (
          <>
            {
              useStatusRenderer(row)
            }
          </>
        );
      },
      disableSortBy: true,

    },
    {
      Header: "Action",
      accessor: (data) => {
        return (
          <>
            <div className="d-flex align-item-center">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips1" >
                    View
                  </Tooltip>
                }
              >
                <img
                  src={eyeIcon}
                  alt=""
                  className="cursor-pointer cstm-eye-icon-gap"
                  onClick={() => handleViewVendor(data.id)}
                />
              </OverlayTrigger>

              {((data.Status === VENDOR_MANAGER_PENDING &&
                user.RoleId === ADMIN_MANAGER_ID) ||
                (data.Status === VENDOR_VP_PENDING &&
                  user.RoleId === VP_ID)) && (

                  <span className="d-flex align-item-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips2">
                          Approve
                        </Tooltip>
                      }
                    >
                      <span
                        className="cstm-icon-gap cursor-pointer"
                        onClick={() => handleApprove(data)}
                      >
                        <ApproveButton />
                      </span>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips3">
                          Reject
                        </Tooltip>
                      }
                    >
                      <span
                        className="cstm-icon-gap cursor-pointer"
                        onClick={() => handleReject(data)}
                      >
                        <RejectButton />
                      </span>
                    </OverlayTrigger>
                  </span>

                )}

              {user?.RoleId === ADMIN_EXECUTIVE_ID &&
                data.Status === VENDOR_REJECT && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips4">
                        Edit
                      </Tooltip>
                    }
                  >
                    <img
                      src={edit}
                      alt=""
                      className="cstm-icon-gap cursor-pointer"
                      onClick={() => handlerEditVendor(data)}
                    />
                  </OverlayTrigger>
                )}
            </div>
          </>
        );
      },
    },
  ], []);

  return columns;
};