import React, { useState } from "react";
import TabBar from "./TabBar"
import BillReport from "./BillReport";
import IndividualBillReport from "./IndividualBillReport";
import PaymentReport from "./PaymentReport";
import ContractReport from "./ContractReport";
import { Helmet } from "react-helmet";

const Report = () => {

  const tabs = [
    { id: 'Purchase Contract', label: 'Purchase Contract' },
    { id: 'Bill', label: 'Bill' },
    { id: 'Standalone Bill', label: 'Standalone Bill' },
    { id: 'Payment', label: 'Payment' },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].id);

  const handleTabChange = (tabId) => {
    setCurrentTab(tabId);
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Report</title>
      </Helmet>

      <div className="container-section">

        <TabBar tabs={tabs} defaultTab={currentTab} onTabChange={handleTabChange} />
        {currentTab === 'Purchase Contract' && <ContractReport />}
        {currentTab === 'Bill' && <BillReport />}
        {currentTab === 'Standalone Bill' && <IndividualBillReport />}
        {currentTab === 'Payment' && <PaymentReport />}

      </div>
    </>
  );
};

export default Report;
