import React from 'react'
import DashboardTable from './DashboardTable'

const DashboardCard = ({ title, columns, data, mt }) => {
  return (
    <div className={`container-section ${mt}`}>
      <div className="section-header">
        <h3>{title}</h3>
      </div>
      <div className="form-style">
        <DashboardTable columns={columns} data={data} />
      </div>
    </div>
  )
}

export default DashboardCard