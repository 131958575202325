import { useState } from 'react';
import { ADMIN_MANAGER_ID } from '../utils/constant';

const AddBillValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (billDate, finalTotalAmount, lineLevelGst, data, subsidiaryId, Files, lines, roleId, departmentId) => {

    let errors = {};
    let formIsValid = true;

    const isValidFileType = (file) => {
      const allowedTypes = ["application/pdf"];
      return allowedTypes.includes(file.type);
    };

    const MAX_FILE_SIZE = 2 * 1024 * 1024;

    if (!billDate) {
      formIsValid = false;
      errors.billDate = "Select Bill Date"
    }

    if (finalTotalAmount === "0.00") {
      formIsValid = false;
      errors.subtotal = "Subtotal cannot be zero !";
    }

    if (!lineLevelGst) {
      if (!data.cgst && !data.sgst && !data.utgst && !data.igst && subsidiaryId === 2) {
        formIsValid = false;
        errors.taxError = "One of the GST Field is required !";
      }
      else if (!data.cgst && data.sgst) {
        formIsValid = false;
        errors.taxError = "Enter CGST";
      }
      else if (data.cgst && !data.sgst) {
        formIsValid = false;
        errors.taxError = "Enter SGST";
      }
      if (!data.salesTax && subsidiaryId === 1) {
        formIsValid = false;
        errors.taxError = "Sales Tax is required !";
      }
    }
    else {
      const missingQuantity = lines.some((item) => {
        return (
          (item.quantity <= 0 || item.Quantity === '' || !item.hasOwnProperty('quantity')) && (item.cgst || item.sgst || item.utgst || item.igst)
        );
      });

      const missingGST = lines.some((item) => {
        return (
          (item.quantity > 0) &&
          (!item.hasOwnProperty('cgst') || item.cgst === '') &&
          (!item.hasOwnProperty('sgst') || item.sgst === '') &&
          (!item.hasOwnProperty('utgst') || item.utgst === '') &&
          (!item.hasOwnProperty('igst') || item.igst === '')
        );
      });

      const cgstAndSgstValidation = lines.some((item) => {
        return (
          ((item.hasOwnProperty('cgst') && item.cgst !== '') && (!item.hasOwnProperty('sgst') || item.sgst === '')) ||
          ((item.hasOwnProperty('sgst') && item.sgst !== '') && (!item.hasOwnProperty('cgst') || item.cgst === ''))
        );
      });

      if (missingGST) {
        alert('One of the GST field is required if quantity is there');
        return;
      }
      else if (missingQuantity) {
        alert('Quantity is required if any GST field is there');
        return;
      }
      else if (cgstAndSgstValidation) {
        alert('Enter both CGST and SGST if one is there');
        return;
      }
    }

    if (!Files || Files.length === 0) {
      formIsValid = false;
      errors.files = "Please upload a document !";
    }
    else if (Files) {
      for (const file of Files) {
        if (!isValidFileType(file)) {
          formIsValid = false;
          errors.files = "Only PDF is allowed !";
          break;
        }
        if (file.size > MAX_FILE_SIZE) {
          formIsValid = false;
          errors.files = "Maximum allowed file size is 2MB !";
          break;
        }
        if (Files.length > 5) {
          formIsValid = false;
          errors.files = "Only 5 files are allowed !";
          break;
        }
      }
    }

    if (roleId === ADMIN_MANAGER_ID) {
      if (!departmentId) {
        formIsValid = false;
        errors.departmentId = "Select Department";
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  return { errors, setErrors, validateForm };
};

export default AddBillValidation;
