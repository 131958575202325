import React, { useContext, useState } from 'react'
import toast from 'react-hot-toast';
import { ModalContext } from '../../utils/Context';
import { postApi } from '../../utils/services';
import { REJECT_INDIVIDUAL_BILL_API } from '../../utils/apiRoutes';
import { useTransactionContext } from '../../utils/helpers/useTransaction';

const RejectToReqIndividualBill = ({ data, handlerIndividualBillList }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const transactionList = useTransactionContext();
    const [disable, setDisable] = useState(false)
    const [rejectReason, setRejectReason] = useState(""); // Add state for reject reason
    const [error, setError] = useState(""); // Add state for error
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;

    const handleReject = async () => {
        try {
            if (!rejectReason) {
                setError("Please provide a rejection reason !");
                return;
            }
            setDisable(true);
            const result = await postApi(REJECT_INDIVIDUAL_BILL_API, {
                "billId": data.id,
                "transactionId": transactionList[4].id,
                "roleId": user.RoleId,
                "level": "",
                "rejectionReason": rejectReason
            })
            handlerIndividualBillList()
            closeModal();
            toast.success(result.res.message)
        } catch (error) {
            console.error("Error rejecting purchase contract:", error);
            toast.error("An unexpected error occurred");
        }
    };

    const handleChangeRejectReason = (e) => {
        setRejectReason(e.target.value);
        setError("");
    };

    return (
        <div className='cstm-frm-mdl'>
            <div class="modal-header">
                <h4 class="modal-title">Rejection Reason</h4>
            </div>
            
            

            <div class="modal-body">
                <div class="form-group mt-2">
                    <label for="" className='form-label'>Are you sure you want to reject this Standalone Bill?</label>
                    <textarea value={rejectReason} className="form-control reject-modal-text-area" placeholder="Enter the reason here..." id="floatingTextarea" rows={3} maxLength={100} onChange={handleChangeRejectReason}></textarea>
                </div>
                {error && <p className="validation-bill-err">{error}</p>}
            </div>



            <div class="modal-footer">
                <div className=" d-flex align-items-center mb-20">
                    <div className="cstm-dngr-btn">
                        <button type="button" className="btn" onClick={() => closeModal()}>Cancel</button>
                    </div>
                    <div className="cstm-reject-btn ms-2">
                        <button type="submit" className="btn" onClick={handleReject} disabled={disable}>
                            Reject
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default RejectToReqIndividualBill
