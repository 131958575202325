import AWS from 'aws-sdk'

AWS.config.update({
    accessKeyId: process.env.REACT_APP_API_ACCESSKEY_ID,
    secretAccessKey: process.env.REACT_APP_API_SECRET_ACCESS_KEY
})

const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
    // region: REGION,
})

export const uploadFile = (transactionName, folderName, vendorName, file) => {

    const timestamp = Date.now();
    let name = vendorName + '_' + folderName;
    let fileName = `${timestamp}_${file?.name}`;
    const params = {
        ACL: process.env.REACT_APP_UPLOAD_ACL,
        Body: file,
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: `images/${transactionName}/${name}/${fileName}`
    };
    return new Promise((resolve, reject) => {
        myBucket.putObject(params)
            .send((err, data) => {
                if (data) {
                    const filePath = `images/${transactionName}/${name}/${fileName}`;
                    resolve({ fileName, filePath }); // Resolve the Promise with the uploaded path
                } else {
                    reject(err); // Reject the Promise with the error
                }
            });
    });
}