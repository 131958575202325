import AdmpIcons from "../../assets/image/AdmpIcons";
import ApprovedIcon from "../../assets/image/ApprovedIcon";
import FinancePendingStatus from "../../assets/image/FinancePendingStatus";
import FullyPaidIcon from "../../assets/image/FullyPaidIcon";
import PaymentIcon from "../../assets/image/PaymentIcon";
import { RejectedIcon } from "../../assets/image/RejectedIcon";
import VpPendingIcon from "../../assets/image/VpPendingIcon";
import { FINANCE_PENDING, FULLY_PAID, PAYMENT_PENDING, VENDOR_APPROVE, VENDOR_MANAGER_PENDING, VENDOR_REJECT, VENDOR_VP_PENDING } from "../constant";

export const useStatusRenderer = (row) => {
  let statusIcon = null;
  switch (row.original.Status || row.original.StatusId) {
    case VENDOR_REJECT:
      statusIcon = <RejectedIcon />;
      break;
    case VENDOR_MANAGER_PENDING:
      statusIcon = <AdmpIcons statusName={row?.original?.StatusName} />;
      break;
    case VENDOR_APPROVE:
      statusIcon = <ApprovedIcon />;
      break;
    case VENDOR_VP_PENDING:
      statusIcon = <VpPendingIcon statusName={row?.original?.StatusName} />;
      break;
    case FINANCE_PENDING:
      statusIcon = <FinancePendingStatus statusName={row?.original?.StatusName} />;
      break;
    case PAYMENT_PENDING:
      statusIcon = <PaymentIcon statusName={row?.original?.StatusName} />;
      break;
    case FULLY_PAID:
      statusIcon = <FullyPaidIcon />;
      break;
    default:
      statusIcon = null
      break;
  }
  return <>{statusIcon}</>;
};