import { useState } from 'react';

const AddBankInfoValidation = () => {
    const [errors, setErrors] = useState({});

    const isValidBankDocument = (file) => {
        const allowedTypes = ["application/pdf"];
        return allowedTypes.includes(file.type);
    };

    const MAX_FILE_SIZE = 2 * 1024 * 1024;

    const validateForm = (paymentModeId, inputValues, bankDocument) => {

        let errors = {};
        let formIsValid = true;

        for (const fieldName in inputValues) {
            if (inputValues.hasOwnProperty(fieldName)) {
                const { fieldValue } = inputValues[fieldName];

                if (!fieldValue.trim()) {
                    formIsValid = false;
                    errors[fieldName] = `Enter ${fieldName}`;
                } else {
                    switch (fieldName) {
                        case 'Bank Name':
                            if (!/^[a-zA-Z\s]+$/.test(fieldValue)) {
                                formIsValid = false;
                                errors[fieldName] = 'Only alphabetic keywords allowed !';
                            }
                            break;
                        case 'Account Number':
                            if (fieldValue.length < 5 || fieldValue.length > 25) {
                                formIsValid = false;
                                errors[fieldName] = 'Account number should have 5-25 digits';
                            }
                            break;
                        case 'IFSC Code':
                            if (fieldValue.length !== 11) {
                                formIsValid = false;
                                errors[fieldName] = 'IFSC Code should have 11 digits';
                            }
                            break;
                        default:
                            break;
                    }
                }
            }

        }
        if (!paymentModeId) {
            formIsValid = false;
            errors.paymentModeId = "Select Payment Mode";
        }
        if (paymentModeId == 1) {
            if (bankDocument.length === 0) {
                formIsValid = false;
                errors.bankDocument = "Please upload a document !";
            }
            if (bankDocument) {
                for (const item of bankDocument) {
                    if (!isValidBankDocument(item)) {
                        formIsValid = false;
                        errors.bankDocument = "Only PDF is allowed !"
                        break;
                    }
                    if (item.size > MAX_FILE_SIZE) {
                        formIsValid = false;
                        errors.bankDocument = "Maximum allowed file size is 2MB !";
                        break;
                    }
                }
            }

        }
        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default AddBankInfoValidation;
