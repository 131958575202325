import { useState } from 'react';

const EditContractValidation = () => {
    const [errors, setErrors] = useState({});

    const isValidFileType = (file) => {
        const allowedTypes = ["application/pdf"];
        return allowedTypes.includes(file.type);
    };

    const MAX_FILE_SIZE = 2 * 1024 * 1024;

    const validateForm = (startDate, endDate, lineItems, contractTotal, files, contractDocument) => {

        let errors = {};
        errors.lineItems = [];
        let formIsValid = true;
        
        if (!startDate) {
            formIsValid = false;
            errors.startDate = "Select Start Date"
        }
        if (!endDate) {
            formIsValid = false;
            errors.endDate = "Select End Date"
        }
        if (contractTotal === "0.00") {
            formIsValid = false;
            errors.contractTotal = "Total Amount can't be zero !";
        }
        if (files.length === 0) {
            if (contractDocument.length === 0) {
                formIsValid = false;
                errors.files = "Please upload a document !";
            }
            else {
                for (const item of contractDocument) {
                    if (!isValidFileType(item)) {
                        formIsValid = false;
                        errors.files = "Only PDF is allowed !";
                        break;
                    }
                    if (item.size > MAX_FILE_SIZE) {
                        formIsValid = false;
                        errors.files = "Maximum allowed file size is 2MB !";
                        break;
                    }
                    if (contractDocument.length > 5) {
                        formIsValid = false;
                        errors.files = "Only 5 files are allowed !";
                        break;
                    }
                }
            }
        }
        else {
            for (const item of contractDocument) {
                if (!isValidFileType(item)) {
                    formIsValid = false;
                    errors.files = "Only PDF is allowed !";
                    break;
                }
                if (item.size > MAX_FILE_SIZE) {
                    formIsValid = false;
                    errors.files = "Maximum allowed file size is 2MB !";
                    break;
                }
                if (contractDocument.length + files.length > 5) {
                    formIsValid = false;
                    errors.files = "Only 5 files are allowed !";
                    break;
                }
            }
        }
        lineItems.forEach((item, index) => {
            const lineItemErrors = {};

            if (!item.ItemId) {
                formIsValid = false;
                lineItemErrors.ItemId = "Select Item";
            }
            if (!item.Rate) {
                formIsValid = false;
                lineItemErrors.Rate = "Enter Rate";
            }
            if (item.Rate && item.Rate == 0) {
                formIsValid = false;
                lineItemErrors.Rate = "Rate can't be zero";
            }
            if (!item.Quantity) {
                formIsValid = false;
                lineItemErrors.Quantity = "Enter Quantity";
            }
            if (item.Quantity && item.Quantity == 0) {
                formIsValid = false;
                lineItemErrors.Quantity = "Quantity can't be zero";
            }
            if (Object.keys(lineItemErrors).length > 0) {
                errors.lineItems[index] = lineItemErrors;
            }
            return lineItemErrors;
        });

        if (errors.lineItems.length === 0) {
            delete errors.lineItems;
        }

        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default EditContractValidation;
