import { useState } from 'react';

const EditFinanceInfoValidation = () => {
    const [errors, setErrors] = useState({});

    const validateForm = (paymentModeId, inputValues, bankDocument, bankDocumentObject) => {
        let errors = {};
        let formIsValid = true;

        for (const fieldName in inputValues) {
            if (inputValues.hasOwnProperty(fieldName)) {
                const { fieldValue } = inputValues[fieldName];

                if (!fieldValue.trim()) {
                    formIsValid = false;
                    errors[fieldName] = `Enter ${fieldName}`;
                } else {
                    switch (fieldName) {
                        case 'Bank Name':
                            if (!/^[a-zA-Z\s]+$/.test(fieldValue)) {
                                formIsValid = false;
                                errors[fieldName] = 'Only alphabetic keywords allowed';
                            }
                            break;
                        case 'Account Number':
                            if (fieldValue.length < 5 || fieldValue.length > 25) {
                                formIsValid = false;
                                errors[fieldName] = 'Account number should have 5-25 digits';
                            }
                            break;
                        case 'IFSC Code':
                            if (fieldValue.length !== 11) {
                                formIsValid = false;
                                errors[fieldName] = 'IFSC Code should have 11 digits';
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        }

        if (paymentModeId === 1) {
            if (!bankDocumentObject && bankDocument.length === 0) {
                formIsValid = false;
                errors.bankDocument = "Please upload a document !";
            }
            else if (!bankDocumentObject?.BankDocumentName && bankDocument.length === 0) {
                formIsValid = false;
                errors.bankDocument = "Please upload a document !";
            } else if (bankDocument.length > 0) {
                const areAllImages = bankDocument.every((file) => {
                    return file.type === 'application/pdf';
                });
                if (!areAllImages) {
                    formIsValid = false;
                    errors.bankDocument = "Only PDF is allowed !";
                } else {
                    const isValidSize = bankDocument.every((file) => {
                        return file.size <= 2 * 1024 * 1024;
                    });
                    if (!isValidSize) {
                        formIsValid = false;
                        errors.bankDocument = "Maximum allowed file size is 2MB !";
                    }
                }
            }
        }

        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default EditFinanceInfoValidation;
