import React from 'react'

const FilePreviewIcon = () => {
  return (
    <>
      <div className='me-2'>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 22" fill="none">
          <path d="M23.9982 0.75H1.62905C1.20529 0.75 0.79888 0.909917 0.499234 1.19457C0.199589 1.47922 0.03125 1.8653 0.03125 2.26786V20.4821C0.03125 20.8847 0.199589 21.2708 0.499234 21.5554C0.79888 21.8401 1.20529 22 1.62905 22H23.9982C24.422 22 24.8284 21.8401 25.1281 21.5554C25.4277 21.2708 25.596 20.8847 25.596 20.4821V2.26786C25.596 1.8653 25.4277 1.47922 25.1281 1.19457C24.8284 0.909917 24.422 0.75 23.9982 0.75ZM1.62905 20.4821V2.26786H23.9982V20.4821H1.62905Z" fill="white" />
          <path d="M5.56076 8.34068C6.03478 8.34068 6.49816 8.20715 6.8923 7.95697C7.28643 7.7068 7.59362 7.35121 7.77502 6.93518C7.95642 6.51916 8.00389 6.06137 7.91141 5.61972C7.81893 5.17806 7.59067 4.77238 7.25548 4.45397C6.9203 4.13555 6.49325 3.91871 6.02833 3.83086C5.56342 3.74301 5.08152 3.7881 4.64358 3.96042C4.20564 4.13274 3.83133 4.42457 3.56798 4.79898C3.30463 5.1734 3.16406 5.61359 3.16406 6.0639C3.16406 6.66774 3.41657 7.24685 3.86604 7.67383C4.31551 8.10081 4.92512 8.34068 5.56076 8.34068ZM5.56076 4.84961C5.81391 4.84811 6.06182 4.91804 6.27308 5.05056C6.48433 5.18307 6.64941 5.37218 6.74738 5.59393C6.84535 5.81567 6.87181 6.06006 6.8234 6.29611C6.77498 6.53215 6.65387 6.74923 6.47543 6.91981C6.29699 7.09039 6.06925 7.20679 5.82108 7.25426C5.57291 7.30174 5.31549 7.27814 5.08145 7.18646C4.84742 7.09478 4.64731 6.93915 4.5065 6.7393C4.36569 6.53945 4.29052 6.30438 4.29051 6.0639C4.29261 5.74447 4.42711 5.4387 4.66487 5.21283C4.90264 4.98696 5.22452 4.85919 5.56076 4.8572V4.84961Z" fill="white" />
          <path d="M16.6335 9.38085L12.3195 13.4791L9.12385 10.4433C8.97417 10.302 8.77169 10.2227 8.56063 10.2227C8.34957 10.2227 8.14709 10.302 7.99741 10.4433L3.16406 15.0956V17.2433L8.58859 12.0902L11.217 14.5492L8.2211 17.3951H10.4181L17.1688 10.9822L22.4016 15.9304V13.7902L17.76 9.38085C17.6103 9.2395 17.4078 9.16016 17.1967 9.16016C16.9857 9.16016 16.7832 9.2395 16.6335 9.38085Z" fill="white" />
        </svg>
      </div>
    </>
  )
}

export default FilePreviewIcon