import React, { useContext, useState } from 'react'
import { ModalContext } from '../../utils/Context';
import { postApi } from '../../utils/services';
import { REJECTED_BILL_API } from '../../utils/apiRoutes';
import toast from 'react-hot-toast';
import { useTransactionContext } from '../../utils/helpers/useTransaction';

const RejectToRequesterBill = ({ handlerBillList, data }) => {
  const [rejectReason, setRejectReason] = useState("");
  const modalContext = useContext(ModalContext);
  const user = JSON.parse(localStorage.getItem("userData"));
  const transactionList = useTransactionContext();
  const [error, setError] = useState("");
  const { closeModal } = modalContext;
  const [disable, setDisable] = useState(false)

  const handlerRejecTorequester = async () => {
    if (!rejectReason) {
      setError("Please provide a rejection reason !");
      return;
    }
    setDisable(true)
    let result = await postApi(REJECTED_BILL_API, {
      "billId": data.id,
      "transactionId": transactionList[1].id,
      "roleId": user.RoleId,
      "level": "",
      "rejectionReason": rejectReason
    })
    if (result.res.success) {
      toast.success(result.res.message)
      handlerBillList()
      closeModal();
    }
  }

  const handleChangeRejectReason = (e) => {
    setRejectReason(e.target.value);
    setError("");
  };

  return (
    <div className='cstm-frm-mdl'>
      <div class="modal-header">
        <h4 class="modal-title">Rejection Reason</h4>
      </div>
      
      <div class="modal-body">
        <div class="form-group mt-2">
          <label for="" className='form-label'>Are you sure you want to reject this Bill?</label>
          <textarea value={rejectReason} className="form-control reject-modal-text-area" placeholder="Enter the reason here..." id="floatingTextarea" rows={3} maxLength={100} onChange={handleChangeRejectReason}></textarea>
        </div>
        {error && <p className="validation-bill-err">{error}</p>}
      </div>



      <div class="modal-footer">
        <div className=" d-flex align-items-center mb-20">
          <div className="cstm-dngr-btn">
            <button type="button" className="btn" onClick={() => closeModal()}>Cancel</button>
          </div>
          <div className="cstm-reject-btn ms-2">
            <button type="submit" className="btn" onClick={handlerRejecTorequester} disabled={disable}>
              Reject
            </button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default RejectToRequesterBill