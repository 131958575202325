import React, { useContext } from 'react'
import Screenlogo from "../../../../assets/image/streamlogo.png";
import { FaArrowRight } from "react-icons/fa";
import pendingIcon from "../../../../assets/image/pending-gif.gif"
import { useNavigate } from 'react-router-dom';
import AuthenContext from '../../../../utils/helpers/AuthContext';
import useAuthenticationRedirect from '../../../../utils/helpers/useAuthenticationRedirect';

const Pending = () => {
  const navigate = useNavigate()
  const authCtx = useContext(AuthenContext);
  useAuthenticationRedirect(authCtx.isLoggedIn, navigate);

  const handleLogout = () => {
    authCtx.logout();
    navigate("/login");
  };

  return (
    <div className="page-inner-container">

      <div className="cstm-stepper bg-white card-edit-vendor-div">
        <div className="imagelogo">
          <img src={Screenlogo} alt="" />
        </div>

        <div>

          <div className="thank-you-box ">
            <div className=" thankyou pending-top-border">
              <div className="imagelogo-pending d-flex justify-content-center">
                <img src={pendingIcon} alt="" />
              </div>

              <h1 className="my-2">Thank You!</h1>
              <p className="mb-2 waitingmsg">
                Your all details are successfully submitted. Kindly wait for the
                approval.
              </p>

              <button className="fa-cstm-icon-back mb-4 pending-bg-btn" onClick={handleLogout}>
                <FaArrowRight className="faarrowbtn" />
                back to login
              </button>

            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Pending
