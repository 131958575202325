import React from 'react'

const TerminologyIcon =() => {
  return (
   <>
  
   <svg width="18" height="18" viewBox="0 0 18 20" fill="#fff" xmlns="http://www.w3.org/2000/svg">
<path d="M1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H16.4C16.5591 1 16.7117 1.06321 16.8243 1.17574C16.9368 1.28826 17 1.44087 17 1.6V14.714M3 15H17M3 19H17" stroke="#fff" stroke-linecap="round"/>
<path d="M6.50033 9.00342C6.28209 9.00374 6.06655 9.05169 5.86875 9.14391C5.67095 9.23614 5.49567 9.37041 5.35512 9.53737C5.21457 9.70433 5.11215 9.89995 5.05501 10.1106C4.99787 10.3212 4.98738 10.5418 5.02427 10.7569C5.06117 10.972 5.14456 11.1764 5.26863 11.356C5.39269 11.5355 5.55445 11.6858 5.7426 11.7964C5.93075 11.907 6.14077 11.9752 6.35799 11.9962C6.57522 12.0172 6.79441 11.9906 7.00029 11.9182" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.13247 9.80243C4.83324 9.65838 4.5746 9.44208 4.37988 9.17306C4.18516 8.90404 4.06049 8.59077 4.01712 8.26152C3.97376 7.93227 4.01306 7.5974 4.13149 7.28714C4.24992 6.97688 4.44374 6.70099 4.69546 6.48437M4.71046 6.44387C4.54648 6.19849 4.47461 5.903 4.50757 5.60971C4.54053 5.31643 4.67619 5.04427 4.89055 4.84141C5.10491 4.63855 5.38413 4.51807 5.67878 4.50131C5.97344 4.48455 6.26452 4.57259 6.5005 4.74983M6.6195 4.78233C6.53973 4.61285 6.49908 4.42761 6.50053 4.2403C6.50199 4.053 6.54552 3.86841 6.62792 3.70019C6.71031 3.53197 6.82945 3.38442 6.97654 3.26844C7.12363 3.15246 7.2949 3.07102 7.4777 3.03014C7.6605 2.98925 7.85014 2.98998 8.03263 3.03225C8.21511 3.07452 8.38575 3.15726 8.53196 3.27436C8.67816 3.39146 8.79617 3.53991 8.87728 3.70875C8.95839 3.8776 9.00052 4.06251 9.00055 4.24982V12C9.00055 12.2652 8.89519 12.5196 8.70765 12.7071C8.52011 12.8946 8.26575 13 8.00053 13C7.7353 13 7.48095 12.8946 7.29341 12.7071C7.10587 12.5196 7.00051 12.2652 7.00051 12M9.00055 5.49985C9.00055 5.89768 9.15859 6.27922 9.4399 6.56053C9.72121 6.84184 10.1027 6.99988 10.5006 6.99988M11.5006 8.99992C11.7189 9.00024 11.9344 9.0482 12.1322 9.14043C12.3301 9.23266 12.5054 9.36695 12.6459 9.53393C12.7865 9.7009 12.8889 9.89654 12.9461 10.1072C13.0032 10.3178 13.0137 10.5384 12.9768 10.7535C12.9399 10.9687 12.8565 11.1731 12.7324 11.3527C12.6083 11.5323 12.4466 11.6826 12.2584 11.7932C12.0702 11.9038 11.8602 11.972 11.6429 11.993C11.4257 12.014 11.2065 11.9874 11.0006 11.915" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.8686 9.80243C13.1678 9.65838 13.4264 9.44208 13.6212 9.17306C13.8159 8.90404 13.9405 8.59077 13.9839 8.26152C14.0273 7.93227 13.988 7.5974 13.8695 7.28714C13.7511 6.97688 13.5573 6.70099 13.3056 6.48437M13.2906 6.44387C13.4546 6.19849 13.5264 5.903 13.4935 5.60971C13.4605 5.31643 13.3248 5.04427 13.1105 4.84141C12.8961 4.63855 12.6169 4.51807 12.3223 4.50131C12.0276 4.48455 11.7365 4.57259 11.5005 4.74983M9.00049 4.24982C9.00052 4.06251 9.04265 3.8776 9.12375 3.70875C9.20486 3.53991 9.32288 3.39146 9.46908 3.27436C9.61528 3.15726 9.78593 3.07452 9.96841 3.03225C10.1509 2.98998 10.3405 2.98925 10.5233 3.03014C10.7061 3.07102 10.8774 3.15246 11.0245 3.26844C11.1716 3.38442 11.2907 3.53197 11.3731 3.70019C11.4555 3.86841 11.499 4.053 11.5005 4.2403C11.502 4.42761 11.4613 4.61285 11.3815 4.78233M11.0005 12C11.0005 12.2652 10.8952 12.5196 10.7076 12.7071C10.5201 12.8946 10.2657 13 10.0005 13C9.73529 13 9.48093 12.8946 9.29339 12.7071C9.10585 12.5196 9.00049 12.2652 9.00049 12" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 19C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17C1 16.4696 1.21071 15.9609 1.58579 15.5858C1.96086 15.2107 2.46957 15 3 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 17H17" stroke="#fff" stroke-linecap="round"/>
</svg>

   </>
  )
}

export default TerminologyIcon