import React from 'react'

const ApproveButton = () => {
  return (
    <span>
      <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30.4469" height="30" rx="6" fill="#1AB859" />
        <path d="M23.74 14.9591L21.8834 12.8438L22.1421 10.0436L19.3952 9.41969L17.9571 7L15.37 8.11093L12.7829 7L11.3448 9.41969L8.59791 10.036L8.85662 12.8362L7 14.9591L8.85662 17.0744L8.59791 19.8822L11.3448 20.5061L12.7829 22.9258L15.37 21.8073L17.9571 22.9182L19.3952 20.4985L22.1421 19.8746L21.8834 17.0744L23.74 14.9591ZM13.8482 18.7637L10.8045 15.72L11.8774 14.6471L13.8482 16.6103L18.8626 11.5959L19.9355 12.6764L13.8482 18.7637Z" fill="white" />
      </svg>
    </span>
  )
}

export default ApproveButton