import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

function DateRangeCalender({ setSelectedDateRange, startDate, endDate, handlerContractRedicalChart, handlerBillRedicalChart }) {

  const handleApply = (event, picker) => {
    setSelectedDateRange({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
    handlerContractRedicalChart(picker.startDate, picker.endDate)
    handlerBillRedicalChart(picker.startDate, picker.endDate)
  };

  return (
    <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate }} onApply={handleApply}>
      <input type="text" className="form-control" />
    </DateRangePicker>
  );
}

export default DateRangeCalender;
