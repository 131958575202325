import ReactApexChart from "react-apexcharts";

function LineChart({categories = [], data = [], title}) {
  const chartData = {
    series: data,
    options: {
      chart: {
        toolbar: {
          show: false
        },
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "K";
        }
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: title,
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: categories
      },
      yaxis: [{
        title: {
          text: 'Amount',
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val + "K";
          }
        }
      }],
      
    }
  }
  return (
    <div id="LineChart">
      <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350}></ReactApexChart>
    </div>
  )
}

export default LineChart


