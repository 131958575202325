import React, { useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import gifIcon from "../../../../assets/image/Success.gif";
import AuthenContext from '../../../../utils/helpers/AuthContext';

const Thankyou = () => {
    const navigate = useNavigate()
    const authCtx = useContext(AuthenContext);

    const handleLogout = () => {
        authCtx.logout();
        navigate('/login')
    }

    useEffect(() => {
        localStorage.clear();
    }, [])

    return (
        <div className="thank-you-box">
            <div className="thankyou">
                <div className="gif-sucess d-flex justify-content-center">
                    <img src={gifIcon} alt="" />
                </div>

                <h1 className="my-2">Thank You!</h1>
                <p className="mb-2 waitingmsg">
                    Your all details are successfully submitted. Kindly wait for the
                    approval.
                </p>

                <button className="fa-cstm-icon-back mb-4" onClick={handleLogout}>
                    <FaArrowRight className="faarrowbtn" />
                    back to login
                </button>

            </div>
        </div>
    )
}

export default Thankyou