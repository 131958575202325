import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import eyeicon from "../../../assets/image/eye.png";
import { BASE_IMAGE_URL } from "../../../utils/services";
import FileIcon from "../../../assets/image/FileIcon";
import DownloadSvg from "../../../assets/image/DownloadSvg";
import { Link } from "react-router-dom";
import PreviewPdf from "../previewPdf/PreviewFile";

const BankAccordion = ({ viewData, viewHistory }) => {

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
  };

  const handlePreview = (url) => {
    const preview = <PreviewPdf url={url} />;
    const encodedText = btoa(url);
  };

  return (
    <>
      <div className="bank-accordian-box">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h3>Finance Details </h3>
            </Accordion.Header>
            <div className="">
              <Accordion.Body className="p-0">
                <div className="finance-img-with-fileds-div mt-0">
                  <div className="cstm-fields-finance-view">
                    <div className="row">
                      <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-md-4 mb-20">
                        <label htmlFor="ifsccode" className="form-label">
                          Payment Mode
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="ifsccode"
                          placeholder="Enter ifsc code"
                          value={viewData.PaymentMode}
                          disabled
                        />
                      </div>
                      {viewData.BankInfo &&
                        viewData.BankInfo.map((info, index) => (
                          <div key={index} className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 mb-20">
                            <label htmlFor={info.FieldName} className="form-label">
                              {info.FieldName}
                            </label>
                            <input
                              type="text"
                              className={viewHistory?.BankInfo && viewHistory?.BankInfo[index]?.FieldValue === "changed" ? "form-control updated-bankInfo-value" : "form-control"}
                              name={info.FieldName}
                              placeholder={`Enter ${info.FieldName}`}
                              value={info.FieldValue}
                              disabled
                            />
                          </div>
                        ))}
                      {viewData.PaymentModeId !== 2 &&
                        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-md-4  d-flex align-items-center pt-2">
                          <span className="cstm-view-bankinf0-btn">
                            <button className="btn" onClick={handleShow}>
                              View Document
                            </button>
                          </span>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
        </Accordion>
      </div>

      <>
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          animation={false}
          className="modal-wrap"
          backdrop="static"
          keyboard={false}
        >
          <div className="file-preview-modal-title">
            <div className="svg-size me-2">
              <FileIcon />
            </div>
            <h3 className="m-0">File Details</h3>
          </div>
          <div className="file-preview-modal-body">
            <>
              <div className="files-card ">
                <div className="d-flex align-items-center">
                  <p>{1}.</p>
                  <p className="ms-3 me-3 break-all">{viewData.CancelChequeName}</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="me-2">
                    <Link
                      to={`${BASE_IMAGE_URL}/${viewData.CancelChequePath}`}
                      download
                      target="_blank"
                    >
                      <DownloadSvg />
                    </Link>
                  </span>
                  <span
                    onClick={() =>
                      handlePreview(`${BASE_IMAGE_URL}/${viewData.CancelChequePath}`)
                    }
                  >
                    <Link
                      to={`/preview-pdf/${btoa(viewData.CancelChequePath)}`}
                      target="_blank"
                    >
                      <span className="eye-icon">
                        <img src={eyeicon} alt="" />
                      </span>
                    </Link>
                  </span>
                </div>
              </div>
            </>
          </div>
          <hr className="m-0" />
          <div className=" d-flex justify-content-end  file-preview-modal-footer">
            <div className="cstm-dngr-btn ">
              <button type="button" onClick={handleClose} className="btn">
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </>

    </>
  );
};

export default BankAccordion;
