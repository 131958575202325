import React, { useState, useEffect, useContext } from "react";
import { Timeline, TimelineEvent } from "@mailtop/horizontal-timeline";
import { FaUser } from "react-icons/fa";
import {
  ADMIN_EXECUTIVE_ID,
  ADMIN_MANAGER_ID,
  FINANCE_ID,
  VENDORID,
  VP_ID,
} from "../../../utils/constant";
import { getApi, postApi } from "../../../utils/services";
import {
  APPROVAL_ADD,
  APPROVAL_LIST,
  APPROVAL_UPDATE,
  ROLE_LIST,
} from "../../../utils/apiRoutes";
import Modal from "react-bootstrap/Modal";
import cross from "../../../assets/image/cross.png";
import { toast } from "react-hot-toast";
import { ModalContext } from "../../../utils/Context";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import { Helmet } from "react-helmet";

const ApprovalFlow = () => {
  const [toggle, setToggle] = useState(false);
  const transactionList = useTransactionContext();

  const [roles, setRoles] = useState([]);

  const [nextApprovallistzero, setNextApprovalListzero] = useState([]);

  const [nextApprovallistone, setNextApprovalListone] = useState([]);

  const [nextApprovallistTwo, setNextApprovalListTwo] = useState([]);

  const [nextApprovallistThree, setNextApprovalListThree] = useState([]);

  const [nextApprovallistFour, setNextApprovalListFour] = useState([]);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const modalContext = useContext(ModalContext);

  const { handleModalData } = modalContext;

  // aprval flow //

  const [approvallist, setApprovalList] = useState([]);

  const [record_count, setCount] = useState();
  const [pendingcount, setPendingCount] = useState();

  // store aprove data //

  const [zeroLevel, setZeroLevel] = useState();

  const [oneLevel, setOneLevel] = useState();

  const [twoLevel, settwoLevel] = useState();

  const [threeLevel, setThreeLevel] = useState();

  const [fourLevel, setfourLevel] = useState();

  // transation method //

  const [transactionId, setTransactionId] = useState();

  const handleRoleApi = async () => {
    try {
      const result = await getApi(ROLE_LIST);
      const filteredRoles = result.res.role_list.filter(
        (role) => role.id !== ADMIN_EXECUTIVE_ID && role.id !== VENDORID
      );

      setRoles(filteredRoles);
    } catch (error) {
      console.error("Error fetching roles:", error);
      // Handle errors if necessary
    }
  };

  useEffect(() => {
    handleRoleApi();
  }, []);

  const handleTransactionId = async (e) => {
    setTransactionId(e.target.value);

    try {
      const result = await postApi(APPROVAL_LIST, {
        transactionId: +e.target.value,
      });
      setApprovalList(result.res.approver_list);
      setPendingCount(result.res.pendingCount);
      setCount(result.res.record_count);
    } catch (error) { }
  };
  useEffect(() => {
    const handleApprovalList = async () => {
      const result = await postApi(APPROVAL_LIST, {
        transactionId: 1,
      });

      setApprovalList(result.res.lines);
    };
    handleApprovalList();
  }, []);

  const handleSelect = async (e) => {
    setZeroLevel(e.target.value);

    const filterRole = roles.filter((item) => item.id != e.target.value);

    setNextApprovalListzero(filterRole);

    setNextApprovalListone([]);

    setNextApprovalListTwo([]);

    setNextApprovalListThree([]);

    setNextApprovalListFour([]);
  };

  const handleSelectOne = async (e) => {
    setOneLevel(e.target.value);

    try {
      const filterRole = nextApprovallistzero.filter(
        (item) => item.id != e.target.value
      );

      setNextApprovalListone(filterRole);

      setNextApprovalListTwo([]);

      setNextApprovalListThree([]);

      setNextApprovalListFour([]);

      settwoLevel();

      setThreeLevel();

      setfourLevel();

    } catch (error) {

    }
  };

  const handleSelectTwo = async (e) => {
    settwoLevel(e.target.value);

    try {
      const filterRole = nextApprovallistone.filter(
        (item) => item.id != e.target.value
      );
      setNextApprovalListTwo(filterRole);

      setNextApprovalListThree([]);

      setNextApprovalListFour([]);

      setThreeLevel();

      setfourLevel();
    } catch (error) {
    }
  };

  const handleSelectThree = async (e) => {
    setThreeLevel(e.target.value);

    setNextApprovalListFour([]);

    setfourLevel();

    try {
      const filterRole = nextApprovallistTwo.filter(
        (item) => item.id != e.target.value
      );
      setNextApprovalListThree(filterRole);
    } catch (error) {

    }
  };

  const handleSelectFour = async (e) => {
    setfourLevel(e.target.value);

    try {
      const filterRole = nextApprovallistThree.filter(
        (item) => item.id != e.target.value
      );
      setNextApprovalListFour(filterRole);
    } catch (error) {

    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      let data2 = [zeroLevel, oneLevel, twoLevel, threeLevel, fourLevel];
      let result = data2.filter((item) => item !== undefined);
      let result2 = result.map((item, index) => index);

      if (result.length !== 0 && result2.length !== 0) {
        if (record_count <= 0) {
          // Make an API call to add a new record
          const response = await postApi(APPROVAL_ADD, {
            recordId: +transactionId,
            roleId: result.map((result) => +result),
            level: result2,
          });

          if (response.res.success) {
            toast.success(response.res.message);
          } else {
            toast.error(response.res.message);
          }
        } else if (pendingcount <= 0) {
          // Record exists, perform update
          const response = await postApi(APPROVAL_UPDATE, {
            recordId: +transactionId,
            roleId: result.map((result) => +result),
            level: result2,
          });

          if (response.res.success) {
            toast.success(response.res.message);
          } else {
            toast.error(response.res.message);
          }
        } else {
          handleShow();
        }
      } else {
        toast.error("Please select at least one role for approval");
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred");
      setLoading(false);
    }
  };

  const roleColors = {
    [ADMIN_MANAGER_ID]: "#87a2c7",
    [VP_ID]: "#9c2919",
    [FINANCE_ID]: "#ffb171",
  };

  const getColorForRole = (roleId) => {
    if (roleColors.hasOwnProperty(roleId)) {
      return roleColors[roleId];
    } else {
      return "#0040a1";
    }
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Approval Flow</title>
      </Helmet>

      <div className="container-section">
        <div className="section-header justify-content-between">
          <h3>Approval Flow</h3>
          <button
            type="button"
            className="btn btn-sm section-hdr-btn"
            onClick={() => setToggle(!toggle)}
          >
            {toggle ? "Close" : "Edit"}
          </button>
        </div>

        <div className="form-style">
          <>
            <div className="mt-4 text-center">
              <Timeline minEvents={5} placeholder>
                {approvallist?.map((item) => (
                  <TimelineEvent
                    icon={FaUser}
                    color={getColorForRole(item?.roleId)}
                    title={item?.RoleName}
                  />
                ))}
              </Timeline>
            </div>

            <div className="form-group">
              <div className="row mb-4 flow-level">
                <div className="col-md-5">
                  <label className="form-label">Transaction</label>

                  <select
                    class="form-select"
                    aria-label="Default select example"
                    value={transactionId}
                    onChange={(e) => handleTransactionId(e)}
                  >
                    <option disabled> Select Transaction</option>
                    {transactionList?.map((items) => (
                      <option value={items?.id}>{items?.Name}</option>
                    ))}
                  </select>
                </div>
              </div>

              {toggle && (
                <>
                  <div className="row flow-level">
                    <div className="col-md-12 row mb-4 m-0 p-0">
                      <div className="col-md-5 ">
                        <label className="form-label">Level 0</label>

                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={zeroLevel}
                          onChange={(e) => handleSelect(e)}
                        >
                          <option disabled>select role</option>

                          {roles?.map((items) => (
                            <option value={items?.id}>{items?.RoleName}</option>
                          ))}
                        </select>
                      </div>

                      {nextApprovallistzero.length > 0 && (
                        <>
                          <div className="col-md-2 my-2 d-flex flex-column justify-content-center align-items-center rotate-arrow">
                            <span className="mdi mdi-arrow-right-bold"></span>
                          </div>

                          <div className="col-md-5">
                            <div>
                              <label className="form-label">Level 1</label>

                              <select
                                class="form-select"
                                aria-label="Default select example"
                                value={oneLevel}
                                onChange={(e) => handleSelectOne(e)}
                              >
                                <option disabled>select role</option>

                                {nextApprovallistzero?.map((item) => (
                                  <option value={item?.id}>
                                    {item?.RoleName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="col-md-12 row m-0 mb-4 p-0">
                      {nextApprovallistone.length > 0 && (
                        <>
                          <div className="col-md-5">
                            <label className="form-label">Level 2</label>

                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onClick={(e) => handleSelectTwo(e)}
                            >
                              <option disabled>select role</option>

                              {nextApprovallistone?.map((item) => (
                                <option value={item?.id}>
                                  {item?.RoleName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </>
                      )}

                      {nextApprovallistTwo.length > 0 && (
                        <>
                          <div className="col-md-2 my-2 d-flex flex-column justify-content-center align-items-center rotate-arrow">
                            <span className="mdi mdi-arrow-right-bold"></span>
                          </div>

                          <div className="col-md-5">
                            <label className="form-label">Level 3</label>

                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onClick={(e) => handleSelectThree(e)}
                            >
                              {nextApprovallistTwo?.map((item) => (
                                <option value={item?.id}>
                                  {item?.RoleName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="col-md-12 row mb-4 m-0 p-0">
                      {nextApprovallistThree.length > 0 && (
                        <div className="col-md-5 ">
                          <label className="form-label">Level 4</label>

                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            {nextApprovallistThree?.map((item) => (
                              <option value={item?.id}>{item?.RoleName}</option>
                            ))}
                          </select>
                        </div>
                      )}

                      {nextApprovallistFour.length > 0 && (
                        <div className="col-md-5">
                          <label className="form-label">Level 5</label>

                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onClick={(e) => handleSelectFour(e)}
                          >
                            {nextApprovallistFour?.map((item) => (
                              <option value={item?.id}>{item?.RoleName}</option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className=" d-flex align-items-center justify-content-end mb-20 ">
                    <span className="cstm-success-btn">
                      <button
                        type="submit"
                        className="btn"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </span>
                  </div>
                </>
              )}
            </div>
          </>
        </div>
      </div>
      
      <>
        <>
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            animation={false}
            className="modal-wrap custom-modal-backdrop"
            backdrop="static"
            keyboard={false}
          >
            <div className="file-preview-modal-body">
              <div className="">
                <span className="cross cursor-pointer" onClick={handleClose}>
                  <img src={cross} alt="" />
                </span>
                <h3 className="text-center">
                  There are {pendingcount} pending items which need to
                  Approved/Rejected
                </h3>
              </div>
            </div>
          </Modal>
        </>
      </>
    </>
  );
};

export default ApprovalFlow;
