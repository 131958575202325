import ReactApexChart from "react-apexcharts";
function PieChart({ title, data = [], label = [] }) {
    const chartData = {
        series: data,
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            title: {
                show: true,
                text: title
            },
            labels: label,
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    }
    return (
        <div id="PieChart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="pie" height={350}></ReactApexChart>
        </div>
    )
}
export default PieChart


