import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { Pagination } from "react-bootstrap";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";

const Bill = ({ columns, data }) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
  } = useTable(
    {
      columns,
      data: data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [{ id: columns[0].accessor, desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const TotalGrandAmount = page.reduce((total, obj) => total + obj.original.Total, 0);
  const TotaltdsAmount = page.reduce((total, obj) => total + obj.original.TDSAmount, 0);
  const currencySymbol = data.find(item => item.SubsidiaryId === 2)?.CurrencySymbol;

  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <input
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search"
            className="form-control mb-3"
          />
        </div>
      </div>

      <div className="table-responsive tbl-body-shadow">
        <table className="w-100 table-theme-1" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div>
                      {column.render("Header")}
                      {columnIndex !== columns.length - 1 &&
                        columnIndex !== columns.length - 2 &&
                        (column.isSorted ? (
                          column.isSortedDesc ? (
                            <span> 🔽</span>
                          ) : (
                            <span> 🔼</span>
                          )
                        ) : (
                          <span> ⬆️⬇️</span>
                        ))}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{ padding: "10px", border: "1px solid black" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {page.length === 0 && (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: "center" }}>
                  No data found.
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            {page.length !== 0 && (
              <tr className="total-show" style={{ border: "none" }}>
                <td className="text-end text-white table-footer"></td>
                <td className="text-end text-white table-footer"></td>
                <td className="text-left text-white" style={{ fontWeight: "bold" }}>
                  Total
                </td>
                <td className="text-end text-white" style={{ fontWeight: "bold", paddingRight: "33px" }}>
                  {currencySymbol} {TotalGrandAmount && TotalGrandAmount?.toFixed(2)}
                </td>
                <td className="text-end text-white" style={{ fontWeight: "bold", paddingRight: "33px" }}>
                  {currencySymbol} {TotaltdsAmount && TotaltdsAmount?.toFixed(2)}
                </td>
                <td className="text-end text-white" style={{ fontWeight: "bold" }}></td>
                <td className="text-end text-white" style={{ fontWeight: "bold" }}></td>
              </tr>
            )}
          </tfoot>
        </table>
      </div>

      {/* Pagination */}
      <div className="d-flex justify-content-end mt-3">
        <Pagination>
          <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
          {pageOptions.map((page, index) => (
            <Pagination.Item
              key={index}
              active={index === pageIndex}
              onClick={() => gotoPage(index)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
        </Pagination>
      </div>
    </div>
  );
};

export default Bill;
