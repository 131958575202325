import { useEffect } from 'react';

const useAuthenticationRedirect = (isLoggedIn, navigate, redirectPath = '/login') => {
  useEffect(() => {
    if (!isLoggedIn) {
      navigate(redirectPath);
    }
  }, [isLoggedIn, navigate, redirectPath]);
};

export default useAuthenticationRedirect;