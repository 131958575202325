import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useStatusRenderer } from "../useStatus";
import eyeIcon from "../../../assets/image/eye.png";
import payIcon from "../../../assets/image/payment.png";
import { ModalContext } from "../../Context";
import PaymentModal from "../../../components/modal/PaymentModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PAYMENT_PENDING } from "../../constant";

// Custom hook for defining FeedsColumns
export const useFeedPayment = (handlerPayList) => {
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;

  const handlerPayment = (item) => {
    let pay = <PaymentModal item={item} handlerPayList={handlerPayList} />;
    handleModalData(pay, "md");
  };

  const columns = useMemo(
    () => [
      {
        Header: "Due Date",
        accessor: "DueDate",
        Cell: ({ row }) => {
          return (
            <>
              {" "}
              {row.original.DueDate
                ? moment(row.original.DueDate).format("D/MM/YYYY")?.slice(0, 10)
                : "N/A"}{" "}
            </>
          );
        },
      },
      {
        Header: "Bill Number",
        accessor: "DocumentNo",
        Cell: ({ row }) => {
          return (
            <>
              <div className="cursor-pointer bill-no">
                {
                  row.original?.IndividualBill && <Link className="" to={`/view-individual-bill/${row.original.InvoiceId}`} target="_blank">{row.original.DocumentNo}</Link>
                }
                {
                  !row.original?.IndividualBill && <Link to={`/view-bill/${row.original.InvoiceId}`} target="_blank">{row.original.DocumentNo}</Link>
                }
              </div>
            </>
          );
        },
      },
      {
        Header: "Vendor Name",
        accessor: "VendorName",
      },
      {
        Header: "Grand Total",
        headerStyle: { textAlign: "right" },
        accessor: "GrandTotal",
        Cell: ({ row }) => {
          return (
            <>
              <div className="text-end pe-4">
                {row?.original?.CurrencySymbol}{" "}
                {row?.original?.GrandTotal?.toFixed(2)}
              </div>
            </>
          );
        },
      },
      {
        Header: "TDS Amount",
        accessor: "TDSAmount",
        Cell: ({ row }) => {
          return (
            <>
              <div className="text-end pe-4">
                {row?.original?.CurrencySymbol}
                {row?.original?.TDSAmount?.toFixed(2)}
              </div>
            </>
          );
        },
      },
      {
        Header: "Payment Amount",
        accessor: "Total",
        Cell: ({ row }) => {
          return (
            <>
              <div className="text-end pe-4">
                {row?.original?.CurrencySymbol}{" "}
                {row?.original?.Total?.toFixed(2)}
              </div>
            </>
          );
        },
      },
      {
        Header: <div className="ps-5">Status</div>,
        accessor: "StatusName",
        Cell: ({ row }) => useStatusRenderer(row),
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: (item) => {
          return (
            <>
              <div className="d-flex align-item-center">
                {item.PaymentTransactionId && (
                  <div className="cursor-pointer cstm-icon-gap">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          className="custom-tooltip cstm-tooltip"
                          id="tooltips1"
                        >
                          View
                        </Tooltip>
                      }
                    >
                      <img src={eyeIcon} alt="" />
                    </OverlayTrigger>
                  </div>
                )}

                {item.Status === PAYMENT_PENDING && (
                  <div className="cursor-pointer cstm-icon-gap">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          className="custom-tooltip cstm-tooltip"
                          id="tooltips2"
                        >
                          Pay
                        </Tooltip>
                      }
                    >
                      <img
                        src={payIcon}
                        alt=""
                        onClick={() => handlerPayment(item)}
                      />
                    </OverlayTrigger>
                  </div>
                )}
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  return columns;
};
