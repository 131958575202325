import React from "react";

const ThumbIcon = () => {
    return (
        <div className="thumbs_svg">
            <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.77381e-06 19.9449L1.36165e-06 10.5158C0.0493215 9.51789 0.850231 8.79734 1.7302 8.78535L5.91761 8.78535C7.11452 7.85825 7.96279 6.57157 9.11632 5.63986C9.52344 5.31235 9.83846 4.9482 10.0614 4.54737C10.7862 3.11133 10.347 1.43943 11.3772 0.294697C13.4474 -0.601272 15.9274 0.652138 16.626 2.619C17.1751 4.22522 16.8071 5.82703 16.1244 7.24563C18.5961 7.24149 21.0679 7.26169 23.5396 7.27494C25.5518 7.42647 26.9866 8.79291 27 10.7357C26.9945 12.6936 25.321 14.1174 23.5396 14.1525L20.6317 14.1525C20.5347 14.7293 20.3457 15.2523 20.0646 15.7216C20.2275 16.9276 19.8548 18.11 19.1341 18.9624C18.7387 25.9236 9.69664 21.7936 6.31021 21.69L1.73023 21.69C0.733885 21.6424 0.0119037 20.837 1.77381e-06 19.9449ZM6.2375 19.9449C8.94706 20.0037 18.2054 24.1354 17.3166 18.2146C18.2964 17.6819 18.6734 16.2565 18.0727 15.355C18.9854 14.4472 19.2454 13.3066 18.3635 12.4075C19.2359 12.4075 20.0986 12.4026 20.9515 12.3928C21.8045 12.3831 22.6672 12.3782 23.5396 12.3782C24.5379 12.357 25.2298 11.6182 25.2407 10.7357C25.2179 9.69242 24.4326 9.03067 23.5396 9.02002C20.1559 9.01984 16.8204 8.99107 13.6818 8.9907C13.9642 6.70408 16.6691 3.59916 13.9071 1.79782C13.465 1.57879 13.1114 1.48581 12.6495 1.5705C12.5719 1.60963 12.5283 1.64871 12.5186 1.68783C12.5089 1.72693 12.4944 1.79047 12.475 1.87845C12.1815 3.69095 11.9075 5.50322 10.5122 6.70303C8.97626 7.89086 8.07062 9.76091 6.31026 10.5158L6.23756 10.5158L6.2375 19.9449Z" fill="#58BAE4" />
            </svg></div>
    )
}
export default ThumbIcon;