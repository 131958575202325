import React, { useState } from "react";
import ReconcileTabBar from "./ReconcileTabBar"
import { Helmet } from "react-helmet";
import ContractReconcileReport from "./ContractReconcileReport";
import BillReconcileReport from "./BillReconcileReport";
import VendorReconcileReport from "./VendorReconcileReport";
import PaymentReconcileReport from "./PaymentReconcileReport";
import StandaloneBillReconcileReport from "./StandaloneBillReconcileReport";

const ReconcileReport = () => {

    const tabs = [
        { id: 'Vendor', label: 'Vendor' },
        { id: 'Purchase Contract', label: 'Purchase Contract' },
        { id: 'Bill', label: 'Bill' },
        { id: 'Standalone Bill', label: 'Standalone Bill' },
        { id: 'Payment', label: 'Payment' },
    ];

    const [currentTab, setCurrentTab] = useState(tabs[0].id);

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
    };

    return (
        <>
            <Helmet>
                <title>Vendor Portal || Report</title>
            </Helmet>

            <div className="container-section">

                <ReconcileTabBar tabs={tabs} defaultTab={currentTab} onTabChange={handleTabChange} />
                {currentTab === 'Vendor' && <VendorReconcileReport />}
                {currentTab === 'Purchase Contract' && <ContractReconcileReport />}
                {currentTab === 'Bill' && <BillReconcileReport />}
                {currentTab === 'Standalone Bill' && <StandaloneBillReconcileReport />}
                {currentTab === 'Payment' && <PaymentReconcileReport />}

            </div>
        </>
    );
};

export default ReconcileReport;
