import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import AuthenContext from "../../utils/helpers/AuthContext";
import icon from "../../assets/image/Avatar.png";

const DropDown = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const authCtx = useContext(AuthenContext);

  const handlelogout = () => {
    authCtx.logout();
    navigate("/login");
  };

  const handleChangePassword = () => {
    navigate("/change-password");
  };

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          variant=""
          id="dropdown-basic"
          className="dropdown-btn"
        >
          <i className="mdi mdi-power"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="user-info">
            <img src={icon} alt="" className="avatar-img " />
            <div className="cstm-user-name-paragraph">
              <p className="user-name-email-div">
                {user.Name || user.LegalName || user.FirstName}
              </p>
              <p className="user-name-div" >
                {user.Email}
              </p>
            </div>
          </div>
          <Dropdown.Item onClick={handleChangePassword}>
            Change Password
          </Dropdown.Item>
          <Dropdown.Item onClick={handlelogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropDown;