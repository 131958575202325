import React, { useMemo, useState } from "react";
import edit from "../../../assets/image/edit2.png"
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Custom hook for defining FeedsColumns

export const useFeedColumnTerminology = () => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const handleEdit = (item, e) => {
        e.preventDefault();
        setSelectedItem(item);
        setShowEditModal(true);
    };

    const columns = useMemo(
        () => [
            {
                Header: "#",
                accessor: "s no",
                Cell: ({ row }) => row.index + 1,
            },
            {
                Header: "Role Name",
                accessor: "RoleName",
                Cell: ({ row }) => (
                    <div style={{ whiteSpace: "nowrap" }}>{row?.original?.RoleName}</div>
                ),
            },
            {
                Header: "Action",
                Cell: ({ row }) => (
                    <>
                        <div className="cursor-pointer d-flex align-items-center justity-content-center">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips1">
                                        Edit
                                    </Tooltip>
                                }
                            >
                                <div
                                    className="ms-1 btn-vendors"
                                    onClick={(e) => handleEdit(row?.original, e)}
                                >
                                    <img src={edit} alt="" />
                                </div>
                            </OverlayTrigger>
                        </div>
                    </>
                ),
            },
        ],
        []
    );

    return { columns, showEditModal, setShowEditModal, selectedItem, handleEdit };
};
