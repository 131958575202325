import React from 'react'
 
const LockIcon = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M14.668 14.6654H13.3346V13.332H14.668V14.6654ZM10.668 14.6654H12.0013V13.332H10.668V14.6654ZM17.3346 14.6654H16.0013V13.332H17.3346V14.6654Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 8V4.66667C4 4.05383 4.12071 3.447 4.35523 2.88081C4.58975 2.31462 4.9335 1.80018 5.36684 1.36684C5.80018 0.933495 6.31462 0.589751 6.88081 0.355229C7.447 0.120707 8.05383 0 8.66667 0C9.2795 0 9.88634 0.120707 10.4525 0.355229C11.0187 0.589751 11.5332 0.933495 11.9665 1.36684C12.3998 1.80018 12.7436 2.31462 12.9781 2.88081C13.2126 3.447 13.3333 4.05383 13.3333 4.66667V8H15.3333C15.8638 8 16.3725 8.21071 16.7475 8.58579C17.1226 8.96086 17.3333 9.46957 17.3333 10V10.7333C18.0869 10.8863 18.7643 11.2952 19.2509 11.8905C19.7375 12.4858 20.0033 13.2311 20.0033 14C20.0033 14.7689 19.7375 15.5142 19.2509 16.1095C18.7643 16.7048 18.0869 17.1137 17.3333 17.2667V18C17.3333 18.5304 17.1226 19.0391 16.7475 19.4142C16.3725 19.7893 15.8638 20 15.3333 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18L0 10C0 9.46957 0.210714 8.96086 0.585786 8.58579C0.960859 8.21071 1.46957 8 2 8H4ZM5.33333 4.66667C5.33333 3.78261 5.68452 2.93477 6.30964 2.30964C6.93477 1.68452 7.78261 1.33333 8.66667 1.33333C9.55072 1.33333 10.3986 1.68452 11.0237 2.30964C11.6488 2.93477 12 3.78261 12 4.66667V8H5.33333V4.66667ZM11.3333 12C10.8029 12 10.2942 12.2107 9.91912 12.5858C9.54405 12.9609 9.33333 13.4696 9.33333 14C9.33333 14.5304 9.54405 15.0391 9.91912 15.4142C10.2942 15.7893 10.8029 16 11.3333 16H16.6667C17.1971 16 17.7058 15.7893 18.0809 15.4142C18.456 15.0391 18.6667 14.5304 18.6667 14C18.6667 13.4696 18.456 12.9609 18.0809 12.5858C17.7058 12.2107 17.1971 12 16.6667 12H11.3333Z" fill="white"/>
    </svg>
    </div>
  )
}
 
export default LockIcon