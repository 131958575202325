import React, { useEffect, useState } from 'react'
import { getApi } from '../../../utils/services';
import { BILLS_API, CONTRACT_LIST, VENDOR, CONTRACT_STATUS, BILL_STATUS } from '../../../utils/apiRoutes';
import { useFeedsColumnsVendor } from '../../../utils/helpers/tableHelpers/useFeedsColumnsVendor';
import { useFeedsColumnContracts } from '../../../utils/helpers/tableHelpers/useFeedColumnContracts';
import { useFeedsColumnBills } from '../../../utils/helpers/tableHelpers/useFeedColumnBills';
import DashboardCard from '../../common/DashboardCard';
import { VENDOR_MANAGER_PENDING, VENDOR_REJECT } from '../../../utils/constant';
import { Helmet } from "react-helmet";
import RadialCircleChart from "../../charts/radialchart/RadialCircleChart"
import moment from 'moment';
import DateRangeCalender from "../../Calender/DateRangeCalender";
import { mdiCalendar } from '@mdi/js';
import Icon from '@mdi/react';
import Loader from '../../common/Loader';
import { useFeedsColumnIndividualBills } from '../../../utils/helpers/tableHelpers/useFeedColumnIndividualBill';

const AdminManagerDashboard = () => {
  const [vendors, setVendor] = useState([]);
  const [purchaseContract, setPurchaseContarct] = useState([]);
  const [bills, setBills] = useState([]);
  const [individualBill, setIndividualBills] = useState([]);
  const [contractRadialChartData, setContractRadialChartData] = useState({
    series: [],
    labels: [],
    total: 0,
  });
  const [billRadialChartData, setBillRadialChartData] = useState({
    series: [],
    labels: [],
    total: 0,
  });
  const [loader, setLoader] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment().subtract(2, 'months'),
    endDate: moment(),
  });

  const handleVendorList = async () => {
    try {
      setLoader(true)
      const result = await getApi(VENDOR);
      const vendors = result.res.vendor_list.filter((item) => item.Status === VENDOR_MANAGER_PENDING);
      setLoader(false)
      setVendor(vendors)
    } catch (error) {
      console.log(error);
    }
  };

  const handlerPurchaseContractList = async () => {
    try {
      setLoader(true)
      let result = await getApi(CONTRACT_LIST);
      result = result.res.contract_list.filter((item) => item.Status === VENDOR_MANAGER_PENDING || item.Status === VENDOR_REJECT);
      setLoader(false)
      setPurchaseContarct(result)
    } catch (error) {
      console.log(error);
    }
  }

  const handlerBillList = async () => {
    try {
      setLoader(true)
      let result = await getApi(`${BILLS_API}?billType=0`);
      result = result.res.bill_list.filter((item) => item.Status === VENDOR_MANAGER_PENDING || item.Status === VENDOR_REJECT);
      setLoader(false)
      setBills(result)
    } catch (error) {
      console.log(error);
    }
  }

  const handlerContractRedicalChart = async (startDate, endDate) => {
    try {
      setLoader(true)
      let result = await getApi(`${CONTRACT_STATUS}?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`);
      setContractRadialChartData({
        series: result.res.series,
        labels: result.res.labels,
        total: result.res.total,
      });
      setLoader(false)
    } catch (error) {
      console.log(error);
    }
  }

  const handlerBillRedicalChart = async (startDate, endDate) => {
    try {
      setLoader(true)
      let result = await getApi(`${BILL_STATUS}?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`);
      setBillRadialChartData({
        series: result.res.series,
        labels: result.res.labels,
        total: result.res.total,
      });
      setLoader(false)
    } catch (error) {
      console.log(error);
    }
  }

  const handlerIndividualBillList = async () => {
    try {
      setLoader(true)
      let result = await getApi(`${BILLS_API}?billType=1`);
      result = result.res.bill_list.filter((item) => item.Status === VENDOR_MANAGER_PENDING || item.Status === VENDOR_REJECT);
      setLoader(false)
      setIndividualBills(result)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleVendorList();
    handlerPurchaseContractList()
    handlerBillList()
    handlerContractRedicalChart(selectedDateRange.startDate, selectedDateRange.endDate);
    handlerBillRedicalChart(selectedDateRange.startDate, selectedDateRange.endDate)
    handlerIndividualBillList()
  }, []);

  const columns = useFeedsColumnsVendor(handleVendorList);
  const billcolumns = useFeedsColumnBills(handlerBillList)
  const purchasecolumns = useFeedsColumnContracts(handlerPurchaseContractList)
  const individualbillcolumns = useFeedsColumnIndividualBills(handlerIndividualBillList)

  return (

    <>

      <Helmet>
        <title>Vendor Portal || Home</title>
      </Helmet>

      <Loader isLoading={loader} />
      <div className="container-fluid p-0">
        <div className='col-12 mb-4'>
          <div className='row g-4 mb-2'>
            <div className='col-12 col-lg-9 d-md-none d-lg-block'></div>
            <div className='col-12 col-lg-3'>
              <div className="input-group">
                <span className="input-group-text"><Icon path={mdiCalendar} size={1} /></span>
                <DateRangeCalender
                  setSelectedDateRange={setSelectedDateRange}
                  startDate={selectedDateRange.startDate}
                  endDate={selectedDateRange.endDate}
                  handlerContractRedicalChart={handlerContractRedicalChart}
                  handlerBillRedicalChart={handlerBillRedicalChart}
                />
              </div>
            </div>
          </div>
          <div className='row g-4'>
            <div className='col-12 col-lg-6'>
              <div className='container-section h-100 mb-0'>
                <div className='card-body min-car-body-height'>
                  <RadialCircleChart
                    title="Contract By Status"
                    data={contractRadialChartData.series}
                    label={contractRadialChartData.labels}
                    total={contractRadialChartData.total}
                    startDate={selectedDateRange.startDate}
                    endDate={selectedDateRange.endDate}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className='container-section mb-0'>
                <div className="card-body min-car-body-height">
                  <RadialCircleChart
                    title="Bill By Status"
                    data={billRadialChartData.series}
                    label={billRadialChartData.labels}
                    total={billRadialChartData.total}
                    startDate={selectedDateRange.startDate}
                    endDate={selectedDateRange.endDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          <DashboardCard title={"Vendors"} columns={columns} data={vendors} mt="m-0" fn="" />
          <DashboardCard title={"Purchase Contracts"} columns={purchasecolumns} data={purchaseContract} mt="mt-4" fn="" tableId="contracts" approvalType="contract" />
          <DashboardCard title={"Bills"} columns={billcolumns} data={bills} mt="mt-4" fn="" />
          <DashboardCard title={"Standalone Bill"} columns={individualbillcolumns} data={individualBill} mt="mt-4" fn="" tableId="invoices" approvalType="invoice" />
        </>
      </div>

    </>

  )
}

export default AdminManagerDashboard