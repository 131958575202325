import React, { useState } from "react";
import Stepper from "react-stepper-horizontal";
import AddVendor from "./AddVendor";
import AddFinanceInfo from "./AddFinanceInfo";

const AdminStepper = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [vendorDetails, setVendorDetails] = useState({})
    const [emailError, setEmailError] = useState({})
    const [bankinfoDetails, setBankInfoDetails] = useState({})
    const [updatedEmail, setUpdatedEmail] = useState()

    const steps = [
        { title: "Vendor Details" },
        { title: "Financial Information" }
    ];

    const getSectionComponent = () => {
        switch (activeStep) {
            case 0:
                return <AddVendor activeStep={activeStep} steps={steps} setActiveStep={setActiveStep} setVendorDetails={setVendorDetails} vendorDetails={vendorDetails} emailError={emailError} setEmailError={setEmailError} bankinfoDetails={bankinfoDetails} setBankInfoDetails={setBankInfoDetails} setUpdatedEmail={setUpdatedEmail} updatedEmail={updatedEmail} />
            case 1:
                return <AddFinanceInfo activeStep={activeStep} steps={steps} setActiveStep={setActiveStep} vendorDetails={vendorDetails} setVendorDetails={setVendorDetails} setEmailError={setEmailError} bankinfoDetails={bankinfoDetails} setBankInfoDetails={setBankInfoDetails} setUpdatedEmail={setUpdatedEmail} />;
            default:

                return null;
        }
    };

    return (
        <div className="container-section">
            <div className="cstm-stepper ">
                <div className="cstm-stepper-scroller">
                    <Stepper
                        steps={steps}
                        activeStep={activeStep}
                        activeColor="#ffd813"
                        completeColor="#ffbd13"
                        completeBarColor="#ffbd13"
                    />
                </div>
                <div>
                    {getSectionComponent()}
                </div>
            </div>
        </div>
    );
};

export default AdminStepper;
