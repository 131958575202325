import { useState, useEffect } from 'react';
import { SIDEBAR_ADMIN_EXECUTIVE, SIDEBAR_ADMIN_MANAGER, SIDEBAR_FINANCE, SIDEBAR_SUPERADMIN, SIDEBAR_VENDOR_APPROVED, SIDEBAR_VENDOR_PENDING, SIDEBAR_VP, } from '../../components/layouts/sidebarList';
import { ADMIN_EXECUTIVE_ID, ADMIN_MANAGER_ID, FINANCE_ID, SUPER_ADMIN_ID, VENDORID, VENDOR_APPROVE, VENDOR_REJECT, VP_ID } from '../constant';

const useSidebarSwitch = (sidebarType, ViewVendordetails) => {
  const [sidebarConfig, setSidebarConfig] = useState([]);

  useEffect(() => {
    // Set the sidebar configuration based on the passed parameter
    switch (sidebarType) {
      case VENDORID:
        if (ViewVendordetails.Status === VENDOR_APPROVE && ViewVendordetails.bankInfoFlag === 0 && !ViewVendordetails.selfService || ViewVendordetails.Status === VENDOR_REJECT && ViewVendordetails.bankInfoFlag === 1 && !ViewVendordetails.selfService) {
          setSidebarConfig(SIDEBAR_VENDOR_PENDING);
        } else {
          setSidebarConfig(SIDEBAR_VENDOR_APPROVED);
        }
        break;
      case ADMIN_EXECUTIVE_ID:
        setSidebarConfig(SIDEBAR_ADMIN_EXECUTIVE);
        break;
      case ADMIN_MANAGER_ID:
        setSidebarConfig(SIDEBAR_ADMIN_MANAGER);
        break;
      case VP_ID:
        setSidebarConfig(SIDEBAR_VP);
        break;
      case FINANCE_ID:
        setSidebarConfig(SIDEBAR_FINANCE);
        break;
      case SUPER_ADMIN_ID:
        setSidebarConfig(SIDEBAR_SUPERADMIN);
        break;
      // Add more cases if needed for additional sidebar types
      default:
        console.error('Invalid sidebar type:', sidebarType);
    }
  }, [sidebarType, ViewVendordetails]);

  return sidebarConfig;
};

export default useSidebarSwitch;
