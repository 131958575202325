import React from 'react'

const RemoveIcon = () => {
    return (
        <div>
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="35" height="35" rx="6" fill="#FF5050" />
                <path d="M11 18H25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </div>
    )
}

export default RemoveIcon