import React, { useState } from 'react';

const ReconcileTabBar = ({ tabs, defaultTab, onTabChange }) => {

    const [activeTab, setActiveTab] = useState(defaultTab);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (onTabChange) {
            onTabChange(tab);
        }
    };

    return (

        <div className="tab-bar d-flex report-tab-section">
            {tabs.map((tab) => (
                <div
                    key={tab.id}
                    className={`tab cursor-pointer p-3 ${activeTab === tab.id ? 'tab-active' : ''}`}
                    onClick={() => handleTabClick(tab.id)}
                >
                    {tab.label}
                </div>
            ))}
        </div>

    );
};

export default ReconcileTabBar;