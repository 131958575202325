import { useContext, useMemo } from 'react';
import { useStatusRenderer } from '../useStatus';
import { useDateFormat } from '../useDateFormate';
import eyeIcon from "../../../assets/image/eye.png"
import { useNavigate } from 'react-router-dom';
import invoiceIcon from "../../../assets/image/invoiceIcon.png"
import edit from "../../../assets/image/edit2.png"
import ApproveButton from '../../../assets/image/ApproveButton';
import RejectButton from '../../../assets/image/RejectButton';
import { ADMIN_MANAGER_ID, SUPER_ADMIN_ID, VENDOR_APPROVE, VENDOR_MANAGER_PENDING, VENDOR_REJECT, VENDOR_VP_PENDING, VP_ID } from '../../constant';
import { ModalContext } from '../../Context';
import ApprovePurchaseContract from '../../../components/modal/ApprovePurchaseContract';
import RejectPuchaseContract from '../../../components/modal/RejectPuchaseContract';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FullPaidBill from '../../../components/modal/FullyPaidBill';
import PendingItem from '../../../components/modal/PendingItem';

// Custom hook for defining FeedsColumns
export const useFeedsColumnContracts = (handlerPurchaseContractList) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;
  const navigate = useNavigate();

  const handlerPurchaseView = (id) => {
    navigate(`/view-purchase-contract/${id}`)
  }

  const handleApprove = (data) => {
    let app = <ApprovePurchaseContract data={data} handlerPurchaseContractList={handlerPurchaseContractList} />
    handleModalData(app, "md");
  }

  const handleReject = (data) => {
    let rej = <RejectPuchaseContract data={data} handlerPurchaseContractList={handlerPurchaseContractList} />
    handleModalData(rej, "md");
  }

  const handlerCreateInvoice = (data) => {
    if (data?.Difference === 0) {
      const ApproveModal = <FullPaidBill />
      handleModalData(ApproveModal, "md");
    }
    else if (data?.VendorStatus === VENDOR_MANAGER_PENDING && data?.IsSelfService === true) {
      let pendingModal = <PendingItem message="Details are pending for approval" />;
      handleModalData(pendingModal, "md");
    }
    else {
      navigate(`/add-bill/${data.id}`);
    }
  };

  const handlerEditPurchaseContarct = (data) => {
    navigate(`/edit-executive-contract/${data.id}`)
  }

  const columns = useMemo(() => [
    {
      Header: "Purchase Contract",
      accessor: "DocumentNo",
    },
    {
      Header: "Vendor Name",
      accessor: "LegalName",
    },
    {
      Header: "Start Date",
      accessor: "StartDate",
      Cell: ({ row }) => <>{useDateFormat(row.original.StartDate)}</>,
    },
    {
      Header: "End Date",
      accessor: "EndDate",
      Cell: ({ row }) => <>{useDateFormat(row.original.EndDate)}</>,
    },
    {
      Header: "Contract Total",
      headerStyle: { textAlign: 'right' },
      accessor: "ContractTotal",
      Cell: ({ row }) => <><div className='text-end pe-4'>{row?.original?.CurrencySymbol} {row.original.ContractTotal?.toFixed(2)}</div></>,
    },
    {
      Header: <div className="ps-5">Status</div>,
      accessor: "StatusName",
      Cell: ({ row }) => useStatusRenderer(row),
      disableSortBy: true,
    },
    {
      Header: "Action",
      accessor: (data) => <>
        <div className="d-flex align-item-center">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips1">
                View
              </Tooltip>
            }
          >
            <img
              src={eyeIcon}
              alt=" "
              className="cursor-pointer cstm-eye-icon-gap"
              onClick={() => handlerPurchaseView(data.id)}
            />
          </OverlayTrigger>

          {(data.Status === VENDOR_APPROVE) && (user.RoleId !== SUPER_ADMIN_ID) && (user.RoleId !== VP_ID)
            && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips2">
                    Create Bill
                  </Tooltip>
                }
              >
                <img
                  src={invoiceIcon}
                  alt=""
                  className="cursor-pointer cstm-icon-gap"
                  onClick={() => handlerCreateInvoice(data)}
                />
              </OverlayTrigger>
            )}

          {((data.Status === VENDOR_MANAGER_PENDING &&
            user.RoleId === ADMIN_MANAGER_ID) ||
            (data.Status === VENDOR_VP_PENDING &&
              user.RoleId === VP_ID)) && (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips3">
                      Approve
                    </Tooltip>
                  }
                >
                  <span
                    className="cursor-pointer cstm-icon-gap"
                    onClick={() => handleApprove(data)}
                  >
                    <ApproveButton />
                  </span>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips4">
                      Reject
                    </Tooltip>
                  }
                >
                  <span
                    className="cursor-pointer cstm-icon-gap"
                    onClick={() => handleReject(data)}
                  >
                    <RejectButton />
                  </span>
                </OverlayTrigger>
              </>
            )}

          {(data.Status === VENDOR_REJECT ||
            (data.Status === VENDOR_MANAGER_PENDING &&
              user.RoleId === ADMIN_MANAGER_ID)) && (
              <>
                {(user.RoleId !== VP_ID) && (user.RoleId !== SUPER_ADMIN_ID) && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips5">
                        Edit
                      </Tooltip>
                    }
                  >
                    <img className="cursor-pointer cstm-icon-gap"
                      src={edit}
                      alt=""
                      onClick={() => handlerEditPurchaseContarct(data)}
                    />
                  </OverlayTrigger>
                )}
              </>
            )}
        </div>
      </>,
    },
  ], []);

  return columns;
};