import React from 'react'

const EyeIcon = () => {
  return (

    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30.4469" height="30" rx="6" fill="" />
      <path d="M15.2843 9C10.1489 9 7.10425 15 7.10425 15C7.10425 15 10.1489 21 15.2843 21C20.2979 21 23.3426 15 23.3426 15C23.3426 15 20.2979 9 15.2843 9ZM15.2234 11C17.4765 11 19.283 12.8 19.283 15C19.283 17.22 17.4765 19 15.2234 19C12.9907 19 11.1638 17.22 11.1638 15C11.1638 12.8 12.9907 11 15.2234 11ZM15.2234 13C14.107 13 13.1936 13.9 13.1936 15C13.1936 16.1 14.107 17 15.2234 17C16.3398 17 17.2532 16.1 17.2532 15C17.2532 14.8 17.172 14.62 17.1314 14.44C16.9691 14.76 16.6443 15 16.2383 15C15.67 15 15.2234 14.56 15.2234 14C15.2234 13.6 15.467 13.28 15.7918 13.12C15.6091 13.06 15.4264 13 15.2234 13Z" fill="white" />
    </svg>
  )
}

export default EyeIcon