import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROLE_LIST } from "../../../utils/apiRoutes";
import { getApi } from "../../../utils/services";
import { useFeedColumnTerminology } from "../../../utils/helpers/tableHelpers/useFeedColumnTerminology";
import Table from "../../common/Table";
import BackButton from "../../../assets/image/BackButton";
import EditTerminology from "../../modal/EditTerminology";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";

const TerminologyList = () => {
    const navigate = useNavigate();
    const [role, setRoles] = useState([]);
    const [loader, setLoader] = useState(false)
    const handleRoleApi = async () => {
        
        try {
            setLoader(true)
            const result = await getApi(ROLE_LIST);
            setLoader(false)
            setRoles(result.res.role_list);
        } catch (error) {
            console.error("Error fetching roles:", error);
        }
    };

    useEffect(() => {
        handleRoleApi();
    }, []);
    const { columns, showEditModal, setShowEditModal, selectedItem, handleEdit } = useFeedColumnTerminology(handleRoleApi);
    const handleEditSuccess = () => {
        handleRoleApi();
    };

    return (
        <>
         <Helmet>
        <title>Vendor Portal || Terminology List</title>
      </Helmet>
      <Loader isLoading={loader}/>
      
        <div className="container-section">
            <div className="section-header ">
                <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
                    <BackButton />
                </button>
                <h3> Role Terminology</h3>
            </div>
            <div className="form-style">
                {showEditModal && (
                    <EditTerminology
                        handleClose={() => setShowEditModal(false)}
                        show={showEditModal}
                        selectedItem={selectedItem}
                        role={role}
                        onEditSuccess={handleEditSuccess}
                    />
                )}
                <Table columns={columns} data={role} />
            </div>
        </div>
        </>
    );
};

export default TerminologyList;