import { useState } from 'react';
import { EMAILVALIDATOR } from '../utils/constant';

const ChangePasswordFromVeliditon = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (oldpass, newpass, confpass) => {
    let _errors = {};
    let formIsValid = true;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/;

    if (!oldpass) {
      formIsValid = false;
      _errors["oldpassword"] = "Enter Current Password ";
    }
    if (!newpass) {
      formIsValid = false;
      _errors["newpassword"] = "Enter New Password ";
    } else if (newpass.length < 8) {
      formIsValid = false;
      _errors["newpassword"] = "New password must have at least 8 characters !";
    }
    else if (!passwordRegex.test(newpass)) {
      formIsValid = false;
      _errors["newpassword"] =
        "New password does not match with password policy !";
    }
    if (!confpass) {
      formIsValid = false;
      _errors["confirmpassword"] = "Enter Confirm Password ";
    } else if (newpass !== confpass) {
      formIsValid = false;
      _errors["confirmpassword"] = "Confirm password does not match with new password !";
    }

    setErrors(_errors);
    return formIsValid;
  };

  return { errors, setErrors, validateForm };
};

export default ChangePasswordFromVeliditon;