import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LockIcon from "../../../assets/image/LockIcon";
import BackButton from "../../../assets/image/BackButton";
import toast from "react-hot-toast";
import {
  CHANGE_PASSWORD_FINANCE,
} from "../../../utils/apiRoutes";
import { postApi } from "../../../utils/services";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiInformationVariantCircleOutline } from '@mdi/js';
import ChangePasswordFromVeliditon from "../../../middleware/changePasswordErroVelidation";

const FinanceChangePassword = () => {
  const navigate = useNavigate();
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [confpass, setCofpass] = useState("");
  const user = JSON.parse(localStorage.getItem("userData"));
  const [currentPassword, setCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const { errors, setErrors, validateForm } = ChangePasswordFromVeliditon();
  const [loader, setLoader] = useState(false);

  const handleChangePasswordApi = async (e) => {
    try {
      const isFormValid = validateForm(oldpass, newpass, confpass);
      if (isFormValid) {
        setLoader(true);
        let result = await postApi(CHANGE_PASSWORD_FINANCE, {
          financeId: user.id,
          oldPassword: oldpass,
          newPassword: newpass,
          confirmPassword: confpass,
        });
        if (result.res.success) {
          toast.success(result.res.message);
          navigate("/")
          setLoader(false);
          setOldpass("");
          setNewpass("");
          setCofpass("");
        } else if (result.res.success === false) {
          setLoader(false);
          const updatedErrors = {};
          if (result.res.message.includes("Current password")) {
            updatedErrors.oldpassword = result.res.message;
          }
          setErrors(updatedErrors);
        }
        else {
          toast.error(result.res.message);
        }
      }
    } catch (error) {
      toast.error(error.res.message);
      console.log(error.message);
    }
  };
  const togglePasswordVisibility1 = () => {
    setCurrentPassword(!currentPassword);
  };
  const togglePasswordVisibility2 = () => {
    setNewPassword(!newPassword);
  };
  const togglePasswordVisibility3 = () => {
    setConfirmPassword(!confirmPassword);
  };

  return (
    <>

      <div className="container-section">
        <div className="section-header">
          <div className="d-flex align-items-center">
            <BackButton />
            <h3>Change password</h3>
          </div>
        </div>
        <div className="change_password_form">
          <div className="change_pasword_card">
            <div className="change_password_card_title">
              <div className="d-flex align-items-center">
                <LockIcon />
                <h3 className="ms-2 my-0">Change Password</h3>
              </div>
            </div>
            <div className="change_password_fild">
              <div className="form-group mb-3">
                <label htmlFor="usr" className="form-label">
                  Current Password<span className="required">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={currentPassword ? "text" : "password"}
                    className="form-control"
                    aria-label="Amount (to the nearest dollar)"
                    placeholder="Current password"
                    autoComplete="off"
                    onChange={(e) => {
                      setOldpass(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        oldpassword: "",
                      }));
                    }}
                  />
                  <div
                    className="input-group-text input-pass-icon cstm-eye-icon cursor-pointer"
                    onClick={togglePasswordVisibility1}
                  >
                    <Icon
                      path={currentPassword ? mdiEyeOff : mdiEye}
                      size={0.8}
                      color="black"
                    />
                  </div>
                </div>
                {errors.oldpassword && (
                  <p className="validation-bill-err">{errors.oldpassword}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="usr" className="form-label">
                  New Password
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="custom-tooltip" id="tooltips1">
                        Password must contain at least one uppercase letter, one
                        lowercase letter, one number, one special character, and
                        no spaces
                      </Tooltip>
                    }
                  >
                    <span className="ms-1 infoIcon">
                      <Icon
                        path={mdiInformationVariantCircleOutline}
                        size={0.6}
                      />
                    </span>
                  </OverlayTrigger>
                  <span className="required">*</span>
                </label>
                <div className="input-group ">
                  <input
                    type={newPassword ? "text" : "password"}
                    className="form-control"
                    aria-label="Amount (to the nearest dollar)"
                    autoComplete="off"
                    placeholder="New password"
                    onChange={(e) => {
                      setNewpass(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        newpassword: "",
                      }));
                    }}
                  />
                  <div
                    className="input-group-text input-pass-icon  cstm-eye-icon cursor-pointer"
                    onClick={togglePasswordVisibility2}
                  >
                    <Icon
                      path={newPassword ? mdiEyeOff : mdiEye}
                      size={0.8}
                      color="black"
                    />
                  </div>
                </div>
                {errors.newpassword && (
                  <p className="validation-bill-err">{errors.newpassword}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="usr" className="form-label">
                  Confirm Password<span className="required">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={confirmPassword ? "text" : "password"}
                    className="form-control"
                    aria-label="Amount (to the nearest dollar)"
                    autoComplete="off"
                    placeholder="Confirm password"
                    onChange={(e) => {
                      setCofpass(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        confirmpassword: "",
                      }));
                    }}
                  />
                  <div
                    className="input-group-text input-pass-icon cstm-eye-icon cursor-pointer"
                    onClick={togglePasswordVisibility3}
                  >
                    <Icon
                      path={confirmPassword ? mdiEyeOff : mdiEye}
                      size={0.8}
                      color="black"
                    />
                  </div>
                </div>
                {errors.confirmpassword && (
                  <p className="validation-bill-err">{errors.confirmpassword}</p>
                )}
              </div>
            </div>
            <hr className="m-0" />
            <div className="form-style">
              <div className=" d-flex align-items-center justify-content-end ">
                <div className="cstm-success-btn ">
                  <button
                    type="submit"
                    className="btn"
                    onClick={handleChangePasswordApi}
                  >
                    {loader ?
                      <>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </>
                      :
                      "Update"
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceChangePassword;
