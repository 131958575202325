import React from 'react'

const DownloadIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 13.5L3.375 7.875L4.95 6.24375L7.875 9.16875V0H10.125V9.16875L13.05 6.24375L14.625 7.875L9 13.5ZM2.25 18C1.63125 18 1.10138 17.7795 0.660377 17.3385C0.219377 16.8975 -0.00074809 16.368 1.91002e-06 15.75V12.375H2.25V15.75H15.75V12.375H18V15.75C18 16.3687 17.7795 16.8986 17.3385 17.3396C16.8975 17.7806 16.368 18.0007 15.75 18H2.25Z" fill="white" />
    </svg>

  )
}

export default DownloadIcon