import React, { useEffect, useState } from 'react'
import { getApi } from '../../../utils/services';
import { BILLS_API, CONTRACT_LIST } from '../../../utils/apiRoutes';
import { useFeedsColumnContracts } from '../../../utils/helpers/tableHelpers/useFeedColumnContracts';
import { useFeedsColumnBills } from '../../../utils/helpers/tableHelpers/useFeedColumnBills';
import DashboardCard from '../../common/DashboardCard';
import { VENDOR_REJECT } from '../../../utils/constant';
import { Helmet } from "react-helmet";
import Loader from '../../common/Loader';
import { useFeedsColumnIndividualBills } from '../../../utils/helpers/tableHelpers/useFeedColumnIndividualBill';

const VendorDashboard = () => {
    const user = JSON.parse(localStorage.getItem('userData'));
    const [purchaseContract, setPurchaseContarct] = useState([]);
    const [bills, setBills] = useState([]);
    const [loader, setLoader] = useState(false);
    const [individualBill, setIndividualBills] = useState([]);

    const handlerPurchaseContractList = async () => {
        try {
            setLoader(true)
            let result = await getApi(`${CONTRACT_LIST}?vendorId=${user.id}`);
            setLoader(false)
            setPurchaseContarct(result.res.contract_list)
        } catch (error) {
            console.log(error);
        }
    }

    const handlerBillList = async () => {
        try {
            setLoader(true)
            let result = await getApi(`${BILLS_API}?billType=0&vendorId=${user.id}`);
            result = result.res.bill_list.filter((item) => item.Status === VENDOR_REJECT);
            setLoader(false)
            setBills(result)
        } catch (error) {
            console.log(error);
        }
    }

    const handlerIndividualBillList = async () => {
        try {
            setLoader(true)
            let result = await getApi(`${BILLS_API}?billType=1&vendorId=${user.id}`);
            result = result.res.bill_list.filter((item) => item.Status === VENDOR_REJECT);
            setLoader(false)
            setIndividualBills(result)
        } catch (error) {
            console.log(error);
        }
    }

    const purchasecolumns = useFeedsColumnContracts()
    const billcolumns = useFeedsColumnBills()
    const individualbillcolumns = useFeedsColumnIndividualBills(handlerIndividualBillList)

    useEffect(() => {
        handlerPurchaseContractList()
        handlerBillList()
        handlerIndividualBillList()
    }, []);

    return (
        <>

            <Helmet>
                <title>Vendor Portal || Home</title>
            </Helmet>
            <Loader isLoading={loader} />

            <>
                <DashboardCard title={"Purchase Contracts"} columns={purchasecolumns} data={purchaseContract} mt="mt-4" fn="" />
                <DashboardCard title={"Bills"} columns={billcolumns} data={bills} mt="mt-4" fn="" />
                <DashboardCard title={"Standalone Bill"} columns={individualbillcolumns} data={individualBill} mt="mt-4" fn="" />
            </>

        </>
    )
}

export default VendorDashboard