import React, { createContext, useContext, useEffect, useState } from 'react';
import { getApi } from '../services';
import { TRANSACTION_API } from '../apiRoutes';
import AuthenContext from './AuthContext'; 

const TransactionContext = createContext();

export const useTransactionContext = () => {
  return useContext(TransactionContext);
};

const useTransaction = () => {
  const authCtx = useContext(AuthenContext);
  const [transactionList, setTransactionList] = useState([]);

  const fetchTransactionList = async () => {
    try {
      const result = await getApi(TRANSACTION_API);
      setTransactionList(result.res.transaction_list);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchTransactionList();
    }
  }, [authCtx.isLoggedIn]);

  return transactionList
};

export const TransactionProvider = ({ children }) => {
  const transactionData = useTransaction();

  return (
    <TransactionContext.Provider value={transactionData}>
      {children}
    </TransactionContext.Provider>
  );
};
