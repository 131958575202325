import React, { useEffect, useState } from "react";
import BackButton from "../../../assets/image/BackButton";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getApi, postApi } from "../../../utils/services";
import { ADD_TDS, VIEW_BILL, APPROVE_BILL_API } from "../../../utils/apiRoutes";
import AddTDSValidation from '../../../middleware/addTDSValidation';
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";

const AddTds = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("userData"));
    const transactionList = useTransactionContext();
    const [viewBill, setViewBill] = useState({});
    const [tdsAmount, setTdsAmount] = useState('');
    const [tdsPercentage, setTdsPercentage] = useState('');
    const [totalAmount, setTotalAmount] = useState();
    const [submitDisable, setSubmitDisable] = useState(false)
    const [loader, setLoader] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const { errors, setErrors, validateForm } = AddTDSValidation();

    const handleViewdetails = async () => {
        setSpinner(true)
        const result = await getApi(`${VIEW_BILL}/${id}`);
        setSpinner(false)
        setViewBill(result.res.bill_details);
    };

    useEffect(() => {
        if (id) {
            handleViewdetails();
        }
    }, [id]);

    const handleTDS = (e) => {

        let { name, value } = e.target;

        value = value.replace(/[^0-9.]/g, '');

        const dotIndex = value.indexOf('.');

        if (dotIndex !== -1 && value.length - dotIndex > 3) {
            value = value.slice(0, dotIndex + 3);
        }
        if (value < viewBill.InvoiceTotal) {
            setTdsAmount(value);
            const calculatedPercentage = (value / viewBill?.InvoiceTotal) * 100 || 0;
            setTdsPercentage(calculatedPercentage.toFixed(2))
            setTotalAmount(viewBill?.Total - value);
        }

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[name];
            return updatedErrors;
        });

    }

    const handleSubmit = async () => {

        // if (ViewItem?.AdvancePayment || isTdsValid && tdsPercentage !== "") {

        const isValid = validateForm(tdsAmount);

        if (isValid) {

            setSubmitDisable(true)
            setLoader(true)

            const result = await postApi(ADD_TDS, {
                // "InvoiceId": id,
                // "TDS": ViewItem?.AdvancePayment ? 0 : tdsPercentage,
                // "Total": ViewItem?.AdvancePayment ? ViewItem?.InvoiceTotal : totalAmount,
                // "TDSAmount": ViewItem?.AdvancePayment ? 0 : tdsAmount
                "invoiceId": Number(id),
                "TDS": Number(tdsPercentage),
                "tdsAmount": Number(tdsAmount),
                "total": totalAmount
            })

            if (result.res.success) {

                const approvalResult = await postApi(APPROVE_BILL_API, {
                    "billId": Number(id),
                    "transactionId": transactionList[1].id,
                    "roleId": user.RoleId,
                })

                if (approvalResult.res.success) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: result.res.message,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    navigate('/');
                }
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>Vendor Portal || TDS</title>
            </Helmet>

            <Loader isLoading={spinner} />

            <div className="container-section">

                <div className="section-header">
                    <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
                        <div class="svgsize">
                            <BackButton />
                        </div>
                    </button>
                </div>

                <div className="form-style">
                    <div className="data-inv-info">
                        <div className="data-inv-group-1">
                            <div className="data-details-1">
                                <div className="section-sub-heading">
                                    <h4>Bill To:</h4>
                                    <p>
                                        JSTREAMS SOLUTION PVT LIMITED,
                                        <br />
                                        <span>Address: </span>Unitech Cyber Park, Unit No.- 1005 ,
                                        10th Floor, Tower C, Sector 39, Gurugram, Haryana, Pincode:
                                        122003
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="data-inv-group-2">
                            <div className="data-details-4">
                                <div className="d-flex align-items-center">
                                    <span className=" data-label">Legal Name</span>
                                    <span className="data-semicolon">:</span>
                                    <span className="data-value">{viewBill?.LegalName}</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span className=" data-label">Purchase Contract</span>
                                    <span className="data-semicolon">:</span>
                                    <span className="data-value">
                                        <Link className="" to={`/view-purchase-contract/${viewBill?.ContractId}`} target="_blank">{viewBill?.DocumentNo}</Link>
                                    </span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span className=" data-label">Bill Number</span>
                                    <span className="data-semicolon">:</span>
                                    <span className="data-value">
                                        <Link className="" to={`/view-bill/${viewBill?.id}`} target="_blank">{viewBill?.InvDocumentNo}</Link>
                                    </span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span className=" data-label">Due Date</span>
                                    <span className="data-semicolon">:</span>
                                    <span className="data-value">{moment(viewBill?.DueDate).format('D/MM/YYYY')?.slice(0, 10)}</span>
                                </div>
                                {viewBill?.DepartmentName && (
                                    <div className="d-flex align-items-center">
                                        <span className=" data-label">Department</span>
                                        <span className="data-semicolon">:</span>
                                        <span className="data-value">{viewBill?.DepartmentName}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-style">
                    <div className="section-sub-heading">
                        <h3>Item Description</h3>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-theme-1">
                            <thead>
                                <tr>
                                    <th className="text-left">Item</th>
                                    <th className="text-left">Description</th>
                                    <th className="text-end">Rate</th>
                                    <th className="text-end">Quantity</th>
                                    {viewBill?.LineGST && (
                                        <>
                                            <th className="text-end">CGST%</th>
                                            <th className="text-end">SGST%</th>
                                            <th className="text-end">UTGST%</th>
                                            <th className="text-end">IGST%</th>
                                            <th className="text-end">GST Amount</th>
                                        </>
                                    )}
                                    <th className="text-end">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {viewBill?.LineItems?.map((row, index) => (
                                    <tr key={index}>
                                        <td className="text-left">{row.ItemName}</td>
                                        <td className="text-left">{row.ItemDescription ? row.ItemDescription : "N/A"}</td>
                                        <td className="text-end">{viewBill?.CurrencySymbol} {row?.Rate?.toFixed(2)}</td>
                                        <td className="text-end">{viewBill?.CurrencySymbol} {row?.Quantity?.toFixed(2)}</td>
                                        {viewBill?.LineGST && (
                                            <>
                                                <td className="text-end">{row?.CGST ? row?.CGST : 0}</td>
                                                <td className="text-end">{row?.SGST ? row?.SGST : 0}</td>
                                                <td className="text-end"> {row?.UTGST ? row?.UTGST : 0}</td>
                                                <td className="text-end">{row?.IGST ? row?.IGST : 0}</td>
                                                <td className="text-end">&#x20B9; {row?.GST_Total}</td>
                                            </>
                                        )}
                                        <td className="text-end">{viewBill?.CurrencySymbol} {row?.Amount?.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-end inv-gst-tbl-cont">
                        <div className="cstm-width">
                            <div className="d-flex align-items-center  mb-2">
                                <span className="gst-cont-label">Sub Total
                                </span><span className="gst-cont-semicolumn">:</span>
                                <span className="gst-cont-input">
                                    <input
                                        type="text"
                                        className="form-control text-end"
                                        value={`${viewBill?.CurrencySymbol} ${viewBill?.InvoiceTotal?.toFixed(2)}`}
                                        disabled
                                    /></span>
                            </div>

                            {viewBill?.SubsidiaryId === 2 ?
                                <div className="d-flex align-items-center mb-2">
                                    <span className="gst-cont-label">GST Total</span>
                                    <span className="gst-cont-semicolumn">:</span>
                                    <span className="gst-cont-input">
                                        <input
                                            type="text"
                                            className="form-control text-end"
                                            value={`${viewBill?.CurrencySymbol} ${viewBill?.TaxSubtotal?.toFixed(2)}`}
                                            disabled
                                        /></span>
                                </div>
                                :
                                <div className="d-flex align-items-center mb-2">
                                    <span className="gst-cont-label">VAT Total</span>
                                    <span className="gst-cont-semicolumn">:</span>
                                    <span className="gst-cont-input">
                                        <input
                                            type="text"
                                            className="form-control text-end"
                                            value={`${viewBill?.CurrencySymbol} ${viewBill?.TaxSubtotal?.toFixed(2)}`}
                                            disabled
                                        /></span>
                                </div>
                            }

                            <div className="d-flex align-items-center mb-2">
                                <span className="gst-cont-label">Grand Total
                                </span>
                                <span className="gst-cont-semicolumn">:</span>
                                <span className="gst-cont-input">
                                    <input
                                        type="text"
                                        className="form-control text-end"
                                        value={`${viewBill?.CurrencySymbol} ${viewBill?.Total?.toFixed(2)}`}
                                        disabled
                                    /></span>
                            </div>

                            <div className="d-flex align-items-center mb-4">
                                <span className="gst-cont-label">TDS Amount<span className="required">*</span>
                                </span>
                                <span className="gst-cont-semicolumn">:</span>
                                <span className="gst-cont-input">
                                    <input
                                        type="text"
                                        className="form-control text-end"
                                        placeholder="Enter TDS Amount"
                                        name="tdsAmount"
                                        value={tdsAmount}
                                        onChange={handleTDS}
                                    />
                                    {errors.tdsAmount && <span className="cstm-tds-error">{errors.tdsAmount}</span>}
                                </span>
                            </div>

                            {
                                tdsAmount &&
                                <>

                                    <div className="d-flex align-items-center mb-2">
                                        <span className="gst-cont-label">TDS(%)
                                        </span>
                                        <span className="gst-cont-semicolumn">:</span>
                                        <span className="gst-cont-input">
                                            <input
                                                type="text"
                                                className="form-control text-end"
                                                value={tdsPercentage}
                                                disabled
                                            /></span>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <span className="gst-cont-label">Total Amount
                                        </span>
                                        <span className="gst-cont-semicolumn">:</span>
                                        <span className="gst-cont-input">
                                            <input
                                                type="text"
                                                className="form-control text-end"
                                                value={`${viewBill?.CurrencySymbol} ${totalAmount?.toFixed(2)}`}
                                                disabled
                                            /></span>
                                    </div>

                                </>
                            }
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-end mb-20 mt-2">
                        <div className="cstm-success-btn ms-2">
                            <button type="submit" className="btn" onClick={handleSubmit} disabled={submitDisable}>
                                {loader ?
                                    <>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </>
                                    : "Submit"}
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default AddTds;
