import React, { useEffect, useState } from "react";
import DownloadIcon from "../../../assets/image/DownloadIcon";
import EyeIcon from "../../../assets/image/EyeIcon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import BackButton from "../../../assets/image/BackButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { BASE_IMAGE_URL, getApi, postApi } from "../../../utils/services";
import { BILL_EDIT, DEPARTMENT_LIST, SITE_CONFIGERATION, VIEW_BILL } from "../../../utils/apiRoutes";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import Swal from "sweetalert2";
import EditBillValidation from "../../../middleware/editBillsValidation";
import DeleteIcon from "../../../assets/image/DeleteIcon";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import { ADMIN_MANAGER_ID, BILL_SITE_CONFIGERATION_ID } from "../../../utils/constant";

const EditBill = () => {

  const [lineGSTcheck, setLineGSTcheck] = useState(false);
  const transactionList = useTransactionContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("userData"));
  const [VendorName, setVendorName] = useState();
  const [InvoiceId, setInvoiceId] = useState();
  const [vendorId, setVendorId] = useState();
  const [DocumentNo, setDocumentNo] = useState();
  const [InvDocumentNo, setInvDocumentNo] = useState();
  const [Cgst, setCgst] = useState();
  const [Sgst, setSgst] = useState();
  const [UTgst, setUTgst] = useState();
  const [Igst, setIgst] = useState();
  const [Tax, setTax] = useState();
  const [TaxSubtotal, setTaxSubtotal] = useState();
  const [Total, setTotal] = useState();
  const [RefNo, setRefNo] = useState();
  const [DueDate, setDueDate] = useState();

  const [BillDate, setBillDate] = useState();
  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();
  const [BillEndDate, setBillEndDate] = useState('');

  // const [BillDateError, setBillDateError] = useState(false);

  const [ContractTotal, setContractTotal] = useState();
  const [InvoiceTotal, setInvoiceTotal] = useState();
  const [lineItems, setLineItems] = useState([]);
  const [TotalInvoicedAmount, setTotalInvoicedAmount] = useState();
  const [deletedFileIds, setDeletedFileIds] = useState([]);
  const [Files, setFiles] = useState([]);
  const [attachDocuments, setAttachDocuments] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loaderTwo, setLoaderTwo] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [Term, setTerm] = useState();


  // state advancePayment //
  //  advance payement work //
  const [isAdvancePayment, setIsAdvancePayment] = useState(false);
  const [advancePayment, setAdvancePayment] = useState("");
  // state advancePayment //
  const { errors, setErrors, validateForm } = EditBillValidation();

  const [subsidiaryId, setSubsidiaryId] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [departmentList, setDepartmentList] = useState([])

  const handleVendorView = async (id) => {
    setLoading(true);
    const result = await getApi(`${VIEW_BILL}/${id}`);
    setLoading(false);
    setInvoiceId(result.res.bill_details.id);
    setVendorName(result.res.bill_details.LegalName);
    setDocumentNo(result.res.bill_details.DocumentNo);
    setInvDocumentNo(result.res.bill_details.InvDocumentNo);
    setContractTotal(result.res.bill_details.ContractTotal);
    setTotalInvoicedAmount(result.res.bill_details.TotalInvoicedAmountForContract);
    setRefNo(result.res.bill_details.ReferenceNo);
    setBillDate(moment(result.res.bill_details.BillDate).format().slice(0, 10))
    setDueDate(moment(result.res.bill_details.DueDate).format().slice(0, 10));
    setStartDate(moment(result.res.bill_details.StartDate).format().slice(0, 10));
    setEndDate(moment(result.res.bill_details.EndDate).format().slice(0, 10));
    setInvoiceTotal(result.res.bill_details.InvoiceTotal);
    setLineItems(result.res.bill_details.LineItems);
    setFiles(result.res.bill_details.Files);
    setSgst(result.res.bill_details.SGST);
    setCgst(result.res.bill_details.CGST);
    setUTgst(result.res.bill_details.UTGST);
    setIgst(result.res.bill_details.IGST);
    setTax(result.res.bill_details.Tax)
    setTaxSubtotal(result.res.bill_details.TaxSubtotal);
    setTotal(result.res.bill_details.Total);
    setTerm(result.res.bill_details.Term);
    setLineGSTcheck(result.res.bill_details.LineGST);
    setVendorId(result.res.bill_details.VendorId);
    setIsAdvancePayment(result.res.bill_details.AdvancePayment);
    setAdvancePayment(result.res.bill_details.InvoiceTotal);
    setSubsidiaryId(result.res.bill_details.SubsidiaryId)
    setCurrencySymbol(result.res.bill_details.CurrencySymbol)
    setDepartmentId(result.res.bill_details.DepartmentId)
  };

  useEffect(() => {
    if (id) {
      handleVendorView(id);
    }
  }, [id]);

  useEffect(() => {
    const fetchDepartment = async () => {

      try {
        const departmentResult = await getApi(DEPARTMENT_LIST)
        setDepartmentList(departmentResult.res.department_list);

        let endDateResult = await getApi(SITE_CONFIGERATION);
        endDateResult = endDateResult.res.siteConfigeration_list.find(i => i.id === BILL_SITE_CONFIGERATION_ID);
        setBillEndDate(endDateResult.Days)

      } catch (error) {
        console.log(error);
      }
    };
    fetchDepartment();
  }, [])

  const handleQuantityChange = (index, newQuantity, maxValue) => {
    const updatedLines = [...lineItems];
    const rate = updatedLines[index].Rate;
    const quantity = isNaN(newQuantity) ? "" : newQuantity;

    if (maxValue >= quantity) {
      updatedLines[index].Quantity = quantity;
      const totalAmount = rate * quantity;
      updatedLines[index].Amount = totalAmount.toFixed(2);

      const cgst = parseFloat(updatedLines[index].CGST || 0);
      const sgst = parseFloat(updatedLines[index].SGST || 0);
      const utgst = parseFloat(updatedLines[index].UTGST || 0);
      const igst = parseFloat(updatedLines[index].IGST || 0);

      const totalGSTamount = (totalAmount * (cgst + sgst + utgst + igst)) / 100;

      updatedLines[index].GST_Total = totalGSTamount.toFixed(2);

      const updatedTaxTotal = updatedLines.reduce(
        (acc, item) => acc + parseFloat(item.GST_Total || 0),
        0
      );

      const updatedTotal = updatedTaxTotal + parseFloat(InvoiceTotal);

      setTaxSubtotal(updatedTaxTotal.toFixed(2));
      setTotal(updatedTotal.toFixed(2));
      setLineItems(updatedLines);
    }
  };

  const handleCheck = (e) => {
    const check = e.target.checked;
    setLineGSTcheck(check);

    if (check) {
      const updatedTaxTotal = lineItems.reduce(
        (acc, item) => acc + parseFloat(item.GST_Total || 0),
        0
      );

      const updatedTotal = updatedTaxTotal + parseFloat(InvoiceTotal);

      setTaxSubtotal(updatedTaxTotal.toFixed(2));
      setTotal(updatedTotal.toFixed(2));
    }
  };

  const handleLineLevelGstChange = (index, e) => {
    const { name, value } = e.target;

    const isValidval = value >= 0 && value <= 100;
    const hasValidDecimal = /^(\d{0,5})(\.\d{0,2})?$/.test(value);

    if (isValidval && hasValidDecimal) {
      const updatedLines = [...lineItems];

      updatedLines[index][name] = value;

      const Amount = updatedLines[index].Amount || 0;
      const cgst = parseFloat(updatedLines[index].CGST || 0);
      const sgst = parseFloat(updatedLines[index].SGST || 0);
      const utgst = parseFloat(updatedLines[index].UTGST || 0);
      const igst = parseFloat(updatedLines[index].IGST || 0);

      const totalGSTamount = (Amount * (cgst + sgst + utgst + igst)) / 100;
      updatedLines[index].GST_Total = totalGSTamount.toFixed(2);

      setLineItems(updatedLines);

      const updatedTaxTotal = updatedLines.reduce(
        (acc, item) => acc + parseFloat(item.GST_Total || 0),
        0
      );
      const updatedTotal = updatedTaxTotal + parseFloat(InvoiceTotal);

      setTaxSubtotal(updatedTaxTotal.toFixed(2));
      setTotal(updatedTotal.toFixed(2));
    }
  };

  const handleNegative = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/-/g, "");
    e.target.value = newValue;
  };

  useEffect(() => {
    const calculateAmount = () => {
      const total = lineItems.reduce(
        (acc, item) => acc + parseFloat(item.Amount || 0),
        0
      );

      setInvoiceTotal(total.toFixed(2));
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors['subtotal'];
        return newErrors;
      });
    };

    calculateAmount();
  }, [lineItems]);

  const handleChange = (e, newfield) => {
    const { value } = e.target;

    const isValid = !isNaN(value) && value >= 0 && value <= 100;
    const hasValidDecimal = /^(\d{0,3})(\.\d{0,2})?$/.test(value);

    if (isValid && hasValidDecimal) {
      if (newfield === "Cgst") {
        setCgst(value);
      } else if (newfield === "Sgst") {
        setSgst(value);
      } else if (newfield === "Utgst") {
        setUTgst(value);
      } else if (newfield === "Igst") {
        setIgst(value);
      }
      else if (newfield === "salesTax") {
        setTax(value);
      }
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors['taxError'];
      return newErrors;
    });
  };

  useEffect(() => {
    if (!lineGSTcheck) {
      const cgst = !isNaN(parseFloat(Cgst)) ? parseFloat(Cgst) : 0;
      const sgst = !isNaN(parseFloat(Sgst)) ? parseFloat(Sgst) : 0;
      const utgst = !isNaN(parseFloat(UTgst)) ? parseFloat(UTgst) : 0;
      const igst = !isNaN(parseFloat(Igst)) ? parseFloat(Igst) : 0;
      const tax = !isNaN(parseFloat(Tax)) ? parseFloat(Tax) : 0;

      let taxSubTotal = 0, total = 0;

      if (subsidiaryId === 2) {

        taxSubTotal = ((cgst + sgst + utgst + igst + tax) * parseFloat(InvoiceTotal)) / 100;
        total = taxSubTotal + parseFloat(InvoiceTotal);

        setTaxSubtotal(taxSubTotal.toFixed(2));
        setTotal(total.toFixed(2));
      }

      else {

        taxSubTotal = ((cgst + sgst + utgst + igst + tax) * parseFloat(InvoiceTotal)) / 100;
        total = taxSubTotal + parseFloat(InvoiceTotal);

        setTaxSubtotal(taxSubTotal.toFixed(2));
        setTotal(total.toFixed(2));

      }
    }
    else {
      const updatedTaxTotal = lineItems.reduce(
        (acc, item) => acc + parseFloat(item.GST_Total || 0),
        0
      );

      const updatedTotal = updatedTaxTotal + parseFloat(InvoiceTotal);

      setTaxSubtotal(updatedTaxTotal.toFixed(2));
      setTotal(updatedTotal.toFixed(2));
    }
  }, [Cgst, Sgst, UTgst, Igst, Tax, InvoiceTotal, lineGSTcheck, subsidiaryId]);

  const handleRemoveFile = (index, event) => {
    event.preventDefault();

    const updatedFiles = [...Files];
    const deletedFileId = updatedFiles[index].FileId;

    updatedFiles.splice(index, 1);

    setFiles(updatedFiles);
    setDeletedFileIds((prevDeletedIds) => [...prevDeletedIds, deletedFileId]);
  };

  const handleFileInputChange = (event) => {
    const selectedFilesArray = Array.from(event.target.files);
    setSelectedFiles(selectedFilesArray);

    setErrors((prevErrors) => ({
      ...prevErrors,
      files: "",
    }));

  };

  const handleBillDateChange = (e) => {
    const date = e.target.value;
    const termCount = Term.replace(/\D/g, "");

    if (date) {
      const newDueDate = new Date(date);
      newDueDate.setDate(newDueDate.getDate() + parseInt(termCount));

      setBillDate(date);
      setDueDate(newDueDate.toISOString().split("T")[0]);
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors['billDate'];
      return newErrors;
    });
  };

  const handleRefChange = (event) => {
    const { value } = event.target;
    setRefNo(value);
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors['refNo'];
      return newErrors;
    });
  };

  const handleDepartmentChange = (e) => {
    setDepartmentId(e.target.value)

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }
      delete updatedErrors.departmentId
      return updatedErrors
    })
  };

  const maxBillDate = moment(EndDate).add(BillEndDate, 'days').format().slice(0, 10);

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const isFormValid = validateForm(
      BillDate,
      // isAdvancePayment,
      lineGSTcheck,
      Files,
      Cgst,
      Sgst,
      UTgst,
      Igst,
      Tax,
      InvoiceTotal,
      subsidiaryId,
      selectedFiles,
      lineItems,
      user.RoleId,
      departmentId
    );

    if (isFormValid) {

      setLoaderTwo(true);
      setSubmitDisable(true);

      const updatedLines = lineItems.map((row) => {
        const newline = {
          invoiceLineId: row.InvoiceLineId,
          itemId: Number(row.ItemId),
          rate: Number(row.Rate),
          quantity: Number(row.Quantity),
          totalAmount: Number(row.Amount),
          contractLineId: Number(row.ContractLineId),
          itemDescription: row.ItemDescription || null
        };

        if (lineGSTcheck) {
          newline.cgst = row.CGST ? Number(row.CGST) : null;
          newline.sgst = row.SGST ? Number(row.SGST) : null;
          newline.utgst = row.UTGST ? Number(row.UTGST) : null;
          newline.igst = row.IGST ? Number(row.IGST) : null;
          newline.gstAmount = row.GST_Total ? Number(row.GST_Total) : null;
        }

        return newline;
      });

      let File = [];
      const words = VendorName.split(" ");
      const vendorName = words[0].trim();
      const vendorNameLowerCase = vendorName.toLowerCase();
      const fileUploadPromises = selectedFiles.map(async (file) => {
        const result = await uploadFile(
          "bills",
          vendorNameLowerCase,
          vendorId,
          file
        );
        const singlefile = {
          FileName: result.fileName,
          FilePath: result.filePath,
        };
        File.push(singlefile);
      });

      // Wait for all file uploads to complete
      await Promise.all(fileUploadPromises);

      const result = await postApi(BILL_EDIT, {
        vendorId: Number(vendorId),
        invoiceId: Number(InvoiceId),
        transactionId: transactionList[1].id,
        roleId: user.RoleId,
        departmentId: departmentId ? Number(departmentId) : null,
        billDate: BillDate,
        referenceNo: RefNo,
        dueDate: DueDate,
        isLineLevelGst: lineGSTcheck,
        isAdvancePayment: isAdvancePayment,
        itemDescription: updatedLines,
        // invoiceTotalAmount: isAdvancePayment
        //   ? Number(advancePayment)
        //   : Number(InvoiceTotal),
        invoiceTotalAmount: Number(InvoiceTotal),
        cgst: Cgst ? Number(Cgst) : null,
        sgst: Sgst ? Number(Sgst) : null,
        igst: Igst ? Number(Igst) : null,
        utgst: UTgst ? Number(UTgst) : null,
        salesTax: Tax || null,
        gstTotal: Number(TaxSubtotal),
        // grandTotal: isAdvancePayment ? Number(advancePayment) : Number(Total),
        grandTotal: Number(Total),
        invoiceDocument: File,
        deletedInvoiceDocumentId: deletedFileIds,
      });

      if (result.res.success) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: result.res.message,
          showConfirmButton: false,
          timer: 2000,
        });
        navigate("/invoice-listing");
      }
      else {
        setErrors({ refNo: result.res.message });
        setLoaderTwo(false);
        setSubmitDisable(false);
      }

    }

  };

  // edit Advance Payment //
  // advance payment work //

  // const handleIsAdvancePayment = (e) => {
  //   setIsAdvancePayment(e.target.checked);
  //   setLineGSTcheck(false);
  // };

  const handleAdvancPaymentValue = (e) => {
    let { value } = e.target;
    setAdvancePayment(value);
  };

  const handleDescriptionChange = (value, index) => {

    const updatedLines = [...lineItems];
    updatedLines[index].ItemDescription = value

    setLineItems(updatedLines);
  }

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Bill</title>
      </Helmet>

      <Loader isLoading={loading} />

      <div className="container-section">

        <div className="section-header">
          <button className="back-viewbtn me-2" onClick={() => navigate(-1)}>
            <div className="svgsize">
              <BackButton />
            </div>
          </button>
          <h3>Bill</h3>
        </div>

        <form>

          <div className="form-style">
            <div className="row ">

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="vendor" className="form-label">
                  Legal Name
                </label>

                <input
                  type="text"
                  name="vendor"
                  id="vendor"
                  value={VendorName}
                  className="form-control"
                  disabled
                />
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="contractname" className="form-label">
                  Purchase Contract
                </label>

                <input
                  type="text"
                  id="contractname"
                  value={DocumentNo}
                  className="form-control"
                  name="contractname"
                  disabled
                />
              </div>

              {user.RoleId === ADMIN_MANAGER_ID && (

                <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                  <label htmlFor="departmentId" className="form-label">
                    Department<span className="required">*</span>
                  </label>
                  <select name="departmentId" id="departmentId" className="form-select" value={departmentId} onChange={handleDepartmentChange}>
                    <option value="">Select</option>
                    {departmentList.map((item, index) => (
                      <option key={index} value={item.DepartmentId}>
                        {item.DepartmentName}
                      </option>
                    ))}
                  </select>
                  {errors.departmentId && <div className="validation-bill-err">{errors.departmentId}</div>}
                </div>

              )}

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="refNo" className="form-label">
                  Reference Number
                </label>

                <input
                  type="text"
                  id="reference"
                  className="form-control"
                  name="reference"
                  placeholder="Enter reference number"
                  value={RefNo}
                  maxLength={20}
                  onChange={handleRefChange}
                />
                {errors.refNo && (
                  <div className="validation-bill-err">{errors.refNo}</div>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="pendingbill" className="form-label">
                  Total
                </label>

                <input
                  type="text"
                  name="pendingbill"
                  id="pendingbill"
                  value={`${currencySymbol} ${ContractTotal?.toFixed(2)}`}
                  className="form-control"
                  disabled
                />
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="totalbilled" className="form-label">
                  Total Billed
                </label>

                <input
                  type="text"
                  name="totalbilled"
                  id="totalbilled"
                  value={`${currencySymbol} ${TotalInvoicedAmount?.toFixed(2)}`}
                  className="form-control"
                  disabled
                />
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="billDate" className="form-label">
                  Bill Date<span className="required">*</span>
                </label>

                <input
                  type="date"
                  name="billDate"
                  id="billDate"
                  className="form-control"
                  value={BillDate}
                  min={StartDate}
                  max={maxBillDate}
                  onChange={handleBillDateChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
                {errors.billDate && (
                  <div className="validation-bill-err">{errors.billDate}</div>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                <label htmlFor="dueDate" className="form-label">
                  Due Date
                </label>

                <input
                  type="date"
                  name="due-date"
                  id="due-date"
                  className="form-control"
                  value={DueDate}
                  disabled
                />
              </div>

            </div>
          </div>

          <div className="form-style">
            <div className="cstm-checkbox mb-3">
              <div className="row mb-3">
                {/* {TotalInvoicedAmount == 0 && (
                    <div className="col-2">
                      <div className="d-flex algin-item-center">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          checked={isAdvancePayment}
                          onChange={(e) => handleIsAdvancePayment(e)}
                        />
                        <p className="ms-1">Advance Payment </p>
                      </div>
                    </div>
                  )} */}
                {subsidiaryId === 2 && (
                  <div className="col-md-12">
                    <div className="d-flex algin-item-center">
                      <input
                        type="checkbox"
                        name="check"
                        id="check"
                        className="form-check-input"
                        checked={lineGSTcheck}
                        onChange={(e) => handleCheck(e)}
                      />
                      <label className="ms-2 form-check-label">Line Level GST</label>
                    </div>
                  </div>
                )}

              </div>
            </div>
            <>
              <div className="section-sub-heading">
                <h3>Item Description</h3>
              </div>

              <div className="inv-tbl">
                <div className="table-responsive tbl-body-shadow">
                  <table className="w-100 table-theme-1 ">
                    <thead className="table-heads">
                      <tr>
                        <th className="text-start">Item</th>
                        <th className="text-start">Description</th>
                        <th className="text-end">Rate</th>
                        <th className="text-end">Quantity</th>
                        {lineGSTcheck && (
                          <>
                            <th className="text-end">CGST(%)</th>
                            <th className="text-end">SGST(%)</th>
                            <th className="text-end">UTGST(%)</th>
                            <th className="text-end">IGST(%)</th>
                            <th className="text-end">GST Amount</th>
                          </>
                        )}
                        <th className="text-end">Amount</th>
                      </tr>
                    </thead>

                    <tbody>
                      {lineItems?.map((row, index) => (
                        <tr key={index}>
                          <td className="text-nowrap custom-width">{row.ItemName}</td>
                          <td className={`text-nowrap ${!lineGSTcheck ? 'description-width' : ''}`}>
                            <textarea className={`form-control ${!lineGSTcheck ? '' : 'description-width'}`} rows={1} cols={50} name="itemDescription" value={row.ItemDescription || ''}
                              onChange={(e) => handleDescriptionChange(e.target.value, index)}></textarea></td>
                          <td className="text-end text-nowrap custom-width">
                            {currencySymbol} {row.Rate?.toFixed(2)}
                          </td>
                          <td className="custom-width">
                            <input
                              type="number"
                              className="form-control text-end"
                              name={`quantity_${index}`}
                              value={row.Quantity}
                              onChange={(e) =>
                                handleQuantityChange(
                                  index,
                                  e.target.value,
                                  row.remainingQuantity
                                )
                              }
                              onInput={handleNegative}
                              placeholder={`max ${row.remainingQuantity} `}
                              max={row.remainingQuantity}
                              min={0}
                              disabled={isAdvancePayment}
                            />
                          </td>
                          {lineGSTcheck && (
                            <>
                              <td className="custom-width">
                                <input
                                  type="text"
                                  className="form-control text-end"
                                  name="CGST"
                                  placeholder="CGST(%)"
                                  value={row.CGST !== null ? row.CGST : ''}
                                  onChange={(e) =>
                                    handleLineLevelGstChange(index, e)
                                  }
                                  onInput={handleNegative}
                                  disabled={row.UTGST || row.IGST}
                                />
                              </td>
                              <td className="custom-width">
                                <input
                                  type="text"
                                  className="form-control text-end"
                                  name="SGST"
                                  value={row.SGST !== null ? row.SGST : ''}
                                  placeholder="SGST(%)"
                                  onChange={(e) =>
                                    handleLineLevelGstChange(index, e)
                                  }
                                  onInput={handleNegative}
                                  disabled={row.UTGST || row.IGST}
                                />
                              </td>
                              <td className="custom-width">
                                <input
                                  type="text"
                                  className="form-control text-end"
                                  name="UTGST"
                                  value={row.UTGST !== null ? row.UTGST : ''}
                                  placeholder="UTGST(%)"
                                  onChange={(e) =>
                                    handleLineLevelGstChange(index, e)
                                  }
                                  onInput={handleNegative}
                                  disabled={row.CGST || row.SGST || row.IGST}
                                />
                              </td>
                              <td className="custom-width">
                                <input
                                  type="text"
                                  className="form-control text-end"
                                  name="IGST"
                                  value={row.IGST !== null ? row.IGST : ''}
                                  placeholder="IGST(%)"
                                  onChange={(e) =>
                                    handleLineLevelGstChange(index, e)
                                  }
                                  onInput={handleNegative}
                                  disabled={row.CGST || row.SGST || row.UTGST}
                                />
                              </td>
                              <td
                                className="custom-width text-end text-nowrap"
                              >
                                {row.GST_Total && "\u20b9"}
                                {row.GST_Total}
                              </td>
                            </>
                          )}
                          <td className="custom-width text-end text-nowrap">
                            {currencySymbol} {row.Amount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex justify-content-end inv-gst-tbl-cont mt-4">
                <div className="cstm-width">
                  {isAdvancePayment ? (
                    <>
                      <div className="d-flex align-items-center mb-3">
                        <span className="gst-cont-label">Sub Total</span>
                        <span className="gst-cont-semicolumn">:</span>
                        <span className="gst-cont-input">
                          <input
                            className="form-control text-end"
                            value={advancePayment}
                            type="text"
                            id="finalTotalAmount"
                            placeholder="Sub Total"
                            onChange={handleAdvancPaymentValue}
                          />
                          {errors.subtotal && (
                            <div className="validation-bill-err ">
                              {errors.subtotal}
                            </div>
                          )}
                        </span>
                      </div>

                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center  mb-3">
                        <span className="gst-cont-label">Sub Total</span>
                        <span className="gst-cont-semicolumn">:</span>
                        <span className="gst-cont-input">
                          <input
                            className="form-control text-end"
                            type="text"
                            id="finalTotalAmount"
                            value={`${currencySymbol} ${InvoiceTotal}`}
                            disabled
                          />
                          {errors.subtotal && (
                            <div className="validation-bill-err ">
                              {errors.subtotal}
                            </div>
                          )}
                        </span>
                      </div>

                      {!lineGSTcheck && subsidiaryId === 2 && (
                        <>
                          <div className="d-flex align-items-center mb-2">
                            <span className="gst-cont-label">CGST(%)</span>
                            <span className="gst-cont-semicolumn">:</span>
                            <span className="gst-cont-input">
                              <input
                                type="text"
                                className="form-control text-end"
                                id="cgst"
                                name="cgst"
                                value={Cgst}
                                placeholder="Enter CGST(%)"
                                onChange={(e) => handleChange(e, "Cgst")}
                                disabled={UTgst || Igst}
                                autoComplete="off"
                              />
                            </span>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <span className="gst-cont-label">SGST(%)</span>
                            <span className="gst-cont-semicolumn">:</span>
                            <span className="gst-cont-input">
                              <input
                                type="text"
                                className="form-control text-end"
                                id="sgst"
                                name="sgst"
                                value={Sgst}
                                placeholder="Enter SGST(%)"
                                onChange={(e) => handleChange(e, "Sgst")}
                                disabled={UTgst || Igst}
                                autoComplete="off"
                              />
                            </span>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <span className="gst-cont-label">UTGST(%)</span>
                            <span className="gst-cont-semicolumn">:</span>
                            <span className="gst-cont-input">
                              <input
                                type="text"
                                className="form-control text-end"
                                id="utgst"
                                name="utgst"
                                value={UTgst}
                                placeholder="Enter UGST(%)"
                                onChange={(e) => handleChange(e, "Utgst")}
                                disabled={Cgst || Sgst || Igst}
                                autoComplete="off"
                              />
                            </span>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <span className="gst-cont-label">IGST(%)</span>
                            <span className="gst-cont-semicolumn">:</span>
                            <span className="gst-cont-input">
                              <input
                                type="text"
                                className="form-control text-end"
                                id="igst"
                                name="igst"
                                value={Igst}
                                placeholder="Enter IGST(%)"
                                onChange={(e) => handleChange(e, "Igst")}
                                disabled={Cgst || Sgst || UTgst}
                                autoComplete="off"
                              />
                              {errors.taxError && (
                                <div className="validation-bill-err">
                                  {errors.taxError}
                                </div>
                              )}
                            </span>
                          </div>

                        </>
                      )}
                      {subsidiaryId !== 2 &&
                        <>
                          <div className="d-flex align-items-center mb-3">
                            <span className="gst-cont-label">Sales Tax(%)</span>
                            <span className="gst-cont-semicolumn">:</span>
                            <span className="gst-cont-input">
                              <input
                                type="text"
                                className="form-control text-end"
                                id="salesTax"
                                name="salesTax"
                                placeholder="Enter Sales Tax(%)"
                                value={Tax}
                                onChange={(e) => handleChange(e, "salesTax")}
                                autoComplete="off"
                              />
                              {errors.taxError && (
                                <div className="validation-bill-err">
                                  {errors.taxError}
                                </div>
                              )}
                            </span>
                          </div>

                        </>
                      }

                      {subsidiaryId === 2 ?
                        <div className="d-flex align-items-center mb-2">
                          <span className="gst-cont-label">GST Total</span>
                          <span className="gst-cont-semicolumn">:</span>
                          <span className="gst-cont-input">
                            <input
                              type="text"
                              className="form-control text-end"
                              id="taxSubTotal"
                              name="taxSubTotal"
                              value={`${currencySymbol} ${TaxSubtotal}`}
                              disabled
                            />
                          </span>
                        </div>

                        :
                        <div className="d-flex align-items-center mb-2">
                          <span className="gst-cont-label">VAT Total</span>
                          <span className="gst-cont-semicolumn">:</span>
                          <span className="gst-cont-input">
                            <input
                              type="text"
                              className="form-control text-end"
                              id="taxSubTotal"
                              name="taxSubTotal"
                              value={`${currencySymbol} ${TaxSubtotal}`}
                              disabled
                            />
                          </span>
                        </div>

                      }

                      <div className="d-flex align-items-center mb-2">
                        <span className="gst-cont-label">Grand Total</span>
                        <span className="gst-cont-semicolumn">:</span>
                        <span className="gst-cont-input">
                          <input
                            type="text"
                            className="form-control text-end"
                            id="total"
                            name="total"
                            value={`${currencySymbol} ${Total}`}
                            disabled
                          />
                        </span>
                      </div>

                    </>
                  )}
                </div>
              </div>
            </>

          </div>

          <div className="form-style">
            <div className="section-sub-heading">
              <h3>Files</h3>
            </div>

            <div className="pur-item-list">
              <div className="table-responsive mb-5 tbl-body-shadow">
                <table className="w-100 table-theme-1 ">
                  <thead className="table-heads">
                    <tr>
                      <th className="text-left">File Name</th>
                      <th className="text-left">File Type</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Files && Files.length > 0 ? (
                      Files.map((row, index) => (
                        <tr key={index}>
                          <td className="text-left  text-nowrap">{row.FileName}</td>
                          <td className="text-left">Pdf</td>
                          <td className="text-left d-flex align-items-center justify-content-center">
                            <div className="cstm-download-btn">
                              <Link
                                to={`${BASE_IMAGE_URL}/${row.FilePath}`}
                                download
                                target="_blank"
                              >
                                <button type="button" className="btn">
                                  <DownloadIcon /> Download
                                </button>
                              </Link>
                            </div>
                            <div className="cstm-preview-file-btn ms-2">
                              <Link
                                className="ms-2"
                                to={`/preview-pdf/${btoa(row.FilePath)}`}
                                target="_blank"
                              >
                                <button type="button" className="btn">
                                  <EyeIcon /> Preview
                                </button>
                              </Link>
                            </div>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  className="custom-tooltip"
                                  id="tooltips1"
                                >
                                  Delete
                                </Tooltip>
                              }
                            >
                              <span
                                className="ms-2"
                                onClick={(e) => handleRemoveFile(index, e)}
                              >
                                <DeleteIcon />
                              </span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <input
                  type="checkbox"
                  id="attachDocuments"
                  className="form-check-input"
                  name="attachDocuments"
                  checked={attachDocuments}
                  onChange={(e) => setAttachDocuments(e.target.checked)}
                />

                <label
                  htmlFor="attachDocuments"
                  className="form-check-label ms-2"
                >
                  Check for attach documents
                  <span className="required">*</span>
                </label>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-xl-4 col-lg-4 col-md-5">
                <div className="input-group mmm">
                  <input
                    type="file"
                    accept=".pdf"
                    className="form-control"
                    disabled={!attachDocuments}
                    onChange={handleFileInputChange}
                    multiple
                  />
                </div>
                {errors.files && (
                  <div className="validation-bill-err">
                    {errors.files}
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-20">
              <div className="cstm-success-btn ms-2">
                <button
                  type="submit"
                  className="btn "
                  onClick={(e) => handleEditSubmit(e)}
                  disabled={submitDisable}
                >
                  {loaderTwo ? (
                    <>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>

        </form>

      </div>

    </>
  );
};

export default EditBill;
