import { useState } from 'react';

const LoginFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (loginType, loginData, captcha, captchaInput) => {
    let _errors = {};
    let formIsValid = true;

    if (!loginType) {
      formIsValid = false;
      _errors["logintype"] = "Please select any user type!";
    } else if (!loginData.email) {
      formIsValid = false;
      _errors["email"] = "Please enter username!";
    } else if (!loginData.password) {
      formIsValid = false;
      _errors["password"] = "Please enter Password!";
    } else if (!captchaInput) {
      formIsValid = false;
      _errors["captcha"] = "Please enter Captcha!";
    } else if (captcha !== captchaInput) {
      formIsValid = false;
      _errors["captcha"] = "Invalid captcha!";
    }

    setErrors(_errors);
    return formIsValid;
  };

  return { errors,setErrors, validateForm };
};

export default LoginFormValidation;
