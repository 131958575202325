import React, { useContext, useEffect, useState } from 'react'
import { ModalContext } from '../../utils/Context';
import { postApi } from '../../utils/services';
import { REJECT_PURCHASE_CONTRACT } from '../../utils/apiRoutes';
import toast from 'react-hot-toast';
import { useTransactionContext } from '../../utils/helpers/useTransaction';

const RejectPurachseAt = ({ levelValue, handlerPurchaseContractList, data }) => {
  const [rejectReason, setRejectReason] = useState("");
  const [level, setLevel] = useState("");
  const modalContext = useContext(ModalContext);
  const user = JSON.parse(localStorage.getItem("userData"));
  const transactionList = useTransactionContext();
  const [roleError, setRoleError] = useState(""); // Add state for role error
  const [rejectReasonError, setRejectReasonError] = useState(""); // Add state for rejection reason error
  const { closeModal } = modalContext;
  const [disable, setDisable] = useState(false)

  const validateForm = () => {
    let isValid = true;

    if (!level) {
      setRoleError("Select a role !");
      isValid = false;
    }
    if (!rejectReason.trim()) {
      setRejectReasonError("Please provide a rejection reason !");
      isValid = false;
    }

    return isValid;
  };

  const handlerRejecTorequester = async () => {
    if (!validateForm()) {
      return;
    }
    setDisable(true);

    try {
      let result = await postApi(REJECT_PURCHASE_CONTRACT, {
        "contractId": data.id,
        "transactionId": transactionList[2].id,
        "roleId": user.RoleId,
        "level": Number(level),
        "rejectionReason": rejectReason
      });

      if (result.res.success) {
        toast.success(result.res.message);
        handlerPurchaseContractList();
        closeModal();
      }
    } catch (error) {
      console.error("Error rejecting purchase contract:", error);
      toast.error("An unexpected error occurred");
    } finally {
      setDisable(false);
    }
  };

  const onOptionChangeHandler = (event) => {
    setLevel(event.target.value);
    setRoleError("");
  };

  const onRejectReasonChangeHandler = (e) => {
    setRejectReason(e.target.value);
    setRejectReasonError("");
  };

  return (
    <div className='cstm-frm-mdl'>
      <div class="modal-header">
        <h4 class="modal-title">Rejection Reason</h4>
      </div>

      <div class="modal-body">
                <div class="form-group mb-20 mt-2 position-relative">
                    <label for="" className='form-label'>Are you sure you want to reject this Purchase Contract?</label>
                    <select value={level} className="form-select " onChange={onOptionChangeHandler}>
                      <option value="" >Select</option>
                      {levelValue.map((option, index) => {
                        return (
                          <option key={index} value={option?.level}>
                            {option?.RoleName}
                          </option>
                        );
                      })}
                    </select>
                     {roleError && <span className="validation-bill-err">{roleError}</span>}
                </div>
                <div className='form-group'>
                <textarea value={rejectReason} className="form-control reject-modal-text-area" placeholder="Enter the reason here..." id="floatingTextarea" rows={3} maxLength={100} onChange={onRejectReasonChangeHandler}></textarea>
                {rejectReasonError && <p className="validation-bill-err">{rejectReasonError}</p>}
                </div>
                
            </div>

      



    









      
      <div class="modal-footer">
        <div className=" d-flex align-items-center mb-2">
          <div className="cstm-dngr-btn">
            <button type="button" className="btn" onClick={() => closeModal()}>Cancel</button>
          </div>
          <div className="cstm-reject-btn ms-2">
            <button type="submit" className="btn" onClick={handlerRejecTorequester} disabled={disable}>
              Reject
            </button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default RejectPurachseAt