import { useState } from 'react';
import {EMAILVALIDATOR } from '../utils/constant';

const ForgetFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (loginType, email) => {
    let _errors = {};
    let formIsValid = true;

    if (!loginType) {
        formIsValid = false;
        _errors["userType"] = "Please select any user type!";
      }
      if (!email) {
        formIsValid = false;
        _errors["email"] = "Please enter the email";
      } else {
        // Regular expression for basic email validation     
        if (!EMAILVALIDATOR.test(email)) {
          formIsValid = false;
          _errors["email"] = "Please enter a valid email address";
        }
      }

    setErrors(_errors);
    return formIsValid;
  };
  
  return { errors,setErrors, validateForm };
};

export default ForgetFormValidation;