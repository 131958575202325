import React, { useState } from "react";
import Stepper from "react-stepper-horizontal";
import EditFinanceInfo from "./EditFinanceInfo";
import EditVendor from "./EditVendor";

const EditAdminStepper = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [vendorDetails, setVendorDetails] = useState({})
    const [viewdata, setViewData] = useState({})
    const [emailError, setEmailError] = useState({})
    const [updatedEmail, setUpdatedEmail] = useState()
    const [bankinfoDetails, setBankInfoDetails] = useState({})
    const steps = [
        { title: "Vendor Details" },
        { title: "Financial Information" }
    ];

    const getSectionComponent = () => {
        switch (activeStep) {
            case 0:
                return <EditVendor activeStep={activeStep} steps={steps} setActiveStep={setActiveStep} setVendorDetails={setVendorDetails} vendorDetails={vendorDetails} viewdata={viewdata}  setViewData={setViewData} emailError={emailError} setEmailError={setEmailError} updatedEmail={updatedEmail} setUpdatedEmail={setUpdatedEmail} bankinfoDetails={bankinfoDetails} setBankInfoDetails={setBankInfoDetails}/>
            case 1:
                return <EditFinanceInfo activeStep={activeStep} steps={steps} setActiveStep={setActiveStep} vendorDetails={vendorDetails} setVendorDetails={setVendorDetails}  viewdata={viewdata} setEmailError={setEmailError} setUpdatedEmail={setUpdatedEmail} bankinfoDetails={bankinfoDetails} setBankInfoDetails={setBankInfoDetails}/>;
            default:
                return null;
        }
    };

    return (
        <div className="container-section">
            <div className="cstm-stepper ">
                <div className="cstm-stepper-scroller">
                    <Stepper
                        steps={steps}
                        activeStep={activeStep}
                        activeColor="#ffd813"
                        completeColor="#ffbd13"
                        completeBarColor="#ffbd13"
                    />
                </div>
                <div>
                    {getSectionComponent()}
                </div>
            </div>
        </div>
    );
};

export default EditAdminStepper;
