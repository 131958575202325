import ReactApexChart from "react-apexcharts";
function PieDonutChart({title, data=[], label=[] }) {
    const chartData = {
        series: data,
        options: {
            chart: {
                type: 'donut',
            },
            title: {
                show: true,
                text: title,
                height:350
            },
            // colors: ['#5543b3', '#a6f5c5', '#FF7600', "#FF9D00"],
            labels: label,
            yaxis: [{
                title: {
                  text: 'Amount',
                },
                labels: {
                  show: true,
                  formatter: function (val) {
                    return val + "K";
                  }
                }
              }],

            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
        }
    }
    return (
        <div id="PieDonutChart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="donut" height={352}></ReactApexChart>
        </div>
    )
}
export default PieDonutChart

