
import React from "react";
 
const StatusViewIcon = ({ status }) => {
  const getStatusColor = () => {
    if (status === "Approved") {
      return "#00E100"; 
    } 
    else if (status === "Rejected") {
            return "Red"; 
    }
    else {
      return "#FFD700"; 
    }
  };
 
  return (
    <div
      className="statusicon me-2"
      style={{ background: getStatusColor() }}
    ></div>
  );
};
 
export default StatusViewIcon;