
import Details from "./Details";
import React, { useState } from "react";
import ThankYou from "./Thankyou";
import FinanceInfo from "./FinanceInfo";
import Instructions from "./Instructions";
import Stepper from "react-stepper-horizontal";
import { VENDOR_APPROVE, VENDOR_REJECT } from "../../../../utils/constant";
import EditFinanceInfo from "./EditFinanceInfo";

const StepperForm = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [viewVendorData, setViewVendorData] = useState({})

    const steps = [
        { title: "Instruction" },
        { title: "Vendor Details" },
        { title: "Financial Information" },
        { title: "Confirmation" },
    ];

    const handleBankInfoComponent = () => {

        if (viewVendorData.status === VENDOR_APPROVE && viewVendorData.bankInfoFlag === 0) {
            return <FinanceInfo activeStep={activeStep} steps={steps} setActiveStep={setActiveStep} viewVendorData={viewVendorData} />;
        }
        else if (viewVendorData.status === VENDOR_REJECT && viewVendorData.bankInfoFlag === 1) {
            return <EditFinanceInfo activeStep={activeStep} steps={steps} setActiveStep={setActiveStep} viewVendorData={viewVendorData} />
        }
    }

    const getSectionComponent = () => {
        switch (activeStep) {
            case 0:
                return <Instructions activeStep={activeStep} steps={steps} setActiveStep={setActiveStep} />
            case 1:
                return <Details activeStep={activeStep} steps={steps} setActiveStep={setActiveStep} setViewVendorData={setViewVendorData} />;
            case 2:
                return handleBankInfoComponent();
            case 3:
                return <ThankYou />;
            default:
                return null;
        }
    };

    return (
        <div className="container-section">
            <div className="cstm-stepper ">
                <div className="cstm-stepper-scroller">
                    <Stepper
                        steps={steps}
                        activeStep={activeStep}
                        activeColor="#ffd813"
                        completeColor="#ffbd13"
                        completeBarColor="#ffbd13"
                    />
                </div>
                <div>
                    {getSectionComponent()}
                </div>
            </div>
        </div>
    );
};

export default StepperForm;
