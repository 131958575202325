import React, { useEffect, useState } from "react";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import { BASE_IMAGE_URL, getApi, postApi } from "../../../utils/services";
import {
  BANK_DETAILS_LIST,
  EDIT_VENDOR,
  PAYMENT_MODE_LIST,
} from "../../../utils/apiRoutes";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import FilePreviewIcon from "../../../assets/image/FilePreviewIcon";
import Modal from "react-bootstrap/Modal";
import cross from "../../../../src/assets/image/cross.png";
import EditFinanceInfoValidation from "../../../middleware/editFinanceInfoValidation";
import { ViewAgenda } from "@mui/icons-material";
import { mdiConsoleLine } from "@mdi/js";
import DownloadSvg from "../../../assets/image/DownloadSvg";
import PreviewPdf from "../previewPdf/PreviewFile";
import eyeicon from "../../../assets/image/eye.png";
import FileIcon from "../../../assets/image/FileIcon";

const EditFinanceInfo = ({
  activeStep,
  setActiveStep,
  vendorDetails,
  setVendorDetails,
  viewdata,
  setEmailError,
  setUpdatedEmail,
  bankinfoDetails,
  setBankInfoDetails
}) => {

  const navigate = useNavigate();
  const [bankDocumentObject, setBankDocumentObject] = useState({
    BankDocumentName: viewdata.CancelChequeName,
    BankDocumentPath: viewdata.CancelChequePath,
  });
  const [bankDetailsList, setBankDetailsList] = useState([]);
  const [bankDocument, setBankDocument] = useState([]);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [documentFlag, setDocumentFlag] = useState(false);
  const [paymentModeList, setPaymentModeList] = useState([]);
  const [paymentModeId, setPaymentModeId] = useState();
  const { errors, setErrors, validateForm } = EditFinanceInfoValidation();
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [show, setShow] = useState(false);
  const [demo, setDemo] = useState(bankinfoDetails.paymentModeId);

  useEffect(() => {
    setDemo(bankinfoDetails.paymentModeId)
    if (bankinfoDetails && bankinfoDetails.paymentModeId) {
      setBankDetailsList([]);
      setShowBankDetails(false);
      // let updatedBankDetails = mergeArray(bankDetailsList, viewdata.BankInfo);
      // updatedBankDetails = mergeArray(bankDetailsList, viewdata.BankInfo);
      // const initialValues = updatedBankDetails.reduce((acc, curr) => {
      //   acc[curr.FieldName] = {
      //     fieldId: curr.id,
      //     fieldValue: curr.FieldValue || "",
      //   };
      //   return acc;
      // }, {});

      // setInputValues(initialValues);
      setPaymentModeId(bankinfoDetails.paymentModeId);
    } else if (viewdata.paymentModeId) {
      setPaymentModeId(viewdata.paymentModeId);
    }
    else {
      setPaymentModeId(viewdata.PaymentModeId);
    }
  }, [bankinfoDetails, viewdata]);

  useEffect(() => {
    if (viewdata && viewdata.SubsidiaryId) {
      setDocumentFlag(true);
    } else {
      setDocumentFlag(false);
    }
  }, [vendorDetails]);

  useEffect(() => {
    const handleViewPaymentMode = async () => {
      if (viewdata && viewdata.SubsidiaryId) {
        let result = await getApi(
          `${PAYMENT_MODE_LIST}?subsidiaryId=${viewdata.SubsidiaryId}`
        );
        setPaymentModeList(result.res.paymentMode_list);
      }
    };
    handleViewPaymentMode();
  }, [vendorDetails]);

  const fetchBankDetails = async (paymentModeId) => {
    try {
      const result = await getApi(`${BANK_DETAILS_LIST}/${paymentModeId}`);
      return result.res.bank_details_list;
    } catch (error) {
      console.error("Error fetching bank details:", error);
      throw error;
    }
  };

  const handleBankDetails = async () => {
    if (paymentModeId) {
      let updatedBankDetails = [];

      if (viewdata.PaymentModeId !== paymentModeId) {
        setBankDetailsList([]);
        updatedBankDetails = mergeArray(viewdata.BankInfo, viewdata.BankInfo);
      } else if (viewdata.BankInfo) {
        const bankDetailsList = await fetchBankDetails(paymentModeId);
        setBankDetailsList(bankDetailsList);
        setShowBankDetails(true);
        updatedBankDetails = mergeArray(bankDetailsList, viewdata.BankInfo);
      }


      const initialValues = updatedBankDetails.reduce((acc, curr) => {
        acc[curr.FieldName] = {
          fieldId: curr.id,
          fieldValue: curr.FieldValue || '',
        };
        return acc;
      }, {});

      setInputValues(initialValues);

      if (updatedBankDetails.length === 0 && paymentModeId === 1) {
        let result = await getApi(`${BANK_DETAILS_LIST}/${paymentModeId}`);
        const fetchedBankDetails = result.res.bank_details_list;
        setBankDetailsList(fetchedBankDetails);
        setShowBankDetails(true);

        const newInitialValues = fetchedBankDetails.reduce((acc, curr) => {
          acc[curr.FieldName] = { fieldId: curr.id, fieldValue: "" };
          return acc;
        }, {});
        setInputValues(newInitialValues);

        viewdata.BankInfo = fetchedBankDetails.map(detail => ({
          VendorBankConfigId: detail.id,
          FieldValue: detail.FieldValue || ''
        }));
      }

    } else if (bankinfoDetails.paymentModeId === 1) {

      const bankDetailsArray = Object.entries(bankinfoDetails.bankDetails).map(([key, value]) => ({
        FieldName: key,
        fieldId: value.fieldId,
        fieldValue: value.fieldValue,
      }));

      const initialValues = bankDetailsArray.reduce((acc, curr) => {
        acc[curr.FieldName] = {
          fieldId: curr.fieldId,
          fieldValue: curr.fieldValue || '',
        };
        return acc;
      }, {});


      setInputValues(initialValues);
      setBankDetailsList(bankDetailsArray);

      // Update viewdata.BankInfo
      viewdata.BankInfo = bankDetailsArray.map(detail => ({
        VendorBankConfigId: detail.fieldId,
        FieldValue: detail.fieldValue
      }));
    }
  };

  useEffect(() => {
    handleBankDetails();
  }, [paymentModeId, bankinfoDetails]);

  const mergeArray = (array1, array2) => {
    let mergedArray = [];

    array1.forEach((itemA) => {
      let matchingItem = array2.find(
        (itemB) => itemB.VendorBankConfigId === itemA.id
      );
      if (matchingItem) {
        mergedArray.push({
          id: itemA.id,
          FieldValue: matchingItem.FieldValue,
          FieldName: itemA.FieldName,
        });
      }
    });

    return mergedArray;
  };

  const handleFileChange = (e) => {
    const newfile = e.target.files;
    setBankDocument(Array.from(newfile));

    const updatedErrors = { ...errors };
    delete updatedErrors.bankDocument;

    setErrors(updatedErrors);
  };

  const handlePayMode = (e) => {
    const modeId = Number(e.target.value);
    setPaymentModeId(modeId);
    setBankInfoDetails((prevDetails) => ({
      ...prevDetails,
      paymentModeId: modeId,
    }));

    if (errors.paymentModeId) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        paymentModeId: "",
      }));
    }

    setShowBankDetails(false);
    setErrors({});
  };

  const handleInputChange = (fieldName, id, value) => {
    if (fieldName === "Account Number") {
      // const newVal = value.replace(/[^0-9]/g, "");

      setInputValues((prevValues) => ({
        ...prevValues,
        [fieldName]: { fieldId: id, fieldValue: value },
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [fieldName]: { fieldId: id, fieldValue: value },
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm(
      paymentModeId,
      inputValues,
      bankDocument,
      bankDocumentObject
    );

    if (isValid) {
      setLoader(true);
      setSubmitDisable(true);

      let bankFile = {};
      if (!bankDocumentObject.BankDocumentPath) {
        const filePromises = bankDocument.map(async (file) => {
          if (file) {
            const result = await uploadFile("document", 'finance', 'vendor', file);
            bankFile = { BankDocumentName: result.fileName, BankDocumentPath: result.filePath };
          }
        });

        await Promise.all(filePromises);
      }

      const updatedValues = Object.entries(inputValues).reduce(
        (acc, [key, value]) => {
          const newKey = key.replace(/\s+/g, "");
          acc[newKey] = value;
          return acc;
        },
        {}
      );

      const vendorBankInfoIdArray = viewdata.BankInfo.map(
        (item) => item.VendorBankInfoId
      );
      // const UpdateVendorBankInfoArray = bankinfoDetails?.vendorBankInfoIds?.map((item) => item.VendorBankInfoId) || [];
      let UpdateVendorBankInfoArray = [];
      if (viewdata.PaymentModeId === paymentModeId && !bankinfoDetails?.vendorBankInfoIds) {
        UpdateVendorBankInfoArray = viewdata.BankInfo.map(item => item.VendorBankInfoId);
      } else if (bankinfoDetails?.paymentModeId === paymentModeId && bankinfoDetails?.vendorBankInfoIds?.length > 0) {
        UpdateVendorBankInfoArray = bankinfoDetails.vendorBankInfoIds.map(item => item.VendorBankInfoId) || [];
      }

      const data = {
        ...vendorDetails,
        bankDetails: updatedValues,
        paymentModeId: paymentModeId ? Number(paymentModeId) : null,
        bankDocument: (paymentModeId == 2 || paymentModeId == null) ? bankFile : bankDocumentObject.BankDocumentPath ? bankDocumentObject : bankFile,
        vendorBankInfoIds: UpdateVendorBankInfoArray ? UpdateVendorBankInfoArray : vendorBankInfoIdArray,
      };

      const result = await postApi(EDIT_VENDOR, data);

      if (result.res.success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: result.res.message,
          showConfirmButton: false,
          timer: 2000
        });
        navigate("/vendors");
      }
      if (!result.res.success) {
        setSubmitDisable(false);
        setLoader(false);

        if (result.res.message.includes("Email Id")) {
          setEmailError({ pEmail: result.res.message });
          setUpdatedEmail(result.res.pEmail)
          setActiveStep(activeStep - 1)
        } else {
          setSubmitDisable(false);
          setLoader(false);
        }
      }

    }
  }

  const handleCrossImage = () => {
    setBankDocumentObject({
      BankDocumentName: null,
      BankDocumentPath: null,
    });
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handlePrevious = () => {

    const vendorBankInfoIdArray = viewdata.BankInfo.map(
      (item) => item.vendorBankInfoIds
    );

    const data = {
      bankDetails: inputValues,
      paymentModeId: paymentModeId ? Number(paymentModeId) : null,
      bankDocument: bankDocumentObject.BankDocumentPath ? bankDocumentObject : bankDocument,
      vendorBankInfoIds: viewdata.BankInfo
    };
    setBankInfoDetails(data); // Set the bankInfoDetails with the constructed data
    setActiveStep(activeStep - 1); // Move to the previous step
  };

  const handlePreview = (url) => {
    const preview = <PreviewPdf url={url} />;
    const encodedText = btoa(url);
  };

  return (
    <>
      <div className="card-edit-vendor-div mt-4 mb-3">
        <div className="section-header">
          <h3>Financial Information</h3>
        </div>
        <div className="form-style">
          <div className="row">
            <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 mb-20 position-relative">
              <label htmlFor="paymentMode" className="form-label">
                Payment Mode
              </label>
              <select
                name="paymentMode"
                id="paymentMode"
                className="form-select"
                value={paymentModeId || bankinfoDetails.paymentModeId || ""}
                onChange={handlePayMode}
              >
                <option value="">Select</option>
                {paymentModeList?.map((item, index) => (
                  <option key={index} value={item?.id}>
                    {item?.Name}
                  </option>
                ))}
              </select>
            </div>
            {showBankDetails &&
              (bankDetailsList).map((row, index) => (
                <div
                  className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 mb-20 position-relative"
                  key={index}
                >
                  <label htmlFor="" className="form-label">
                    {row.FieldName}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={`Enter ${row.FieldName}`}
                    value={inputValues[row.FieldName]?.fieldValue || ""}
                    onChange={(e) =>
                      handleInputChange(row.FieldName, row.id, e.target.value)
                    }
                  />
                  {errors[row.FieldName] && (
                    <span className="cstm-err">{errors[row.FieldName]}</span>
                  )}
                </div>
              ))}

            {showBankDetails && paymentModeId === 1 && (
              <div className="col-xl-4 col-lg-6 col-md-6">
                <label htmlFor="fileInput" className="form-label">
                  Upload Document<span className="required">*</span>
                </label>
                <div className="upload-documents-fields">
                  {bankDocumentObject.BankDocumentPath ? (
                    <div className="file-view-button">
                      <span className="view-file-cross-icon">
                        <span
                          className="mdi mdi-close-circle"
                          onClick={handleCrossImage}
                        ></span>
                        <button className="btn" onClick={handleShow}>
                          <span className="d-flex align-items-center">
                            <FilePreviewIcon /> <span>Preview</span>
                          </span>
                        </button>
                      </span>
                    </div>
                  ) : (
                    <div className="input-group mmm">
                      <input
                        type="file"
                        id="files"
                        className="form-control input-fields-cstm"
                        onChange={handleFileChange}
                        multiple
                      />
                      {errors.bankDocument && (
                        <span className="cstm-err">
                          {errors.bankDocument}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="stepper-next-btn">
        {activeStep !== 0 && (
          <span className="cstm-dngr-btn me-2">
            <button
              onClick={(e) => handlePrevious(e)}
              className="btn previousstepper"
            >
              Previous
            </button>
          </span>
        )}
        <div className="cstm-success-btn">
          <button
            onClick={(e) => handleSubmit(e)}
            className="btn"
            disabled={submitDisable}
          >
            {loader ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>

      <>
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          animation={false}
          className="modal-wrap"
          backdrop="static"
          keyboard={false}
        >
          <div className="file-preview-modal-title">
            <div className="svg-size me-2">
              <FileIcon />
            </div>
            <h3 className="m-0">File Details</h3>
          </div>
          <div className="file-preview-modal-body">
            <>
              <div className="files-card ">
                <div className="d-flex align-items-center">
                  <p>{1}.</p>
                  <p className="ms-3 me-3 break-all">{bankDocumentObject.BankDocumentName}</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="me-2">
                    <Link
                      to={`${BASE_IMAGE_URL}/${bankDocumentObject.BankDocumentPath}`}
                      download
                      target="_blank"
                    >
                      <DownloadSvg />
                    </Link>
                  </span>
                  <span
                    onClick={() =>
                      handlePreview(`${BASE_IMAGE_URL}/${bankDocumentObject.BankDocumentPath}`)
                    }
                  >
                    <Link
                      to={`/preview-pdf/${btoa(bankDocumentObject.BankDocumentPath)}`}
                      target="_blank"
                    >
                      <span className="eye-icon">
                        <img src={eyeicon} alt="" />
                      </span>
                    </Link>
                  </span>
                </div>
              </div>
            </>
          </div>
          <hr className="m-0" />
          <div className=" d-flex justify-content-end  file-preview-modal-footer">
            <div className="cstm-dngr-btn ">
              <button type="button" onClick={handleClose} className="btn">
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default EditFinanceInfo;
