import React, { useState, useEffect } from 'react'
import { getApi } from '../../../utils/services';
import { LOG_REPORT } from '../../../utils/apiRoutes';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const PaymentReconcileReport = () => {

    // const [loader, setLoader] = useState(false);
    const [paymentReportList, setPaymentReportList] = useState([]);
    const [status, setStatus] = useState("");
    const monthStartDate = moment().startOf('month');
    const monthEndDate = moment().endOf('month');
    const [fromDate, setFromDate] = useState(monthStartDate.format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(monthEndDate.format('YYYY-MM-DD'));
    const [syncedCount, setSyncedCount] = useState(0)
    const [notSyncedCount, setNotSyncedCount] = useState(0)

    useEffect(() => {
        const handlePaymentReconcileReport = async (e) => {
            // setLoader(true);
            const result = await getApi(
                `${LOG_REPORT}?transactionType=Payment&status=${status}&startDate=${fromDate}&endDate=${toDate}`
            );
            // setLoader(false);
            const syncedCount = result.res.list.filter((i) => i.SyncStatus === 'SYNCED')
            const notSyncedCount = result.res.list.length - syncedCount.length
            setSyncedCount(syncedCount.length);
            setNotSyncedCount(notSyncedCount);
            setPaymentReportList(result.res);
        };

        handlePaymentReconcileReport();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setLoader(true);
        const result = await getApi(`${LOG_REPORT}?transactionType=Payment&status=${status}&startDate=${fromDate}&endDate=${toDate}`);
        // setLoader(false);
        const syncedCount = result.res.list.filter((i) => i.SyncStatus === 'SYNCED')
        const notSyncedCount = result.res.list.length - syncedCount.length
        setSyncedCount(syncedCount.length);
        setNotSyncedCount(notSyncedCount);
        setPaymentReportList(result.res);
    };

    return (
        <>
            <div className="form-style">

                <div className="report-inpfiled-container">

                    <div className="field-flex mb-20">
                        <label htmlFor="transactionStatus" className="form-label">
                            Transaction Status
                        </label>

                        <select id="transactionStatus" className="form-select" name="transactionStatus" onChange={(e) => setStatus(e.target.value)}>
                            <option>Select</option>
                            <option>SYNCED</option>
                            <option>NOT SYNCED</option>
                        </select>
                    </div>

                    <div className="field-flex mb-20">
                        <label htmlFor="fromDate" className="form-label">
                            From Date
                        </label>
                        <input
                            type="date"
                            id="fromDate"
                            className="form-control"
                            name="fromDate"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            onKeyDown={(e) => e.preventDefault()}
                        />
                    </div>

                    <div className="field-flex mb-20">
                        <label htmlFor="toDate" className="form-label">
                            To Date
                        </label>
                        <input
                            type="date"
                            id="toDate"
                            className="form-control"
                            name="toDate"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            onKeyDown={(e) => e.preventDefault()}
                        ></input>
                    </div>

                    <div className="field-flex align-self-end mb-20">
                        <div className="cstm-success-btn">
                            <button type="submit" className="btn" onClick={handleSubmit}>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="section-header justify-content-between">
                    <h3>Payment</h3>
                    <div className="cstm-bill-recon-sec">
                        <div className="d-flex align-items-center">
                            <span className="recon-StatusIcon2 blink"></span>
                            <p className="me-1">{syncedCount}</p>
                            <p>Sync</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="recon-StatusIcon3 blink"></span>
                            <p className="me-1">{notSyncedCount}</p>
                            <p>Not Sync</p>
                        </div>
                    </div>
                </div>
            </div>

            {paymentReportList.record_count === 0 ? (
                <div className="form-style">
                    <div className="section-sub-heading">
                        <h3 className="text-center">{paymentReportList.message}</h3>
                    </div>
                </div>
            ) : (
                <div className="form-style pb-4">
                    <div className="table-responsive tbl-body-shadow">
                        <table className="w-100 table-theme-1">
                            <thead>
                                <tr>
                                    <th className="text-left">Payment Id</th>
                                    <th className="text-left">Bill Number</th>
                                    <th className="text-left">Vendor</th>
                                    <th className="text-left">NetSuite Id</th>
                                    <th className="text-center">Error Date</th>
                                    <th className="text-center">Error Message</th>
                                    <th className="text-center">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentReportList.list?.map((row, index) => (
                                    <tr key={index}>
                                        <td className="text-left">{row?.PaymentId ? row?.PaymentId : "N/A"}</td>
                                        <td className="text-left">{row?.DocumentNo}</td>
                                        <td className="text-left">{row?.LegalName}</td>
                                        <td className="text-left">{row?.ExternalId ? row?.ExternalId : "N/A"}</td>
                                        <td className="text-center">
                                            {moment(row.CreatedAt)
                                                .format("D/MM/YYYY")
                                                ?.slice(0, 10)}
                                        </td>
                                        <td className="text-center">
                                            {row?.Message ?
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip1">
                                                            {row?.Message}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="error-text-truncate">
                                                        {row?.Message}
                                                    </div>
                                                </OverlayTrigger>
                                                :
                                                <div className="error-text-truncate">N/A</div>
                                            }
                                        </td>
                                        <td className="d-flex align-items-center justify-content-center">
                                            <span
                                                className="cstm-sync"
                                                style={{ backgroundColor: row.SyncStatus === "SYNCED" ? "#06bca1" : "#ff4d4d" }}
                                            >
                                                {row.SyncStatus}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};

export default PaymentReconcileReport