import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Link, useParams } from "react-router-dom";
import { BASE_IMAGE_URL } from "../../../utils/services";
import DownloadIcon from "../../../assets/image/DownloadIcon";

const PreviewFile = () => {
  const { id } = useParams();
  const url = `${BASE_IMAGE_URL}/${atob(id)}`;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <div className="form-style">
        <div className="prev-head d-flex align-items-center justify-content-end">
          <Link to={url} download className="mb-2">
            <div className="cstm-download-btn">
              <button type="button" className="btn">
                <DownloadIcon /> Download
              </button>
            </div>
          </Link>
        </div>
        <div className="main">
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <div className="pagec me-3">
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </div>
            <div className="buttonc ">
              <button
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
                className="Pre prev-btn me-2"
              >
                Previous
              </button>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                className="prev-btn"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewFile;
