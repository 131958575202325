import React, { useEffect, useState, useContext } from "react";
import BackButton from "../../../assets/image/BackButton";
import RemoveIcon from "../../../assets/image/RemoveIcon";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import { TERMS_LIST, SUBSIDIARY_LIST, COUNTRY_LIST, STATE_LIST, CITY_LIST, VIEW_VENDOR, CURRENCY_LIST, PAYMENT_MODE_LIST, BANK_DETAILS_LIST, VENDOR_SELF_SERVICE, GET_EMAIL } from "../../../utils/apiRoutes";
import { BASE_IMAGE_URL, getApi, postApi } from "../../../utils/services";
import FilePreviewIcon from "../../../assets/image/FilePreviewIcon";
import cross from "../../../assets/image/cross.png"
import { Modal } from "react-bootstrap";
import { ModalContext } from "../../../utils/Context";
import EditProfileValidation from "../../../middleware/editProfileValidation";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import Loader from "../../common/Loader";
import { Helmet } from "react-helmet";
import PendingItem from "../../modal/PendingItem";
import eyeicon from "../../../assets/image/eye.png";
import FileIcon from "../../../assets/image/FileIcon";
import DownloadSvg from "../../../assets/image/DownloadSvg";
import PreviewPdf from "../previewPdf/PreviewFile";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const EditProfile = () => {

    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("userData"));
    const transactionList = useTransactionContext();
    // const authCtx = useContext(AuthenContext);

    const [formState, setFormState] = useState({
        vendor: '',
        subsidiary: '',
        terms: '',
        addressLine1: '',
        addressLine2: '',
        pincode: '',
        country: '',
        state: '',
        city: '',
        pEmail: '',
        website: '',
        currency: '',
        file: []
    })

    const modalContext = useContext(ModalContext);
    const { handleModalData } = modalContext;
    const [termsList, setTermsList] = useState([])
    const [subsidiaryList, setSubsidiaryList] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [logoObject, setLogoObject] = useState({});
    const [relevantDocObject, setRelevantDocObject] = useState({});
    // const [billingAddDisable, setBillingAddDisable] = useState(true)
    const [contactInfoDisable, setContactInfoDisable] = useState(true)
    const [deletedContactId, setDeletedContactId] = useState([])
    const [submitDisable, setSubmitDisable] = useState(false)
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(false);
    // // const [attachDoc, setAttachDoc] = useState(false);
    // const [relevantDoc, setRelevantDoc] = useState([]);
    const { errors, setErrors, validateForm } = EditProfileValidation();
    const [fieldData, setFieldData] = useState({})

    const [rows, setRows] = useState([
        { firstName: '', lastName: '', email: '', phoneNo: '', phoneCode: '91', checked: true }
    ]);
    const [bankDocument, setBankDocument] = useState([]);
    const [bankDocumentObject, setBankDocumentObject] = useState({})
    const [inputValues, setInputValues] = useState({})
    const [paymentModeId, setPaymentModeId] = useState('')
    const [bankDetailsList, setBankDetailsList] = useState([]);
    const [showBankDetails, setShowBankDetails] = useState(false);
    const [paymentModeList, setPaymentModeList] = useState([])
    const [bankInfo, setBankInfo] = useState([])
    const [attachlogo, setAttachLogo] = useState({});
    const [attachLogoFile, setAttachLogoFile] = useState();

    const handleViewVendordetails = async (id) => {
        try {
            setLoading(true);
            const result = await getApi(`${VIEW_VENDOR}/${id}`);
            setLoading(false);
            setFormState({
                ...formState,
                vendorHeader: result.res.vendor_details.LegalName,
                vendor: result.res.vendor_details.LegalName,
                subsidiary: result.res.vendor_details.SubsidiaryId,
                terms: result.res.vendor_details.TermId,
                website: result.res.vendor_details.Website,
                pEmail: result.res.vendor_details.EmailId,
                addressLine1: result.res.vendor_details.AddressLine1,
                addressLine2: result.res.vendor_details.AddressLine2,
                pincode: result.res.vendor_details.PostalCode,
                city: result.res.vendor_details.City,
                country: result.res.vendor_details.Country,
                state: result.res.vendor_details.State,
                currency: result.res.vendor_details.CurrencyId
            })

            setLogoObject({ LogoName: result.res.vendor_details.LogoName, LogoPath: result.res.vendor_details.LogoPath })
            setRelevantDocObject({ RelevantDocName: result.res.vendor_details.RelevantDocName, RelevantDocPath: result.res.vendor_details.RelevantDocPath })
            let contactInfo = result?.res?.vendor_details?.ContactInfo?.map((item) => ({
                firstName: item?.FirstName, lastName: item?.LastName, email: item?.Email, phoneNo: item?.Phone, phoneCode: item?.PhoneCode || 91,
                checked: item?.IsPrimary, ContactLineId: item?.ContactLineId
            }
            ))
            setRows(contactInfo)
            setAttachLogo({
                LogoName: result.res.vendor_details.LogoName,
                LogoPath: result.res.vendor_details.LogoPath,
            });
            setAttachLogoFile(result.res.vendor_details.LogoPath);

            setPaymentModeId(result?.res?.vendor_details.PaymentModeId)
            setFieldData(result?.res?.vendor_details)
            setBankDocumentObject({ BankDocumentName: result?.res?.vendor_details?.CancelChequeName, BankDocumentPath: result?.res?.vendor_details?.CancelChequePath })
            setBankInfo(result?.res?.vendor_details.BankInfo)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (user.id) {
            handleViewVendordetails(user.id);
        }
    }, [user.id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const termResult = await getApi(TERMS_LIST);
                setTermsList(termResult.res.terms_list);

                const subsidiaryResult = await getApi(SUBSIDIARY_LIST);
                setSubsidiaryList(subsidiaryResult.res.subsidiary_list);

                const countryResult = await getApi(COUNTRY_LIST);
                setCountryList(countryResult.res.country_list);

                const currencyResult = await getApi(CURRENCY_LIST);
                setCurrencyList(currencyResult.res.currency_list);

            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (fieldData.SubsidiaryId) {
            const handleViewPaymentMode = async () => {
                let result = await getApi(`${PAYMENT_MODE_LIST}?subsidiaryId=${fieldData.SubsidiaryId}`)
                setPaymentModeList(result.res.paymentMode_list)
            }
            handleViewPaymentMode()
        }
    }, [fieldData.SubsidiaryId])

    const handleBankDetails = async () => {
        if (paymentModeId) {
            let result = await getApi(`${BANK_DETAILS_LIST}/${paymentModeId}`);
            setBankDetailsList(result.res.bank_details_list);
            setShowBankDetails(true);

            if (fieldData && fieldData.BankInfo) {
                const updatedBankDetails = mergeArray(result.res.bank_details_list, fieldData.BankInfo);

                if (updatedBankDetails.length > 0) {
                    const initialValues = updatedBankDetails.reduce((acc, curr) => {
                        acc[curr.FieldName] = { fieldId: curr.id, fieldValue: curr.FieldValue };
                        return acc;
                    }, {});
                    setInputValues(initialValues);
                } else {
                    const initialValues = result.res.bank_details_list.reduce((acc, curr) => {
                        acc[curr.FieldName] = { fieldId: curr.id, fieldValue: '' };
                        return acc;
                    }, {});
                    setInputValues(initialValues);
                }
            } else {
                console.error('fieldData.BankInfo is not defined');
            }
        }
    };

    const handleFileLogo = (e) => {
        const logoFile = Array.from(e.target.files);
        setAttachLogoFile(logoFile);
        setFormState({
            ...formState,
            file: logoFile,
        });
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors.file;
            return updatedErrors;
        });
    };

    const mergeArray = (array1, array2) => {
        let mergedArray = [];

        array1.forEach(itemA => {
            let matchingItem = array2.find(itemB => itemB.VendorBankConfigId === itemA.id);
            if (matchingItem) {
                mergedArray.push({ id: itemA.id, FieldValue: matchingItem.FieldValue, FieldName: itemA.FieldName });
            }
        });

        return mergedArray;
    };

    useEffect(() => {
        handleBankDetails();
    }, [paymentModeId]);

    const handlePayMode = (e) => {
        setPaymentModeId(Number(e.target.value))
        if (errors.PaymentModeId) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                PaymentModeId: "",
            }));
        }
        setShowBankDetails(false);
        setErrors({});
    }

    useEffect(() => {
        const fetchStates = async () => {
            if (formState.country) {

                const splitedCountryCode = formState.country.split(',')[1];

                try {
                    const result = await getApi(`${STATE_LIST}/${splitedCountryCode}`);
                    setStateList(result.res.state_list);
                } catch (error) {
                    console.log(error);
                }
            }
        };

        fetchStates();
    }, [formState.country]);

    useEffect(() => {
        const fetchCity = async () => {

            if (formState.country && formState.state) {

                const splitedCountryCode = formState.country.split(',')[1];
                const splitedStateCode = formState.state.split(',')[1];

                try {
                    const result = await getApi(`${CITY_LIST}/${splitedCountryCode}/${splitedStateCode}`);
                    setCityList(result.res.city_list);
                } catch (error) {
                    console.log(error);
                }
            }
        };

        fetchCity();
    }, [formState.country, formState.state]);

    const handleChange = (e) => {

        const { name, value, files } = e.target

        if (name === 'vendor') {

            // const newVal = value.replace(/[^a-zA-Z\s]/g, '');

            setFormState({
                ...formState,
                [name]: value,
            })

        }

        else if (name === 'pincode') {

            const newVal = value.replace(/[^0-9]/g, '');

            setFormState({
                ...formState,
                [name]: newVal,
            })

        } else if (name === "pEmail") {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const isValidEmail = emailRegex.test(value);

            setFormState((prevState) => ({
                ...prevState,
                [name]: value,
            }));

            setErrors((prevEmailError) => ({
                ...prevEmailError,
                [name]: isValidEmail
            }));

            if (!isValidEmail) {
                setSubmitDisable(false);
                return;
            }
        }

        else if (name === 'file') {

            setFormState({
                ...formState,
                [name]: Array.from(files),
            })
        }

        else {

            setFormState({
                ...formState,
                [name]: value,
            })

        }

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[name];
            return updatedErrors;
        });
        setSubmitDisable(false);
    }

    const handleEmailBlur = async () => {
        const emailValue = formState.pEmail.trim();
        if (!emailValue) {
            setErrors((prevEmailError) => ({
                ...prevEmailError,
                pEmail: '',
            }));
            return;
        }

        try {
            const response = await postApi(GET_EMAIL, {
                vendorId: Number(user.id),
                type: 'update',
                emailId: emailValue,
            });
            if (response.res && response.res.message) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    pEmail: response.res.message,
                }));
                setSubmitDisable(true);
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    pEmail: '',
                }));
                setSubmitDisable(false);
            }
        } catch (error) {
            console.error('Error checking email existence:', error);
            setErrors((prevErrors) => ({
                ...prevErrors,
                pEmail: 'Server error',
            }));
            setSubmitDisable(false);
        }
    };

    const handleContactChange = (index, e) => {

        const { name, value } = e.target

        if (name === 'firstName' || name === 'lastName') {

            const newVal = value.replace(/[^a-zA-Z\s]/g, '');

            const updatedRows = [...rows];
            updatedRows[index][name] = newVal

            setRows(updatedRows)

        }

        else if (name === 'phoneNo') {

            const newVal = value.replace(/[^0-9]/g, '');

            const updatedRows = [...rows];
            updatedRows[index][name] = newVal

            setRows(updatedRows)

        }

        else if (name === 'email' || name === 'phoneCode') {

            const updatedRows = [...rows];
            updatedRows[index][name] = value

            setRows(updatedRows);
        }

        const updatedErrors = { ...errors };
        if (updatedErrors.rows && updatedErrors.rows[index]) {
            delete updatedErrors.rows[index][name];
        }

        setErrors(updatedErrors);

    }

    const handleInputChange = (fieldName, id, value) => {

        if (fieldName === 'Account Number') {
            // const newVal = value.replace(/[^0-9]/g, '');

            setInputValues((prevValues) => ({
                ...prevValues,
                [fieldName]: { fieldId: id, fieldValue: value },
            }));
        }

        else {
            setInputValues((prevValues) => ({
                ...prevValues,
                [fieldName]: { fieldId: id, fieldValue: value },
            }));
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: '',
        }));
    }

    const handleFileChange = (e) => {
        const newfile = e.target.files;
        setBankDocument(Array.from(newfile));

        const updatedErrors = { ...errors };
        delete updatedErrors.bankDocument;

        setErrors(updatedErrors);

    };

    const handleCrossImage = () => {
        setBankDocumentObject({
            BankDocumentName: null,
            BankDocumentPath: null,
        });
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [logoShow, setLogoShow] = useState(false);
    const handleLogoClose = () => setLogoShow(false);
    const handleLogoShow = () => setLogoShow(true);

    const handleAdd = () => {

        if (rows.length <= 2) {

            setRows([...rows, { firstName: '', lastName: '', email: '', phoneNo: '', phoneCode: '91', checked: false }]);
        }
        // else {
        //     toast.error('Maximum 3 contact information is allowed');
        // }
    }

    const handleRemove = (index) => {

        const updatedRows = [...rows];

        if (updatedRows[index].ContactLineId !== undefined) {

            const deletedContactId = updatedRows[index].ContactLineId;
            setDeletedContactId((prevDeletedIds) => [...prevDeletedIds, { contactLineId: deletedContactId }]);
        }

        updatedRows.splice(index, 1)
        setRows(updatedRows)

        const updatedErrors = { ...errors };
        if (updatedErrors.rows && updatedErrors.rows.length > index) {
            updatedErrors.rows.splice(index, 1);
        }
        setErrors(updatedErrors)

    }

    const handleCheckboxChange = (row) => {

        const updatedRows = rows.map((r) => ({
            ...r,
            checked: r === row ? true : false,
        }));

        setRows(updatedRows);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateForm(
            formState.vendor,
            formState.subsidiary,
            formState.currency,
            formState.terms,
            formState.pEmail,
            formState.file,
            // formState.relevantDoc,
            formState.website,
            formState.addressLine1,
            formState.country,
            formState.state,
            formState.city,
            formState.pincode,
            rows,
            paymentModeId,
            inputValues,
            bankDocumentObject,
            bankDocument
        );


        if (isValid) {
            setSubmitDisable(true);
            setLoader(true);

            let bankFile = {};
            if (paymentModeId === 2) {
                bankFile = { BankDocumentName: null, BankDocumentPath: null }
            }
            else {
                if (bankDocument.length <= 0) {
                    bankFile = bankDocumentObject
                }
                else {
                    const filePromises = bankDocument.map(async (file) => {
                        if (!!file) {
                            const result = await uploadFile("document", 'finance', 'vendor', file);
                            bankFile = { BankDocumentName: result.fileName, BankDocumentPath: result.filePath }
                        }
                    });
                    await Promise.all(filePromises);
                }
            }

            let fileLogo = {};
            if (Array.isArray(attachLogoFile)) {
                const logoUploadPromises = attachLogoFile.map(async (file) => {
                    if (!!file) {
                        const result = await uploadFile(
                            "profile",
                            "logo",
                            "vendor",
                            file
                        );
                        fileLogo = {
                            LogoName: result.fileName,
                            LogoPath: result.filePath,
                        };
                    }
                });

                await Promise.all(logoUploadPromises);
            }

            const vendorBankInfoIdArray = bankInfo.map(item => item.VendorBankInfoId);

            const updatedValues = Object.entries(inputValues).reduce((acc, [key, value]) => {
                const newKey = key.replace(/\s+/g, '');
                acc[newKey] = value;
                return acc;
            }, {});

            let updated_contact_information = rows.map((item) => ({
                "contactLineId": item?.ContactLineId ? item?.ContactLineId : "",
                "firstName": item?.firstName,
                "lastName": item?.lastName,
                "email": item?.email,
                "phoneNo": item?.phoneNo,
                "checked": item?.checked,
                "phoneCode": item?.phoneCode,
            }));

            const result = await postApi(VENDOR_SELF_SERVICE, {
                "vendor": formState.vendor,
                "vendorId": user.id,
                "transactionId": transactionList[3].id,
                "roleId": user.RoleId,
                "subsidiary": Number(formState.subsidiary),
                "terms": Number(formState.terms),
                "currency": Number(formState.currency),
                "addressLine1": formState.addressLine1,
                "addressLine2": formState.addressLine2,
                "pincode": formState.pincode,
                "country": formState.country,
                "state": formState.state,
                "city": formState.city,
                "primary_email": formState.pEmail,
                "website": formState.website,
                "selfService": true,
                "logo": typeof attachLogoFile === "string" ? logoObject : fileLogo,
                "contact_information": updated_contact_information,
                "deletedRow": deletedContactId,
                "relevantDoc": relevantDocObject,
                "bankDocument": bankFile,
                "paymentModeId": paymentModeId,
                "bankDetails": updatedValues,
                "vendorBankInfoIds": vendorBankInfoIdArray
            });

            if (result.res.success) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: result.res.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            }

            if (!result.res.success) {
                setSubmitDisable(false);
                setLoader(false);

                if (result.res.message.includes("Email Id")) {
                    setErrors({ pEmail: result.res.message });
                }
                else {
                    let pendingModal = <PendingItem message={result.res.message} />;
                    handleModalData(pendingModal, "md");
                }
            }
        }
    };

    const handlePreview = (url) => {
        const preview = <PreviewPdf url={url} />;
        const encodedText = btoa(url);
    };

    return (
        <>
            <Helmet>
                <title>Vendor Portal || Profile</title>
            </Helmet>

            <Loader isLoading={loading} />

            <div className="container-section">

                <div className="vendor-req-sec">
                    <div className="section-header">
                        <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
                            <BackButton />
                        </button>
                        <h3>{formState.vendorHeader}</h3>
                    </div>
                    <div className="vendor-req-sec-form form-style">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="vendor" className="form-label">
                                    Legal Name<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="vendor"
                                    className="form-control"
                                    placeholder="Enter Legal Name"
                                    name="vendor"
                                    value={formState.vendor}
                                    onChange={handleChange}
                                />
                                {errors.vendor && <span className="cstm-err">{errors.vendor}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="subsidiary" className="form-label">
                                    Subsidiary<span className="required">*</span>
                                </label>
                                <select name="subsidiary" id="subsidiary" className="form-select" value={formState.subsidiary} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {subsidiaryList.map((item, index) => (
                                        <option key={index} value={item.Id}>
                                            {item.Name}
                                        </option>
                                    ))}
                                </select>
                                {errors.subsidiary && <span className="cstm-err">{errors.subsidiary}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="currency" className="form-label">
                                    Currency<span className="required">*</span>
                                </label>
                                <select name="currency" id="currency" className="form-select" value={formState.currency} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {currencyList.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.CurrencyCode}
                                        </option>
                                    ))}
                                </select>
                                {errors.currency && <span className="cstm-err">{errors.currency}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="terms" className="form-label">
                                    Payment Terms<span className="required">*</span>
                                </label>
                                <select name="terms" id="terms" className="form-select" value={formState.terms} disabled onChange={handleChange}>
                                    <option value="">Select</option>
                                    {termsList.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.Term}
                                        </option>
                                    ))}
                                </select>
                                {errors.terms && <span className="cstm-err">{errors.terms}</span>}
                            </div>
                            {/* <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="pEmail" className="form-label">
                                    Email<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="pEmail"
                                    className="form-control"
                                    placeholder="Enter Email"
                                    name="pEmail"
                                    value={formState.pEmail}
                                    onChange={handleChange}
                                />
                                {errors.pEmail && <span className="cstm-err">{errors.pEmail}</span>}
                            </div> */}

                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="pEmail" className="form-label">
                                    Email<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="pEmail"
                                    className="form-control"
                                    placeholder="Enter Email"
                                    name="pEmail"
                                    value={formState.pEmail}
                                    onChange={handleChange}
                                    onBlur={handleEmailBlur}
                                />
                                {errors.pEmail && (
                                    <div className="cstm-err">{errors.pEmail}</div>
                                )}
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="website" className="form-label">
                                    Website
                                </label>
                                <input
                                    type="text"
                                    id="website"
                                    className="form-control"
                                    placeholder="Enter website"
                                    name="website"
                                    value={formState.website}
                                    onChange={handleChange}
                                />
                                {errors.website && <span className="cstm-err">{errors.website}</span>}
                            </div>
                            <div
                                className={`col-${attachLogoFile
                                    ? "xl-4 col-lg-6 col-md-6"
                                    : "xl-3 col-lg-6 col-md-6"
                                    } mb-20 position-relative`}
                            >
                                <label htmlFor="files" className="form-label">
                                    Logo<span className="ms-1 infoIcon"></span>
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="input-group mmm">
                                        <input type="file" className="form-control" name="file" onChange={(e) => handleFileLogo(e)} />
                                    </div>
                                    {errors.file && <span className="cstm-err">{errors.file}</span>}
                                    {typeof attachLogoFile == "string" ? (
                                        <div className="cstm-view-file">
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={handleLogoShow}
                                            >
                                                view
                                            </button>
                                        </div>
                                    ) : Array.isArray(attachLogoFile) &&
                                        attachLogoFile.length > 0 ? (
                                        <div className="cstm-view-file">
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={handleLogoShow}
                                            >
                                                view
                                            </button>

                                        </div>
                                    ) : null}

                                </div>
                            </div>
                            {/* <div
                                className={`col-${attachLogoFile
                                    ? "xl-4 col-lg-6 col-md-6"
                                    : "xl-3 col-lg-6 col-md-6"
                                    } mb-20 position-relative`}
                            >
                                <label htmlFor="files" className="form-label">
                                    Logo<span className="ms-1 infoIcon"></span>
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="input-group mmm">
                                        <input
                                            class="form-control"
                                            type="file"
                                            id="formFile"
                                            onChange={(e) => handleFileLogo(e)}
                                        />
                                    </div>
                                    {typeof attachLogoFile == "string" ? (
                                        <div className="cstm-view-file">
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={handleLogoShow}
                                            >
                                                view
                                            </button>
                                        </div>
                                    ) : Array.isArray(attachLogoFile) &&
                                        attachLogoFile.length > 0 ? (
                                        <div className="cstm-view-file">
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={handleLogoShow}
                                            >
                                                view
                                            </button>

                                        </div>
                                    ) : null}
                                </div>
                                {errors.file && <span className="cstm-err">{errors.file}</span>}
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="billing-adrs-sec">
                    <div className="section-header">
                        <h3>Billing Address</h3>
                    </div>
                    <div className="billing-adrs-sec-form form-style">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="addressLine1" className="form-label">
                                    Address Line 1<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="addressLine1"
                                    className="form-control"
                                    placeholder="Enter address line 1"
                                    name="addressLine1"
                                    value={formState.addressLine1}
                                    onChange={handleChange}
                                />
                                {errors.addressLine1 && <span className="cstm-err">{errors.addressLine1}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="addressLine2" className="form-label">
                                    Address Line 2
                                </label>
                                <input
                                    type="text"
                                    id="addressLine2"
                                    className="form-control"
                                    placeholder="Enter address line 2"
                                    name="addressLine2"
                                    value={formState.addressLine2}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="country" className="form-label">
                                    Country<span className="required">*</span>
                                </label>
                                <select name="country" id="country" className="form-select" value={formState.country} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {countryList.map((item, index) => (
                                        <option key={index}>
                                            {item.name + "," + item.isoCode}
                                        </option>
                                    ))}
                                </select>
                                {errors.country && <span className="cstm-err">{errors.country}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="state" className="form-label">
                                    State<span className="required">*</span>
                                </label>
                                <select name="state" id="state" className="form-select" value={formState.state} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {stateList.map((item, index) => (
                                        <option key={index}>
                                            {item.name + "," + item.isoCode}
                                        </option>
                                    ))}
                                </select>
                                {errors.state && <span className="cstm-err">{errors.state}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="city" className="form-label">
                                    City<span className="required">*</span>
                                </label>
                                <select name="city" id="city" className="form-select" value={formState.city} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {cityList.map((item, index) => (
                                        <option key={index} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                                {errors.city && <span className="cstm-err">{errors.city}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="pincode" className="form-label">
                                    Zip Code<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="pincode"
                                    className="form-control"
                                    placeholder="Enter pincode"
                                    name="pincode"
                                    maxLength={6}
                                    value={formState.pincode}
                                    onChange={handleChange}
                                />
                                {errors.pincode && <span className="cstm-err">{errors.pincode}</span>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-info-sec">
                    <div className="section-header justify-content-between">
                        <div className="d-flex align-items-center">
                            <h3>Contact Information</h3>
                        </div>
                        <button type="button" className="btn btn-sm section-hdr-btn"
                            onClick={handleAdd}
                        >
                            Add More
                        </button>
                    </div>
                    <div className="billing-adrs-sec-form form-style">
                        {rows.map((row, index) => (
                            <div className={row.checked ? 'row primary-card-information' : 'row optional-div-information'} key={index}>
                                <div className="primary-contact">
                                    <Checkbox
                                        {...label}
                                        checked={row.checked}
                                        onChange={() => handleCheckboxChange(row)}
                                        sx={{
                                            "& .MuiSvgIcon-root": { fontSize: 28 }
                                        }}
                                    />{" "}
                                    <label className="ms-1">Primary Contact</label>
                                </div>

                                {!row.checked && <span className="remove-icon" onClick={() => handleRemove(index)}><RemoveIcon /></span>}

                                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                    <label htmlFor="firstName" className="form-label">
                                        First Name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        className="form-control"
                                        placeholder="Enter first name"
                                        name="firstName"
                                        value={row.firstName}
                                        onChange={(e) => handleContactChange(index, e)}
                                    />
                                    {errors.rows &&
                                        errors.rows[index] &&
                                        errors.rows[index].firstName && (
                                            <span className="cstm-err">
                                                {errors.rows[index].firstName}
                                            </span>
                                        )}
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                    <label htmlFor="lastName" className="form-label">
                                        Last Name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        className="form-control"
                                        placeholder="Enter last name"
                                        name="lastName"
                                        value={row.lastName}
                                        onChange={(e) => handleContactChange(index, e)}
                                    />
                                    {errors.rows &&
                                        errors.rows[index] &&
                                        errors.rows[index].lastName && (
                                            <span className="cstm-err">
                                                {errors.rows[index].lastName}
                                            </span>
                                        )}
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                    <label htmlFor="vendor" className="form-label">
                                        Email<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        className="form-control"
                                        placeholder="Enter email"
                                        name="email"
                                        value={row.email}
                                        onChange={(e) => handleContactChange(index, e)}
                                    />
                                    {errors.rows &&
                                        errors.rows[index] &&
                                        errors.rows[index].email && (
                                            <span className="cstm-err">
                                                {errors.rows[index].email}
                                            </span>
                                        )}
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                    <label htmlFor="phoneCode" className="form-label">
                                        Phone Number
                                    </label>
                                    <div className="input-group">
                                        <select name="phoneCode" id="phoneCode" className="inp-mrg-sec" value={row.phoneCode} onChange={(e) => handleContactChange(index, e)}>
                                            {countryList.map((item, index) => (
                                                <option key={index}>
                                                    {item.phonecode}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="text"
                                            id="phoneNo"
                                            className="form-control inp-rgt-radius"
                                            placeholder="Enter phone number"
                                            name="phoneNo"
                                            maxlength={10}
                                            value={row.phoneNo}
                                            onChange={(e) => handleContactChange(index, e)}
                                        />
                                        {errors.rows &&
                                            errors.rows[index] &&
                                            errors.rows[index].phoneNo && (
                                                <span className="cstm-err">
                                                    {errors.rows[index].phoneNo}
                                                </span>
                                            )}
                                        <div data-lastpass-icon-root="true"></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* <div className="cstm-checkbox mb-2">
                        <div className="cstm-checkbox-inner d-flex align-items-center">
                            <input className="form-check-input m-0" type="checkbox"
                                name="attachDoc"
                                id="attachDoc"
                            />
                            <div className="cstm-checkbox-inr-lbl-field d-flex align-items-center">
                                <label htmlFor="attachDoc" className="ms-2">
                                    Check for relevant document
                                </label>
                                <span className="required">*</span>
                            </div>
                        </div>
                    </div>
                    <div className="input-group row mb-3">
                        <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                            <input
                                type="file"
                                id="files"
                                className="form-control"
                                name="files"
                                onChange={handleFileChange}
                            />
                            {errors.relevantDoc && <span className="cstm-err">{errors.relevantDoc}</span>}
                        </div>
                    </div> */}
                    </div >
                </div >

                <div className="contact-info-sec">
                    <div className="section-header">
                        <h3>Finance Information</h3>
                    </div>
                    <div className="form-style">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="paymentMode" className="form-label">Payment Mode<span className="required">*</span></label>
                                <select name="paymentMode" id="paymentMode" className="form-select" value={paymentModeId} onChange={handlePayMode} >
                                    <option value="">Select</option>
                                    {
                                        paymentModeList?.map((item, index) => (
                                            <option key={index} value={item?.id}>{item?.Name}</option>))
                                    }
                                </select>
                                {errors.paymentModeId && <span className="cstm-err">{errors.paymentModeId}</span>}
                            </div>

                            {showBankDetails && bankDetailsList.map((row, index) => (
                                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative" key={index}>
                                    <label htmlFor="" className="form-label">{row.FieldName}<span className="required">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`Enter ${row.FieldName}`}
                                        value={inputValues[row.FieldName]?.fieldValue || ''}
                                        onChange={(e) => handleInputChange(row.FieldName, row.id, e.target.value)}
                                    />
                                    {errors[row.FieldName] && (
                                        <span className="cstm-err">{errors[row.FieldName]}</span>
                                    )}
                                </div>
                            ))}
                            <div className="row">
                                {showBankDetails && paymentModeId === 1 && (
                                    <div className="col-xl-5 col-lg-6 col-md-6">
                                        <label htmlFor="fileInput" className="form-label">
                                            Upload Document<span className="required">*</span>
                                        </label>
                                        <div className="upload-documents-fields">
                                            {bankDocumentObject ? (
                                                <div className="file-view-button">
                                                    {bankDocumentObject.BankDocumentPath ? (
                                                        <span className="view-file-cross-icon">
                                                            <span
                                                                className="mdi mdi-close-circle"
                                                                onClick={handleCrossImage}
                                                            ></span>
                                                            <button className="btn" onClick={handleShow}>
                                                                <span className="d-flex align-items-center">
                                                                    <FilePreviewIcon />
                                                                    <span>Preview</span>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    ) : (
                                                        <div className="input-group mmm">
                                                            <input
                                                                type="file"
                                                                id="files"
                                                                className="form-control input-fields-cstm"
                                                                onChange={handleFileChange}
                                                                multiple
                                                            />
                                                            {errors.bankDocument && (
                                                                <span className="cstm-err">
                                                                    {errors.bankDocument}
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="d-flex align-items-center position-relative w-100 fin-info-upl-field">
                                                    <div className="input-group mmm">
                                                        <input
                                                            type="file"
                                                            id="files"
                                                            className="form-control input-fields-cstm"
                                                            onChange={handleFileChange}
                                                            multiple
                                                        />
                                                        {errors.bankDocument && (
                                                            <span className="cstm-err">
                                                                {errors.bankDocument}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end mb-20">
                            <div className="cstm-success-btn ms-2">
                                <button type="submit" className="btn" onClick={(e) => handleSubmit(e)} disabled={submitDisable}>
                                    {loader ?
                                        <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </>
                                        : "Submit"
                                    }
                                </button>
                            </div>
                        </div>
                        <>
                            <Modal
                                show={logoShow}
                                onHide={handleClose}
                                size="lg"
                                animation={false}
                                className="modal-wrap custom-modal-backdrop"
                                backdrop="static" Pedit
                                keyboard={false}
                            >
                                <div className="file-preview-modal-body">
                                    <div className="">
                                        <span className="cross" onClick={handleLogoClose}>
                                            <img src={cross} alt="" />
                                        </span>
                                        <div className="">
                                            <>
                                                {typeof attachLogoFile == "string" ? (
                                                    <div className="d-flex justify-content-center">
                                                        <div className="view-logo">
                                                            <img
                                                                src={`${BASE_IMAGE_URL}/${attachLogoFile}`}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                ) : Array.isArray(attachLogoFile) &&
                                                    attachLogoFile.length > 0 ? (
                                                    <div className="d-flex justify-content-center">
                                                        <div className="view-logo">
                                                            <img
                                                                src={URL.createObjectURL(
                                                                    new Blob([attachLogoFile[0]])
                                                                )}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex justify-content-center">
                                                        <div className="view-logo">
                                                            <img
                                                                src={
                                                                    "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </>
                        <>
                            <Modal
                                show={show}
                                onHide={handleClose}
                                size="lg"
                                animation={false}
                                className="modal-wrap"
                                backdrop="static"
                                keyboard={false}
                            >
                                <div className="file-preview-modal-title">
                                    <div className="svg-size me-2">
                                        <FileIcon />
                                    </div>
                                    <h3 className="m-0">File Details</h3>
                                </div>
                                <div className="file-preview-modal-body">
                                    <>
                                        <div className="files-card ">
                                            <div className="d-flex align-items-center">
                                                <p>{1}.</p>
                                                <p className="ms-3 me-3 break-all">{bankDocumentObject.BankDocumentName}</p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <span className="me-2">
                                                    <Link
                                                        to={`${BASE_IMAGE_URL}/${bankDocumentObject.BankDocumentPath}`}
                                                        download
                                                        target="_blank"
                                                    >
                                                        <DownloadSvg />
                                                    </Link>
                                                </span>
                                                <span
                                                    onClick={() =>
                                                        handlePreview(`${BASE_IMAGE_URL}/${bankDocumentObject.BankDocumentPath}`)
                                                    }
                                                >
                                                    <Link
                                                        to={`/preview-pdf/${btoa(bankDocumentObject.BankDocumentPath)}`}
                                                        target="_blank"
                                                    >
                                                        <span className="eye-icon">
                                                            <img src={eyeicon} alt="" />
                                                        </span>
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                </div>
                                <hr className="m-0" />
                                <div className=" d-flex justify-content-end  file-preview-modal-footer">
                                    <div className="cstm-dngr-btn ">
                                        <button type="button" onClick={handleClose} className="btn">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                        </>
                    </div >
                </div >

            </div>

        </>
    );
};

export default EditProfile;
