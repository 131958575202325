import React, { useEffect, useState } from 'react'
import { getApi } from '../../../utils/services';
import { BILLS_API } from '../../../utils/apiRoutes';
import { useFeedsColumnIndividualBills } from '../../../utils/helpers/tableHelpers/useFeedColumnIndividualBill';
import IndividualBill from '../../Table/IndividualBill';
import { Helmet } from "react-helmet";
import Loader from '../../common/Loader';
import { VENDORID } from '../../../utils/constant';

const IndividualBillList = () => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const [individualBill, setIndividualBills] = useState([]);
    const [loader, setLoader] = useState(false);

    const handlerIndividualBillList = async () => {
        try {
            let routeName
            if (user.RoleId === VENDORID) {
                routeName = `${BILLS_API}?billType=1&vendorId=${user.id}`
            }
            else {
                routeName = `${BILLS_API}?billType=1`
            }
            setLoader(true)
            let result = await getApi(routeName);
            result = result.res.bill_list;
            setLoader(false)
            setIndividualBills(result)
        } catch (error) {
            console.log(error);
        }
    }

    const individualBillcolumns = useFeedsColumnIndividualBills(handlerIndividualBillList)

    useEffect(() => {
        handlerIndividualBillList()
    }, [])

    return (
        <>
            <Helmet>
                <title>Vendor Portal || Standalone Bill List</title>
            </Helmet>
            <Loader isLoading={loader} />
            <div className="container-section">
                <div className="section-header">
                    <h3>Standalone Bill</h3>
                </div>
                <div className="form-style">
                    <div className="">
                        <IndividualBill columns={individualBillcolumns} data={individualBill} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default IndividualBillList