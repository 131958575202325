import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/services";
import { BILLS_API } from "../../../utils/apiRoutes";
import { useFeedsColumnBills } from "../../../utils/helpers/tableHelpers/useFeedColumnBills";
import { FINANCE_PENDING } from "../../../utils/constant";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import DashboardCard from '../../common/DashboardCard';
import { useFeedsColumnIndividualBills } from "../../../utils/helpers/tableHelpers/useFeedColumnIndividualBill";

const FinanceDashboard = () => {
  const [bills, setBills] = useState([]);
  const [loader, setLoader] = useState(false);
  const [individualBill, setIndividualBills] = useState([]);

  const handlerIndividualBillList = async () => {
    try {
      setLoader(true)
      let result = await getApi(`${BILLS_API}?billType=1`);
      result = result.res.bill_list.filter((item) => item.Status === FINANCE_PENDING);
      setLoader(false)
      setIndividualBills(result)
    } catch (error) {
      console.log(error);
    }
  }

  const individualbillcolumns = useFeedsColumnIndividualBills(handlerIndividualBillList)

  const handlerBillList = async () => {
    try {
      setLoader(true);
      let result = await getApi(`${BILLS_API}?billType=0`);
      result = result.res.bill_list.filter(
        (item) => item.Status === FINANCE_PENDING
      );
      setLoader(false);
      setBills(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handlerBillList();
    handlerIndividualBillList()
  }, []);

  const billcolumns = useFeedsColumnBills(handlerBillList);

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Home</title>
      </Helmet>

      <Loader isLoading={loader} />

      <>
        <DashboardCard title={"Bills"} columns={billcolumns} data={bills} mt="mt-4" fn="" />
        <DashboardCard title={"Standalone Bill"} columns={individualbillcolumns} data={individualBill} mt="mt-4" fn="" />
      </>

    </>
  );
};

export default FinanceDashboard;
