import ReactApexChart from "react-apexcharts";

function MultipleBarChart({categories = [], data = [], title}) {
const chartData = {
    series: data,
      options : {
        chart: {
            toolbar: {
                show: false
              },
            type: 'bar',
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top'
              }
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "K";
            },
            offsetY: -20,
            style: {
              fontSize: '10px',
              colors: ["#304758"]
            }
          },
          
          xaxis: {
            categories: categories,
            position: 'bottom',
          },
          yaxis: {
            title: {
              text: 'Amount',
            },
            labels: {
              formatter: function (val) {
                return val + "K";
              }
            }
          },
          title: {
            text: title
          }
        }
    }
return (
    <div id="Barchart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
    </div>
);
      }
export default MultipleBarChart;










    



