import { useState } from 'react';

const AddTDSValidation = () => {
    const [errors, setErrors] = useState({});

    const validateForm = (tdsAmount) => {

        let errors = {};
        let formIsValid = true;
        
        if (!tdsAmount) {
            formIsValid = false;
            errors.tdsAmount = "Enter TDS Amount !"
        }

        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default AddTDSValidation;
