import React from "react";
import icon from "../../assets/image/streamlogo.png";
import DropDown from "./DropDown";

const Header = ({ setTogglerBar, togglerBar }) => {
    return (
        <nav className="navbar header navbar-expand-sm">
            <div className="d-flex justify-content-between align-items-center w-100">
                <div className="header-logo ">
                    <img src={icon} alt="" />
                </div>
                <div className="d-flex align-items-center">
                    <ul className="navbar-nav ">
                        <li className="nav-item nav-logout user-name">
                            <DropDown />
                        </li>
                    </ul>
                    <button
                        className="navbar sidebar-humburger d-lg-none align-self-center border-0"
                        type="button"
                        id="togglebtn"
                        data-toggle="offcanvas"
                        onClick={() => setTogglerBar(!togglerBar)}
                    >
                        <span className="mdi mdi-menu"></span>
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Header;