import React, { useEffect, useState } from 'react'
import { getApi } from '../../../utils/services';
import { BILLS_API, CONTRACT_LIST, VENDOR } from '../../../utils/apiRoutes';
import { useFeedsColumnsVendor } from '../../../utils/helpers/tableHelpers/useFeedsColumnsVendor';
import { useFeedsColumnContracts } from '../../../utils/helpers/tableHelpers/useFeedColumnContracts';
import { useFeedsColumnBills } from '../../../utils/helpers/tableHelpers/useFeedColumnBills';
import DashboardCard from '../../common/DashboardCard';
import { VENDOR_VP_PENDING } from '../../../utils/constant';
import { Helmet } from "react-helmet";
import Loader from '../../common/Loader';
import { useFeedsColumnIndividualBills } from '../../../utils/helpers/tableHelpers/useFeedColumnIndividualBill';

const VpDashboard = () => {
  const [vendors, setVendor] = useState([]);
  const [purchaseContract, setPurchaseContarct] = useState([]);
  const [bills, setBills] = useState([]);
  const [individualBill, setIndividualBills] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleVendorList = async () => {
    try {
      setLoader(true)
      const result = await getApi(VENDOR);
      const vendors = result.res.vendor_list.filter((item) => item.Status === VENDOR_VP_PENDING);
      setLoader(false)
      setVendor(vendors)
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useFeedsColumnsVendor(handleVendorList);

  const handlerPurchaseContractList = async () => {
    try {
      setLoader(true)
      let result = await getApi(CONTRACT_LIST);
      result = result.res.contract_list.filter((item) => item.Status === VENDOR_VP_PENDING);
      setLoader(false)
      setPurchaseContarct(result)
    } catch (error) {
      console.log(error);
    }
  }

  const purchasecolumns = useFeedsColumnContracts(handlerPurchaseContractList)

  const handlerBillList = async () => {
    try {
      setLoader(true)
      let result = await getApi(`${BILLS_API}?billType=0`);
      result = result.res.bill_list.filter((item) => item.Status === VENDOR_VP_PENDING);
      setLoader(false)
      setBills(result)
    } catch (error) {
      console.log(error);
    }
  }

  const billcolumns = useFeedsColumnBills(handlerBillList)

  const handlerIndividualBillList = async () => {
    try {
      setLoader(true)
      let result = await getApi(`${BILLS_API}?billType=1`);
      result = result.res.bill_list.filter((item) => item.Status === VENDOR_VP_PENDING);
      setLoader(false)
      setIndividualBills(result)
    } catch (error) {
      console.log(error);
    }
  }

  const individualbillcolumns = useFeedsColumnIndividualBills(handlerIndividualBillList)

  useEffect(() => {
    handleVendorList();
    handlerPurchaseContractList()
    handlerBillList()
    handlerIndividualBillList()
  }, []);

  return (

    <>
      <Helmet>
        <title>Vendor Portal || Home</title>
      </Helmet>

      {loader ?
        <Loader /> :
        <>
          <DashboardCard title={"Vendors"} columns={columns} data={vendors} mt="m-0" fn="" />
          <DashboardCard title={"Purchase Contracts"} columns={purchasecolumns} data={purchaseContract} mt="mt-4" fn="" />
          <DashboardCard title={"Bills"} columns={billcolumns} data={bills} mt="mt-4" fn="" />
          <DashboardCard title={"Standalone Bill"} columns={individualbillcolumns} data={individualBill} mt="mt-4" fn="" />
        </>
      }
    </>

  )
}

export default VpDashboard















