import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
}));

const sizes = {
  xl: 'w-75',
  lg: 'w-50',
  md: 'w-40',
  cs: 'w-45',
  sm: 'w-25',
};

const CommonModal = ({
  isModalOpen,
  modalData,
  modalSize,
  closeModal,
}) => {
  const classes = useStyles();

  const handleClose = (event) => {
    if (event.target.id === 'custom-modal') {
      closeModal();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <div
            id="custom-modal"
            className={classNames({
              [classes.paper]: true,
              [sizes[modalSize]]: true,
            })}
          >
            {modalData}
            {/* <button id="closeButton" onClick={closeModal}>
              Close
            </button> */}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CommonModal;
