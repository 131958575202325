import React from 'react'
import FinaceIcon from  "../image/tdsicon.png"

const FinanceApporveIcon = () => {
  return (
    <div>
        <img src={FinaceIcon} alt="" />
    </div>
  )
}

export default FinanceApporveIcon