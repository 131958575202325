import React from 'react'

const DeleteIcon = () => {
    return (
        <div>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="30" rx="6" fill="#F80000" />
                <path d="M21.4661 10.9349C21.4517 10.9349 21.4302 10.9349 21.4086 10.9349C17.6101 10.5543 13.8188 10.4107 10.0634 10.7913L8.59855 10.9349C8.29697 10.9636 8.03129 10.7482 8.00257 10.4466C7.97385 10.145 8.18926 9.88651 8.48366 9.85779L9.94849 9.71418C13.7686 9.32643 17.6389 9.47722 21.5163 9.85779C21.8107 9.88651 22.0262 10.1522 21.9974 10.4466C21.9759 10.7266 21.7389 10.9349 21.4661 10.9349Z" fill="white" />
                <path d="M12.4913 10.2097C12.4626 10.2097 12.4339 10.2097 12.398 10.2025C12.1108 10.1523 11.9097 9.87221 11.96 9.58499L12.118 8.64434C12.2328 7.95501 12.3908 7 14.0638 7H15.9451C17.6254 7 17.7834 7.99091 17.8911 8.65152L18.049 9.58499C18.0993 9.87939 17.8982 10.1594 17.611 10.2025C17.3166 10.2528 17.0366 10.0517 16.9935 9.7645L16.8355 8.83103C16.735 8.20633 16.7135 8.08426 15.9523 8.08426H14.071C13.3099 8.08426 13.2956 8.18479 13.1879 8.82385L13.0227 9.75732C12.9796 10.023 12.7498 10.2097 12.4913 10.2097Z" fill="white" />
                <path d="M17.3088 22.4381H12.6989C10.1929 22.4381 10.0924 21.0523 10.0134 19.9321L9.54669 12.7013C9.52515 12.4069 9.75492 12.1484 10.0493 12.1269C10.3509 12.1125 10.6022 12.3351 10.6238 12.6295L11.0905 19.8603C11.1695 20.9518 11.1982 21.361 12.6989 21.361H17.3088C18.8168 21.361 18.8455 20.9518 18.9173 19.8603L19.384 12.6295C19.4056 12.3351 19.6641 12.1125 19.9585 12.1269C20.2529 12.1484 20.4826 12.3997 20.4611 12.7013L19.9944 19.9321C19.9154 21.0523 19.8148 22.4381 17.3088 22.4381Z" fill="white" />
                <path d="M16.1961 18.4887H13.805C13.5106 18.4887 13.2665 18.2446 13.2665 17.9502C13.2665 17.6558 13.5106 17.4116 13.805 17.4116H16.1961C16.4905 17.4116 16.7347 17.6558 16.7347 17.9502C16.7347 18.2446 16.4905 18.4887 16.1961 18.4887Z" fill="white" />
                <path d="M16.7988 15.6166H13.2086C12.9142 15.6166 12.67 15.3725 12.67 15.0781C12.67 14.7837 12.9142 14.5396 13.2086 14.5396H16.7988C17.0932 14.5396 17.3374 14.7837 17.3374 15.0781C17.3374 15.3725 17.0932 15.6166 16.7988 15.6166Z" fill="white" />
            </svg>

        </div>
    )
}

export default DeleteIcon