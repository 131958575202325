import React from "react";
import ReactApexChart from "react-apexcharts";

function RadialCircleChart({ title, data, label, total }) {
  const chartData = {
    series: data,
    options: {
      chart: {
        height: 390,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '50%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: true, // Show value
              fontSize: '22px',
              formatter: function (val) {
                return val + `%`; // Display the value of each slice
              }
            },
            total: {
              show: true,
              label: 'Total',
              formatter: function (w) {
                return total; // Display the total
              }
            }
          }
        }
      },
      labels: label,
      legend: {
        show: true,
        floating: true,
        fontSize: '12px',
        position: 'left',
        offsetX: 55,
        offsetY: -0,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0
        },
        formatter: function (seriesName, opts) {
          return seriesName;
        },
        itemMargin: {
          vertical: 3
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }]
    },
  };

  return (
    <div id="RadialCircleChart" className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>{title}</h5>
      </div>
      <ReactApexChart options={chartData.options} series={chartData.series} type="radialBar" height={350} />
    </div>
  );
}

export default RadialCircleChart;