import React, { useEffect, useState, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import BackButton from "../../../assets/image/BackButton";
import pinicon from "../../../assets/image/pinIcon.png";
import { ModalContext } from "../../../utils/Context";
import PreviewIcon from "../../../assets/image/PreviewIcon";
import { BASE_IMAGE_URL, getApi } from "../../../utils/services";
import { VIEW_VENDOR } from "../../../utils/apiRoutes";
import StatusViewIcon from "../../../assets/image/StatusViewIcon";
import Bankaccordions from "./BankAccordion";
import Modal from "react-bootstrap/Modal";
import {
  ADMIN_MANAGER_ID,
  VENDORID,
  VENDOR_APPROVE,
  VENDOR_MANAGER_PENDING,
  VENDOR_REJECT,
  VENDOR_VP_PENDING,
  VP_ID,
} from "../../../utils/constant";
import ApproveVendor from "../../modal/ApproveVendor";
import RejectVendor from "../../modal/RejectVendor";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import Integration from "./Integration";

const ViewVendor = () => {
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [ViewVendordetails, setViewVendorDetails] = useState({});
  const [Viewhistory, setViewhistory] = useState({});
  const [loader, setLoader] = useState(false);
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;
  const [array, setArray] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let data = {
    id: Number(id),
    bankInfoFlag: ViewVendordetails.bankInfoFlag,
    vendorBankInfoIds: array,
    selfService: ViewVendordetails.selfService,
  };

  const navigate = useNavigate();

  const handleViewVendordetails = async () => {
    try {
      setLoader(true);
      const result = await getApi(`${VIEW_VENDOR}/${id}`);
      setLoader(false);
      setViewVendorDetails(result?.res.vendor_details);
      setViewhistory(result.res.vendorHistory);

      const array = result?.res.vendor_details.BankInfo.map(
        (item) => item.VendorBankInfoId
      );
      setArray(array);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleViewVendordetails();
  }, [id]);

  const handleApprove = () => {
    let app = (
      <ApproveVendor handleVendorList={handleViewVendordetails} data={data} />
    );
    handleModalData(app, "md");
  };

  const handleReject = () => {
    let rej = (
      <RejectVendor data={data} handleVendorList={handleViewVendordetails} />
    );
    handleModalData(rej, "md");
  };

  const handleProfileEdit = () => {
    navigate(`/edit-profile`);
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || View Vendor</title>
      </Helmet>

      <Loader isLoading={loader} />

      <div className="container-section">
        <div className="cstm-vendor-details">
          <div className="section-header section-header-btn-box flex-wrap">
            <div className="d-flex align-items-center ">
              <button
                onClick={() => navigate(-1)}
                className="back-viewbtn me-2"
              >
                <div className="svgsize">
                  <BackButton />
                </div>
              </button>
              <h3 className="me-2">{ViewVendordetails.LegalName}</h3>
            </div>

            <div className="d-flex align-items-center cstm-prvbtn-box">
              <span>
                {ViewVendordetails.RelevantDocName && (
                  <div className="cstm-preview-btn">
                    <button type="button" className="btn " onClick={handleShow}>
                      <PreviewIcon />
                      <span className="ps-2">Preview</span>
                    </button>
                  </div>
                )}
              </span>
              <div className="d-flex cstm-editbtn-container">
                {user?.RoleId === VENDORID &&
                  ((ViewVendordetails.Status === VENDOR_APPROVE && ViewVendordetails.bankInfoFlag === 1 && (!ViewVendordetails.selfService || ViewVendordetails.selfService)) || (ViewVendordetails.Status === VENDOR_REJECT && ViewVendordetails.bankInfoFlag === 1 && ViewVendordetails.selfService)) && (
                    <div className="cstm-edit-btn ms-2">
                      <button
                        onClick={handleProfileEdit}
                        className="btn"
                        type="button"
                      >
                        <>
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.224 3.05439L2.50361 15.46C2.06107 15.9311 1.6328 16.859 1.54714 17.5014L1.01894 22.1267C0.83336 23.797 2.03251 24.939 3.68849 24.6535L8.28525 23.8683C8.92765 23.7541 9.82706 23.283 10.2696 22.7977L21.9899 10.3921C24.0171 8.25072 24.9307 5.80959 21.7758 2.82598C18.6351 -0.129084 16.2511 0.913038 14.224 3.05439Z"
                              stroke="white"
                              stroke-width="1.75"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.2671 5.12378C12.8809 9.06386 16.0787 12.076 20.0473 12.4757"
                              stroke="white"
                              stroke-width="1.75"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </>
                        <span className="ps-2">Edit</span>
                      </button>
                    </div>
                  )}

                {ViewVendordetails?.LogoPath && (
                  <div className="vendor-logo ms-2">
                    <img
                      className="m-0"
                      src={`${BASE_IMAGE_URL}/${ViewVendordetails?.LogoPath}`}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="form-style">
            <div className="data-info-box">
              <div className="imgpinicon">
                <img src={pinicon} alt=""></img>
              </div>
              <div className="data-info">
                <div className="data-group">
                  <div className="data-item">
                    <span className="label">Legal Name</span>
                    <span className="semicolon">:</span>
                    <span className={Viewhistory?.LegalName === "changed" ? "updated-value" : "value"}>
                      {ViewVendordetails.LegalName}
                    </span>
                  </div>
                  <div className="data-item">
                    <span className="label">Subsidiary</span>
                    <span className="semicolon">:</span>
                    <span className={Viewhistory?.Name === "changed" ? "updated-value" : "value"}>
                      {ViewVendordetails?.Name}</span>
                  </div>
                  <div className="data-item">
                    <span className="label">Currency</span>
                    <span className="semicolon">:</span>
                    <span className={Viewhistory?.CurrencyCode === "changed" ? "updated-value" : "value"}>
                      {ViewVendordetails?.CurrencyCode}
                    </span>
                  </div>
                  <div className="data-item">
                    <span className="label">Payment Terms</span>
                    <span className="semicolon">:</span>
                    <span className="value">
                      {ViewVendordetails?.Term}</span>
                  </div>
                  <div className="data-item">
                    <span className="label">Status</span>
                    <span className="semicolon">:</span>
                    <span className="value d-flex align-items-center">
                      <StatusViewIcon status={ViewVendordetails?.StatusName} />
                      <span >{ViewVendordetails?.StatusName}</span>
                    </span>
                  </div>
                  <div className="data-item">
                    <span className="label">Email</span>
                    <span className="semicolon">:</span>
                    <span className={Viewhistory?.EmailId === "changed" ? "updated-value" : "value"}>
                      {ViewVendordetails.EmailId}</span>
                  </div>
                  {ViewVendordetails?.Website && (
                    <div className="data-item">
                      <span className="label">Website</span>
                      <span className="semicolon">:</span>
                      <span className={Viewhistory?.Website === "changed" ? "updated-value" : "value"}>
                        {ViewVendordetails.Website}</span>
                    </div>
                  )}
                  <div className="data-item">
                    <span className="label">Address Line 1</span>
                    <span className="semicolon">:</span>
                    <span className={Viewhistory?.AddressLine1 === "changed" ? "updated-value" : "value"}>
                      {ViewVendordetails.AddressLine1}
                    </span>
                  </div>

                  {ViewVendordetails?.AddressLine2 && (
                    <div className="data-item">
                      <span className="label">Address Line 2</span>
                      <span className="semicolon">:</span>
                      <span className={Viewhistory?.AddressLine2 === "changed" ? "updated-value" : "value"}>
                        {ViewVendordetails.AddressLine2}
                      </span>
                    </div>
                  )}
                  <div className="data-item">
                    <span className="label">Country</span>
                    <span className="semicolon">:</span>
                    <span className={Viewhistory?.Country === "changed" ? "updated-value" : "value"}>
                      {ViewVendordetails.Country}</span>
                  </div>
                  <div className="data-item">
                    <span className="label">State</span>
                    <span className="semicolon">:</span>
                    <span className={Viewhistory?.State === "changed" ? "updated-value" : "value"}>
                      {ViewVendordetails.State}</span>
                  </div>
                  <div className="data-item">
                    <span className="label">City</span>
                    <span className="semicolon">:</span>
                    <span className={Viewhistory?.City === "changed" ? "updated-value" : "value"}>
                      {ViewVendordetails.City}</span>
                  </div>
                  <div className="data-item">
                    <span className="label">Zip Code</span>
                    <span className="semicolon">:</span>
                    <span className={Viewhistory?.PostalCode === "changed" ? "updated-value" : "value"}>
                      {ViewVendordetails.PostalCode}
                    </span>
                  </div>

                  {ViewVendordetails?.RejectionReason && (
                    <div className="data-item">
                      <span className="label">Rejection Reason</span>
                      <span className="semicolon">:</span>
                      <span className="value">
                        {ViewVendordetails?.RejectionReason}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="cstm-view-vendor">
            <div className="section-header">
              <h3>Contact Information</h3>
            </div>
          </div>

          <div className="form-style">
            <div className="wrapper mt-2">
              {ViewVendordetails?.ContactInfo?.map((item, index) => (
                <div className={item.IsPrimary ? "contact-profile-Card active-profile" : "contact-profile-Card"} key={index}>
                  <div className="d-flex align-items-center">
                    {item.IsPrimary && (
                      <div className="primary-flag">
                        <div className="d-flex align-items-center">
                          <div className="p-1 check">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="16"
                              viewBox="0 0 18 16"
                              fill="none"
                            >
                              <path
                                d="M15.3738 0L7.12617 11.0464L2.25 6.09643L0 8.38214L7.49883 16L18 2.28571L15.3738 0Z"
                                fill="#24A148"
                              />
                            </svg>
                          </div>
                          <div className="title">Primary Contact</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex">
                    <div className="">
                      <div className="d-flex align-items-center mb-20">
                        <span className="label-div">First Name</span>
                        <span className="semicolon-div">:</span>
                        <span className={Viewhistory?.ContactInfo && Viewhistory?.ContactInfo[index]?.FirstName === "changed" ? "updated-value" : "value"}>
                          {item?.FirstName}
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-20">
                        <span className="label-div">Last Name</span>
                        <span className="semicolon-div">:</span>
                        <span className={Viewhistory?.ContactInfo && Viewhistory?.ContactInfo[index]?.LastName === "changed" ? "updated-value" : "value"}>
                          {item?.LastName}</span>
                      </div>
                      <div className="d-flex align-items-center mb-20">
                        <span className="label-div">Email</span>
                        <span className="semicolon-div">:</span>
                        <span className={Viewhistory?.ContactInfo && Viewhistory?.ContactInfo[index]?.Email === "changed" ? "updated-value text-break" : "value text-break"}>
                          {item?.Email}</span>
                      </div>
                      {item?.Phone && (
                        <div className="d-flex align-items-center mb-20">
                          <span className="label-div">Phone Number</span>
                          <span className="semicolon-div">:</span>
                          <span className={Viewhistory?.ContactInfo && Viewhistory?.ContactInfo[0]?.Phone === "changed" ? "updated-value" : "value"}>
                            +{item?.PhoneCode} {item?.Phone}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <>
            {ViewVendordetails?.ExternalId && (
              <div className="">
                <Integration viewData={ViewVendordetails} />
              </div>
            )}
          </>
          <>
            {ViewVendordetails?.bankInfoFlag === 1 && (
              <div className="">
                <Bankaccordions viewData={ViewVendordetails} viewHistory={Viewhistory} />
              </div>
            )}
          </>
        </div>

        {((user?.RoleId === ADMIN_MANAGER_ID &&
          ViewVendordetails.Status === VENDOR_MANAGER_PENDING) ||
          (user?.RoleId === VP_ID &&
            ViewVendordetails.Status === VENDOR_VP_PENDING)) && (
            <div className="form-style">
              <div className="d-flex justify-content-end">
                <span className="cstm-dngr-btn me-2" onClick={handleReject}>
                  <button type="button" className="btn">
                    Reject
                  </button>
                </span>

                <span onClick={handleApprove} className="cstm-success-btn">
                  <button className="btn">Approve</button>
                </span>
              </div>
            </div>
          )}

        <div>
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            animation={false}
            className="modal-wrap"
            backdrop="static"
            keyboard={false}
          >
            <div className="file-preview-modal-title">
              <div className="svg-size me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                >
                  <path
                    d="M9 7V1.5L14.5 7M2 0C0.89 0 0 0.89 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V6L10 0H2Z"
                    fill="white"
                  />
                </svg>
              </div>
              <h3 className="m-0">File Details</h3>
            </div>
            <div className="file-preview-modal-body">
              <>
                <div className="files-card ">
                  <div className="d-flex align-items-center">
                    <p>{1}.</p>
                    <p className="ms-3 files-card-name">
                      {ViewVendordetails.RelevantDocName}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="me-2">
                      <Link
                        to={`${BASE_IMAGE_URL}/${ViewVendordetails.RelevantDocPath}`}
                        download
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="31"
                          height="30"
                          viewBox="0 0 31 30"
                          fill="none"
                        >
                          <rect
                            width="30.4351"
                            height="30"
                            rx="6"
                            fill="#F7CB73"
                          />
                          <path
                            d="M15 19L10 14L11.4 12.55L14 15.15V7H16V15.15L18.6 12.55L20 14L15 19ZM9 23C8.45 23 7.979 22.804 7.587 22.412C7.195 22.02 6.99934 21.5493 7 21V18H9V21H21V18H23V21C23 21.55 22.804 22.021 22.412 22.413C22.02 22.805 21.5493 23.0007 21 23H9Z"
                            fill="white"
                          />
                        </svg>
                      </Link>
                    </span>
                    <span>
                      <Link
                        to={`/preview-pdf/${btoa(
                          ViewVendordetails.RelevantDocPath
                        )}`}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="31"
                          height="30"
                          viewBox="0 0 31 30"
                          fill="none"
                        >
                          <rect
                            width="30.4351"
                            height="30"
                            rx="6"
                            fill="#58BAE4"
                          />
                          <path
                            d="M15.2785 9C10.1451 9 7.10156 15 7.10156 15C7.10156 15 10.1451 21 15.2785 21C20.2901 21 23.3336 15 23.3336 15C23.3336 15 20.2901 9 15.2785 9ZM15.2176 11C17.4698 11 19.2756 12.8 19.2756 15C19.2756 17.22 17.4698 19 15.2176 19C12.9857 19 11.1596 17.22 11.1596 15C11.1596 12.8 12.9857 11 15.2176 11ZM15.2176 13C14.1016 13 13.1886 13.9 13.1886 15C13.1886 16.1 14.1016 17 15.2176 17C16.3335 17 17.2466 16.1 17.2466 15C17.2466 14.8 17.1654 14.62 17.1248 14.44C16.9625 14.76 16.6379 15 16.2321 15C15.664 15 15.2176 14.56 15.2176 14C15.2176 13.6 15.4611 13.28 15.7857 13.12C15.6031 13.06 15.4205 13 15.2176 13Z"
                            fill="white"
                          />
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
              </>
            </div>
            <hr className="m-0" />
            <div className="d-flex  justify-content-end file-preview-modal-footer">
              <div className="cstm-dngr-btn">
                <button type="button" onClick={handleClose} className="btn">
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>

    </>
  );
};

export default ViewVendor;
