import { useState } from 'react';
import { PASSWORDVALIDATOR } from '../utils/constant';


const ResetPasswordFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (password,confpassword) => {
   let _errors = {};
   let formIsValid = true;
  
    if(password && password.length <8){
      formIsValid = false;
      _errors["newpassword"] = "Password must have at least 8 characters!";
    }

    else if (!PASSWORDVALIDATOR.test(password)) {
      formIsValid = false;
      _errors["newpassword"] =
        "New password does not matched with password policy!";
    }
    if (!password) {
      formIsValid = false;
      _errors["newpassword"] = "Enter New Password!";
    }
    if (!confpassword) {
      formIsValid = false;
      _errors["confirmpassword"] = "Enter Confirm Password!";
    }
    
    if( confpassword && password && password !==confpassword){
      formIsValid = false;
      _errors["confirmpassword"] = "New Password and Confirm Password is not same!";
    }
    
    setErrors(_errors)
    return formIsValid;

   
  };

  return { errors,setErrors, validateForm };
};

export default ResetPasswordFormValidation;