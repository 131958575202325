import { useState } from 'react';

const EditProfileValidation = () => {
    const [errors, setErrors] = useState({});

    // const isValidRelevantDoc = (file) => {
    //     const allowedTypes = ["application/pdf"];
    //     return allowedTypes.includes(file.type);
    // };

    const isValidLogoType = (file) => {
        const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        return allowedTypes.includes(file.type);
    };

    const isValidBankDocument = (file) => {
        const allowedTypes = ["application/pdf"];
        return allowedTypes.includes(file.type);
    };

    const MAX_FILE_SIZE = 2 * 1024 * 1024;

    const validateForm = (vendor, subsidiary, currency, terms, pEmail, file, website, addressLine1, country, state, city, pincode, rows, paymentModeId, inputValues, bankDocumentObject, bankDocument) => {

        let errors = {};
        errors.rows = []
        let formIsValid = true;

        if (!vendor.trim()) {
            formIsValid = false;
            errors.vendor = "Enter Legal Name"
        }
        if (!subsidiary) {
            formIsValid = false;
            errors.subsidiary = "Select Subsidiary";
        }
        if (!currency) {
            formIsValid = false;
            errors.currency = "Select currency";
        }
        if (!terms) {
            formIsValid = false;
            errors.terms = "Select Terms";
        }
        if (!pEmail) {
            formIsValid = false;
            errors.pEmail = "Enter Email";
        }
        else {
            const emailRegex = /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
            if (!emailRegex.test(pEmail)) {
                formIsValid = false;
                errors.pEmail = "Enter valid format";
            }
        }
        if (website) {
            const websiteRegex = /^(http|https)?:\/\/[a-zA-Z0-9-\.]+\.[a-z]{2,4}/;
            if (!websiteRegex.test(website)) {
                formIsValid = false;
                errors.website = "Enter valid format";
            }
        }
        if (!addressLine1.trim()) {
            formIsValid = false;
            errors.addressLine1 = "Enter AddressLine1";
        }
        if (!country) {
            formIsValid = false;
            errors.country = "Select Country";
        }
        if (!state) {
            formIsValid = false;
            errors.state = "Select State";
        }
        if (!city) {
            formIsValid = false;
            errors.city = "Select City";
        }
        if (!pincode) {
            formIsValid = false;
            errors.pincode = "Enter Pincode";
        }
        else {
            if (pincode.length < 6) {
                formIsValid = false;
                errors.pincode = "Invalid Pincode";
            }
        }
        if (file) {
            for (const item of file) {
                if (!isValidLogoType(item)) {
                    formIsValid = false;
                    errors.file = "Only PNG,JPEG,JPG files are allowed !";
                    break;
                }
                if (item.size > MAX_FILE_SIZE) {
                    formIsValid = false;
                    errors.file = "Maximum allowed file size is 2MB !";
                    break;
                }
            }
        }
        // if (relevantDoc.length === 0) {
        //     formIsValid = false;
        //     errors.relevantDoc = "Please upload a document !";
        // }
        // else {
        //     for (const item of relevantDoc) {
        //         if (!isValidRelevantDoc(item)) {
        //             formIsValid = false;
        //             errors.relevantDoc = "Only PDF is allowed !";
        //             break;
        //         }
        //         if (item.size > MAX_FILE_SIZE) {
        //             formIsValid = false;
        //             errors.relevantDoc = "Maximum allowed file size is 2MB";
        //             break;
        //         }
        //     }
        // }
        rows.forEach((item, index) => {
            const rowsErrors = {};

            if (!item.firstName.trim()) {
                formIsValid = false;
                rowsErrors.firstName = "Enter First Name";
            }
            if (!item.lastName.trim()) {
                formIsValid = false;
                rowsErrors.lastName = "Enter Last Name";
            }
            if (!item.email) {
                formIsValid = false;
                rowsErrors.email = "Enter Email";
            }
            else {
                const emailRegex = /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
                if (!emailRegex.test(item.email)) {
                    formIsValid = false;
                    rowsErrors.email = "Enter valid format";
                }
            }
            if (item.phoneNo && item.phoneNo.length < 10) {
                formIsValid = false;
                rowsErrors.phoneNo = "Invalid Phone Number";
            }
            if (Object.keys(rowsErrors).length > 0) {
                errors.rows[index] = rowsErrors;
            }
            return rowsErrors;
        });

        for (const fieldName in inputValues) {
            if (inputValues.hasOwnProperty(fieldName)) {
                const { fieldValue } = inputValues[fieldName];

                if (!fieldValue.trim()) {
                    formIsValid = false;
                    errors[fieldName] = `Enter ${fieldName}`;
                } else {
                    switch (fieldName) {
                        case 'Bank Name':
                            if (!/^[a-zA-Z\s]+$/.test(fieldValue)) {
                                formIsValid = false;
                                errors[fieldName] = 'Only alphabetic keywords allowed';
                            }
                            break;
                        case 'Account Number':
                            if (fieldValue.length < 5 || fieldValue.length > 25) {
                                formIsValid = false;
                                errors[fieldName] = 'Account number should have 5-25 digits';
                            }
                            break;
                        case 'IFSC Code':
                            if (fieldValue.length !== 11) {
                                formIsValid = false;
                                errors[fieldName] = 'IFSC Code should have 11 digits';
                            }
                            break;
                        default:
                            break;
                    }
                }
            }

        }
        if (!paymentModeId) {
            formIsValid = false;
            errors.paymentModeId = "Select Payment Mode";
        }
        if (paymentModeId === 1) {
            if (!bankDocumentObject && bankDocument.length === 0) {
                formIsValid = false;
                errors.bankDocument = "Please upload a document !";
            }
            else if (!bankDocumentObject?.BankDocumentName && bankDocument.length === 0) {
                formIsValid = false;
                errors.bankDocument = "Please upload a document !";
            } else if (bankDocument.length > 0) {
                if (bankDocument) {
                    for (const item of bankDocument) {
                        if (!isValidBankDocument(item)) {
                            formIsValid = false;
                            errors.bankDocument = "Only PDF is allowed !"
                        }
                        if (item.size > MAX_FILE_SIZE) {
                            formIsValid = false;
                            errors.bankDocument = "Maximum allowed file size is 2MB !";
                            break;
                        }
                    }
                }
            }
        }
        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default EditProfileValidation;
