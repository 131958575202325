import React, { useContext, useState } from "react";
import { ModalContext } from "../../utils/Context";
import { postApi } from "../../utils/services";
import { PAY_PAYMENT } from "../../utils/apiRoutes";
import toast from "react-hot-toast";

const MakePaymentModal = ({ item, handleView, id }) => {

  const [Error, setErrors] = useState("");
  const [disable, setDisable] = useState(false)
  const [loading, setLoading] = useState(false);

  const modalContext = useContext(ModalContext);
  const { closeModal } = modalContext;
  let pendingAmount = item.IndividualBill === undefined ? item?.Total : item?.RemainingAmount;

  const handleMinDate = () => {
    // bill date
    const minDate = new Date(item?.BillDate);
    minDate.setDate(minDate.getDate());
    // Format the minimum date in yyyy-MM-dd format
    const minDateFormatted = minDate.toISOString().split("T")[0];
    // current date
    const minDate2 = new Date();
    minDate2.setDate(minDate2.getDate() - 7); // Corrected this line
    // Format the minimum date in yyyy-MM-dd format
    const minDateFormatted2 = minDate2.toISOString().split("T")[0];

    if (minDate < minDate2) {
      return minDateFormatted2;
    } else {
      return minDateFormatted;
    }
  };

  // Get the current date in yyyy-MM-dd format
  const maxDateFormatted = new Date().toISOString().split("T")[0];

  // Initialize the selected date with the minimum date
  const [selectedDate, setSelectedDate] = useState(maxDateFormatted);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setErrors("");
  };

  const handlePayment = async () => {
    // Clear previous errors
    try {
      setDisable(true);
      setLoading(true)
      const result = await postApi(PAY_PAYMENT, {
        paymentId: item?.PaymentId,
        paymentAmount: pendingAmount,
        paymentDate: selectedDate,
      });

      if (result.res.success) {
        toast.success(result.res.message);
        handleView(id);
        closeModal();
      }
      if (!result.res.success) {

        setErrors(result.res.message);
      }
    } catch (error) {

    }
    // Handle the result as needed
  };

  return (
    <div className="cstm-frm-mdl">
      <div class="modal-header d-flex align-item-center justify-content-between">
        <h4 class="modal-title">
          Pay Now
        </h4>
      </div>

      <div class="modal-body">
        <div class="mb-3">
          <label for="VendorName" class="form-label">
            Vendor Name
          </label>
          <input
            type="text"
            class="form-control"
            value={item?.LegalName}
            disabled
          />
        </div>
        <div className="d-flex mb-3 justify-content-between">
          <div className="w-100">
            <label for="Amount" class="form-label">
              Amount
            </label>
            <input
              type="text"
              class="form-control"
              id="Amount"
              value={`${item?.CurrencySymbol} ${pendingAmount?.toFixed(2)}`}
              disabled
            />
          </div>
        </div>
        <div className="d-flex mb-3 justify-content-between">
          <div className="w-100 ">
            <label for="exampleInputEmail1" class="form-label">
              Actual Payment Amount
            </label>
            <input
              type="text"
              className="form-control"
              value={`${item?.CurrencySymbol} ${pendingAmount?.toFixed(2)}`}
              disabled
            />
          </div>
          <div className="w-100 ms-2">
            <label for="dateInput" class="form-label">
              Payment Date
            </label>
            <input
              type="date"
              id="dateInput"
              className="form-control"
              name="dateInput"
              min={handleMinDate()}
              max={maxDateFormatted}
              value={selectedDate}
              onChange={handleDateChange}
            />
            {Error && <div className="validation-bill-err">{Error}</div>}
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div className=" d-flex align-items-center mb-20">
          <div className="cstm-dngr-btn">
            <button type="button" className="btn" onClick={() => closeModal()}>
              Cancel
            </button>
          </div>
          <div className="cstm-success-btn ms-2">
            <button
              type="submit"
              disabled={disable}
              className="btn"
              onClick={handlePayment}
            >
              {loading ? (
                <>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakePaymentModal;
