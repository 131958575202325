import React, { useEffect, useState } from 'react'
import { useFeedsColumnBills } from '../../../utils/helpers/tableHelpers/useFeedColumnBills';
import { BILLS_API } from '../../../utils/apiRoutes';
import { getApi } from '../../../utils/services';
import { VENDORID } from '../../../utils/constant';
import Bill from '../../Table/Bill';
import { Helmet } from "react-helmet";
import Loader from '../../common/Loader';

const BillList = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const [bills, setBills] = useState([]);
  const [loader, setLoader] = useState(false);

  const handlerBillList = async () => {
    try {
      let routeName
      if (user.RoleId === VENDORID) {
        routeName = `${BILLS_API}?billType=0&vendorId=${user.id}`
      }
      else {
        routeName = `${BILLS_API}?billType=0`
      }
      setLoader(true)
      let result = await getApi(routeName);
      result = result.res.bill_list;
      setLoader(false)
      setBills(result)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handlerBillList()
  }, []);

  const billcolumns = useFeedsColumnBills(handlerBillList);

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Bill List</title>
      </Helmet>

      <Loader isLoading={loader} />
      <div className="container-section">
        <div className="section-header">
          <h3>Bills</h3>
        </div>
        <div className="form-style">
          <div className="">
            <Bill columns={billcolumns} data={bills} />
          </div>
        </div>
      </div>

    </>
  )
}

export default BillList