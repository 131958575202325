import ReactApexChart from "react-apexcharts";

function GroupedBarChart() {
    const chartData = {
        series: [{
            data: [54, 61, 59, 43, 47, 76]
        }],
        options: {
            chart: {
                toolbar: {
                    show: false
                },
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'top'
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val + "K";
                },
                offsetY: -20,
                style: {
                    fontSize: '9px',
                    colors: ["#304758"]
                }
            },

            xaxis: {
                categories: ['Jan', 'Feb', 'March', 'April', 'May', 'June'],
                position: 'bottom',
            },

            yaxis: {
                title: {
                    text: 'Amount',
                },
                labels: {
                    formatter: function (val) {
                        return val + "K";
                    }
                }
            },
            title: {
                text: "AP Aging"
            }
        }
    }
    return (
        <div id="Barchart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
        </div>
    );
}

export default GroupedBarChart;





